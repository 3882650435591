<script>
import tnVue from "@/mixins/tn.vue";
import { mapActions, mapGetters } from "vuex";

import LanguageList from "@/utils/language-list..js";
import FeedbackAdd from "@/components/modals/feedback/feedback-add.vue";

export default {
  components: { FeedbackAdd },

  data: () => ({
    navbar: true,
    ln: "home",

    isFullScreen: false,

    languageList: LanguageList,
    selectedLanguage: null,

    isAddFeedback: false,
  }),

  mixins: [tnVue],

  computed: {
    ...mapGetters("auth", ["GET_USER"]),
    isMenuOrderPermission: (vm) => vm.$store.state.auth.isMenuOrderPermission,

    filteredMenuItems() {
      if (!this.isMenuOrderPermission) {
        return this.menuItems.filter((item) => item.url !== "/price-list-viewer-order");
      }
      return this.menuItems;
    },

    menuItems: (vm) => [
      {
        name: vm.tn("selection_of_goods"),
        children: [
          {
            name: vm.tn("selection_of_goods_child.by_goods"),
            url: "/selection",
            exactPath: true,
          },
          {
            name: vm.tn("selection_of_goods_child.automatic"),
            url: "/selection/auto",
          },
          {
            name: vm.tn("selection_of_goods_child.top_search"),
            url: "/selection/top",
            exactPath: true,
          },
          {
            name: vm.tn("selection_of_goods_child.top_sold"),
            url: "/selection/top/sold",
          },
        ],
      },
      {
        name: vm.tn("price_list"),
        url: "/price-list",
      },
      {
        name: vm.tn("basket"),
        url: "/basket",
      },
      // {
      //   name: vm.tn("favorite"),
      //   url: "/favorite",
      // },
      // {
      //   name: vm.tn("my_discount"),
      //   url: "/my-discount",
      // },
      {
        name: vm.tn("price_list_viewer_order"),
        url: "/price-list-viewer-order",
      },
      {
        name: vm.tn("history"),
        url: "/history",
      },
      {
        name: vm.tn("pharmacy_requirements"),
        url: "/pharmacy-requirements",
      },
    ],

    activePath: (vm) => vm.$route.meta.parent || vm.$route.path,
  },

  watch: {
    selectedLanguage(v) {
      if (v) this.$i18n.locale = v;
    },
  },

  created() {
    const locale = this.$i18n.locale;
    const selectedLanguage = LanguageList.find((x) => x.value == locale);
    if (selectedLanguage) this.selectedLanguage = selectedLanguage.value;
    else this.selectedLanguage = LanguageList[0];
  },

  methods: {
    ...mapActions("auth", ["LOGOUT"]),

    openFullscreen() {
      const elem = document.getElementById("app");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },

    closeFullscreen() {
      if (document.fullscreenElement == null) {
        this.openFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      }
    },

    onPolicy() {
      window.open("/Публичная оферта - Oson Price (OP2023).pdf", "_blank");
    },

    onFeedback() {
      this.isAddFeedback = true;
    },

    getParentName(item) {
      const child = item.children.find((x) => x.url == this.activePath);
      return child?.name || item.name;
    },

    getChildUrl(item) {
      const child = item.children.find((x) => x.url == this.activePath);
      return child?.url ? this.$route.path : undefined;
    },
  },
};
</script>

<template>
  <div id="home">
    <feedback-add
      v-if="isAddFeedback"
      @close="isAddFeedback = false"
    />

    <v-app-bar
      app
      class="bg-secondary disable-drag"
      elevation="0"
      dense
    >
      <div class="pt-3">
        <router-link to="/home">
          <img
            v-if="GET_USER.isTrial"
            width="180"
            src="@/assets/img/logo-inline-trial.svg"
            alt=""
          />
          <img
            v-else
            width="180"
            src="@/assets/img/logo-inline.svg"
            alt=""
          />
        </router-link>
      </div>

      <v-spacer />

      <template v-for="(item, index) in filteredMenuItems">
        <v-menu
          v-if="item.children"
          :key="index"
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn
              text
              color="primary"
              class="reset-text"
              v-bind="attrs"
              v-on="on"
              :to="getChildUrl(item)"
            >
              {{ getParentName(item) }}
              <v-icon
                :class="value && '_rotate_180'"
                class="_transition ml-0"
                small
              >
                mdi-chevron-up
              </v-icon>
            </v-btn>
          </template>

          <v-list
            class="disable-drag"
            nav
            dense
          >
            <template v-for="(child, ci) in item.children">
              <v-list-item
                :key="ci + 'i'"
                :to="child.url"
                @click="child.action && child.action()"
                :exact-path="child.exactPath"
              >
                <v-list-item-title>
                  {{ child.name }}
                </v-list-item-title>
              </v-list-item>
              <v-divider
                v-if="ci != item.children.length - 1"
                class="mb-1"
                :key="ci + 'd'"
              />
            </template>
          </v-list>
        </v-menu>

        <v-btn
          v-else
          :key="index"
          text
          color="primary"
          class="reset-text"
          :to="item.url"
        >
          {{ item.name }}
        </v-btn>
      </template>

      <v-spacer />

      <v-btn
        :title="tn('full_screen_btn')"
        icon
        @click="closeFullscreen"
      >
        <v-icon> mdi-fullscreen </v-icon>
      </v-btn>

      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            min-width="36"
            width="36"
            color="primary"
            rounded
            elevation="0"
          >
            {{ GET_USER.firstName.substring(0, 1) }}
          </v-btn>
        </template>

        <v-list
          class="disable-drag"
          nav
          dense
          min-width="240"
        >
          <v-list-group :value="true">
            <template v-slot:activator>
              <v-list-item-title>
                {{ tn("edit_account") }}
              </v-list-item-title>
            </template>
            <v-divider class="mb-1" />
            <v-list-item
              class="pl-5"
              to="/telegram-bot-connect"
            >
              <v-list-item-title>
                {{ tn("connect_telegram") }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class="mb-1" />
            <!-- <v-list-item
              class="pl-5"
              to="/edit-phone"
            >
              <v-list-item-title>
                {{ tn("change_phone") }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class="mb-1" /> -->
            <v-list-item
              class="pl-5"
              to="/edit-inn"
            >
              <v-list-item-title>
                {{ tn("change_inn") }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class="mb-1" />
            <v-list-item
              class="pl-5"
              to="/edit-profile"
            >
              <v-list-item-title>
                {{ tn("edit_profile") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-divider class="mb-1" />

          <v-menu
            nudge-left="233"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>
                  {{ tn("change_language") }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <v-list
              nav
              dense
            >
              <v-list-item-group
                v-model="selectedLanguage"
                color="primary"
              >
                <template v-for="(lang, index) in languageList">
                  <v-list-item
                    :key="lang.value"
                    :value="lang.value"
                  >
                    <v-list-item-title>
                      {{ lang.name }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider
                    v-if="index != languageList.length - 1"
                    class="mb-1"
                    :key="lang.value + '-d'"
                  />
                </template>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-divider class="mb-1" />
          <v-list-item @click="onFeedback">
            <v-list-item-title>
              {{ tn("feedback") }}
            </v-list-item-title>
          </v-list-item>
          <v-divider class="mb-1" />
          <v-list-item @click="onPolicy">
            <v-list-item-title>
              {{ tn("privacy_policy") }}
            </v-list-item-title>
          </v-list-item>
          <v-divider class="mb-1" />
          <v-list-item
            @click="LOGOUT"
            class="error--text"
          >
            <v-list-item-title>
              {{ tn("exit") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main style="min-width: 1100px">
      <router-view />
    </v-main>
  </div>
</template>

<style lang="scss" scoped>
// .test-flag {
//   position: fixed;
//   top: 0;
//   left: 0;
//   background-color: red;
//   color: white;
//   font-size: 12px;
//   padding: 2px;
//   border-bottom-right-radius: 10px;
// }

._transition {
  transform: rotate(180deg);

  transition: all 0.5s;
}

._rotate_180 {
  transform: rotate(0);
}
</style>
