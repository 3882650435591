import Vue from "vue";
const vm = Vue.prototype;

const url = "PriceListViewerFeedback";

export const state = () => ({
  //
});

export const mutations = {
  //
};

export const actions = {
  async ADD_API(ctx, payload) {
    const res = await vm.$axios.$post(url, payload);
    return res?.succeeded;
  },
};

export const getters = {
  //
};
