<script>
import { mapActions } from "vuex";

import { readExcelData } from "@/utils/read-excel-data";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";

export default {
  components: { ConfirmDialog },

  props: {
    title: String,
    disabled: Boolean,
    notFoundAlert: {
      type: String,
      required: true,
    },

    tn: Function,
  },

  data: () => ({
    isLoading: false,
    sessionGuid: null,
    isShowDelete: false,
  }),

  methods: {
    ...mapActions("selection-auto", {
      importItems: "SET_IMPORT_ITEMS",
      checkImport: "CHECK_IMPORT",
    }),

    async onUpload(event) {
      await this.importItems();

      this.isLoading = true;

      const files = event?.target?.files || [];
      if (files.length) {
        const lineItems = await readExcelData(files[0]);
        if (lineItems?.length) {
          await this.importItems(lineItems);
          this.$router.push("/selection/auto/import");
        } else {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.notFoundAlert,
            color: "info",
          });
        }
      }

      this.isLoading = false;
    },

    async onImportFile() {
      this.isLoading = true;
      const data = await this.checkImport();
      this.isLoading = false;

      this.$nextTick(() => {
        if (data?.sessionGuid) {
          this.sessionGuid = data?.sessionGuid;
          this.isShowDelete = true;
        } else {
          this.$refs.uploadExcel.click();
        }
      });
    },

    onUploadNew({ close }) {
      this.$refs.uploadExcel.click();
      close();
    },

    onOpenOld() {
      this.$router.push("/selection/auto/join/" + this.sessionGuid);
    },
  },
};
</script>

<template>
  <div>
    <ConfirmDialog
      :text="tn('exist_import_alert')"
      @close="isShowDelete = false"
      v-if="isShowDelete"
      @accept="onUploadNew"
      @no="onOpenOld"
      hideInfo
      :cancelText="tn('exist_import_alert_yes')"
      :acceptText="tn('exist_import_alert_no')"
    />

    <input
      v-if="!isLoading"
      @change="onUpload"
      type="file"
      ref="uploadExcel"
      class="d-none"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    />

    <v-btn
      color="primary"
      min-width="40"
      width="40"
      height="40"
      min-height="40"
      outlined
      :loading="isLoading"
      :disabled="disabled"
      :title="title"
      class="mr-2"
      @click="onImportFile"
    >
      <v-icon> mdi-file-download-outline </v-icon>
    </v-btn>
  </div>
</template>
