<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";
import { mapActions } from "vuex";

export default {
  mixins: [tnVue],
  components: { ModalsTemplate },
  props: { ln: String },

  data: (vm) => ({
    dialog: true,
    isLoading: false,
    formData: {},
    guid: vm.$route.params.priceListViewerOrderId,
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    ...mapActions("price-list-viewer-order", ["CANCEL_API"]),

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        const params = {
          guid: this.guid,
          ...this.formData,
        };

        const status = await this.CANCEL_API(params);
        this.isLoading = false;
        if (status) {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.$t("success"),
            color: "success",
          });
          this.$emit("saved");
          this.dialog = false;
        }
      }
    },
  },
};
</script>

<template>
  <modals-template
    :width="500"
    v-model="dialog"
    :title="tn('cancel_modal.title')"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-textarea
        autofocus
        outlined
        @keyup.enter="onSave"
        dense
        v-model="formData.note"
        required
        :rules="[$rules.required]"
      />

      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        elevation="0"
        class="mt-5 mb-1"
      >
        {{ tn("cancel_modal.save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
