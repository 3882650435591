<script>
import { mapActions, mapGetters } from "vuex";
import tnVue from "@/mixins/tn.vue";
import PermissionConfirmDialog from "@/components/modals/permission-confirm-dialog.vue";

export default {
  props: {
    item: Object,
    ln: String,
  },

  components: { PermissionConfirmDialog },

  data: () => ({
    isLoading: false,
    isPermissionAlert: false,
    tryCount: null,
  }),

  mixins: [tnVue],

  computed: {
    ...mapGetters("auth", ["GET_USER", "GET_USER_PERMISSIONS"]),
  },

  methods: {
    ...mapActions("product", { alertApi: "ALERT_API" }),
    ...mapActions("auth", ["GET_PERMISSIONS_LIST"]),

    async onAlert() {
      if (!this.GET_USER.isTrial) {
        this.onPermission();
      } else {
        this.$store.dispatch("SET_LOADING", true);
        await this.GET_PERMISSIONS_LIST();
        this.$store.dispatch("SET_LOADING", false);

        this.tryCount = this.$getObjectParam("CanAddProductAlert", this.GET_USER_PERMISSIONS);
        if (this.$number(this.tryCount)) {
          this.isPermissionAlert = true;
        } else {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.$t("permission_confirm_dialog.permission_alert"),
            color: "error",
          });
        }
      }
    },

    async onPermission() {
      this.isPermissionAlert = false;

      this.isLoading = true;
      await this.alertApi({ ...this.item });
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div>
    <PermissionConfirmDialog
      :text="$t('permission_confirm_dialog.function_permission_alert')"
      :try-count="tryCount"
      @close="isPermissionAlert = false"
      v-if="isPermissionAlert"
      @accept="onPermission"
    />

    <div
      style="transform: translateY(-4px)"
      class="tb-action-wrap translate-3 justify-center"
    >
      <v-btn
        :loading="isLoading"
        :color="item.isAlert ? 'primary' : undefined"
        :title="item.isAlert ? tn('table.actions.alert_remove_btn') : tn('table.actions.alert_btn')"
        @click="onAlert"
        icon
        x-small
      >
        <v-icon>
          {{ item.isAlert ? "mdi-bell" : "mdi-bell-outline" }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
