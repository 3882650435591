<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "debounce";

import tnVue from "@/mixins/tn.vue";

export default {
  props: {
    tableHeight: Number,
    value: Array,
  },

  data: () => ({
    ln: "price_list.compare.table.filter.provider",

    options: {},
    itemsPerPage: 50,

    selectedRows: [],

    isLoading: false,

    searchText: null,
  }),

  mixins: [tnVue],

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },

    selectedRows(v) {
      this.$emit("setCount", v?.length || 0);
    },
  },

  computed: {
    ...mapGetters("provider", {
      entries: "GET_ONLY_NAME",
      entriesCount: "GET_ONLY_NAME_COUNT",
    }),

    tableHeaders: (vm) => [{ text: vm.tn("table.name"), value: "name", width: "100%" }],
  },

  created() {
    this.selectedRows = this.$array(this.value).map((x) => ({ guid: x }));
  },

  methods: {
    ...mapActions("provider", ["GET_ONLY_NAME_API"]),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = { options: { ...this.options } };

      params.options.sortBy = '"Name"';
      params.options.sortDesc = "asc";

      if (this.searchText) params.options.search = this.searchText;
      params.filterData = {};
      await this.GET_ONLY_NAME_API(params);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onClear() {
      this.searchText = null;
      this.$nextTick(() => this.getEntries());
    },
  },
};
</script>

<template>
  <div>
    <div class="pb-2">
      <v-text-field
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        @click:append="onSearch"
        @keyup.enter="onSearch"
        :label="tn('search')"
        v-model="searchText"
        clearable
        @click:clear="onClear"
      />
    </div>
    <v-data-table
      dense
      :headers="tableHeaders"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="entriesCount"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [25, 50],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: $t('table.per_page_text'),
      }"
      :height="tableHeight"
      disable-sort
      show-select
      v-model="selectedRows"
      item-key="guid"
      key="guid"
      class="remove-checkbox-margin"
      checkbox-color="primary"
    />
  </div>
</template>
