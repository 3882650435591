<script>
import tnVue from "@/mixins/tn.vue";
import BasketFreshList from "./components/basket-fresh-list.vue";
import BasketFreshNotFoundList from "./components/basket-fresh-not-found-list.vue";
import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BasketFreshList,
    BasketFreshNotFoundList,
    ConfirmDialog,
  },

  data: (vm) => ({
    ln: "basket.fresh",
    currentPath: `/basket/fresh/${vm.$route.params.basketGuid}`,
    isShowDelete: false,
  }),

  mixins: [tnVue],

  computed: {
    activeTab: (vm) => vm.$route.params.activeTab,
  },

  methods: {
    ...mapActions("basket", {
      deleteApi: "REMOVE_API",
      changeWithFreshListApi: "CHANGE_WITH_FRESH_LIST_API",
    }),

    async onDelete({ close, setLoading }) {
      setLoading(true);
      const params = { basketGuid: this.$route.params.basketGuid };
      const status = await this.deleteApi(params);
      setLoading(false);
      close();
      if (status && !this.$router.back()) this.$router.push("/basket");
    },

    async onChangeFresh() {
      this.$store.dispatch("SET_LOADING", true);
      const params = { basketGuid: this.$route.params.basketGuid };
      const status = await this.changeWithFreshListApi(params);
      this.$store.dispatch("SET_LOADING", false);
      if (status) {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("items_replaced_successfully_alert"),
          color: "success",
        });
        this.isShowDelete = true;
      }
    },

    onRefresh() {
      this.$refs?.freshListNotFound?.onRefresh();
      this.$refs?.freshList?.onRefresh();
    },

    onCloseDelete() {
      this.isShowDelete = false;
      this.onRefresh();
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-tabs>
      <v-tab
        replace
        exact-path
        :to="currentPath"
      >
        <span class="transform-none">{{ tn("tabs.new_goods") }}</span>
      </v-tab>
      <v-tab
        replace
        :to="`${currentPath}/not-found`"
      >
        <span class="transform-none">{{ tn("tabs.no_left") }}</span>
      </v-tab>
    </v-tabs>

    <div class="pt-4" />

    <ConfirmDialog
      :text="tn('delete_alert')"
      @close="onCloseDelete"
      v-if="isShowDelete"
      @accept="onDelete"
    />

    <basket-fresh-not-found-list
      :ln="`${ln}.fresh_list_not_found`"
      v-if="activeTab == 'not-found'"
      @delete="isShowDelete = true"
      @changeFresh="onChangeFresh"
      ref="freshListNotFound"
    />
    <basket-fresh-list
      :ln="`${ln}.fresh_list`"
      @delete="isShowDelete = true"
      @changeFresh="onChangeFresh"
      v-else
      ref="freshList"
    />
  </v-container>
</template>
