import store from "../store";
export default async (to, from, next) => {
  if (to.path == "/") return next(true);
  if (to?.meta?.auth) {
    const isMenuOrderPermission = await initMenuOrderPermission();
    if (isMenuOrderPermission) return next(true);
  }
  next("/home");
};

async function initMenuOrderPermission() {
  let isMenuOrderPermission = !!store.state.auth.isMenuOrderPermission;
  if (isMenuOrderPermission) return true;

  store.dispatch("SET_INIT", true);
  await store.dispatch("auth/INIT_USER");
  await store.dispatch("auth/GET_MENU_ORDER_PERMISSION");
  isMenuOrderPermission = !!store.state.auth.isMenuOrderPermission;
  if (isMenuOrderPermission) await store.dispatch("INIT_STATES");

  store.dispatch("SET_INIT", false);
  return isMenuOrderPermission;
}
