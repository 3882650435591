<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "debounce";

import tnVue from "@/mixins/tn.vue";
import TableFocusInput from "./table-focus-input.vue";
import TablePagination from "@/mixins/table-pagination.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import SearchSuggestBox from "@/components/search-suggest-box.vue";

export default {
  components: {
    TableFocusInput,
    SearchSuggestBox,
  },

  props: {
    tableHeight: Number,
    ln: String,
  },

  data: () => ({
    searchText: null,
    sortBy: null,
    isShowSuggests: null,
    uniqKey: "pharmacy-requirements-products-list",
  }),

  mixins: [tnVue, TablePagination, TableNavFunctionsVue],

  computed: {
    ...mapGetters("product", {
      entries: "GET_PRODUCT",
      entriesCount: "GET_PRODUCT_COUNT",
    }),

    tableHeaders: (vm) => [
      {
        text: "#",
        value: "number",
        width: 10,
        sortable: false,
        align: "center",
        class: "disable-resize",
      },
      {
        text: vm.tn("table.productName"),
        value: "name",
        minWidth: "180px",
      },
      {
        text: vm.tn("table.manufacturer"),
        value: "brandName",
        minWidth: "180px",
      },
      {
        text: vm.tn("table.linked_count"),
        value: "linkedCount",
        _filter: (v) => vm.$sum(v),
        _align: "right",
      },
    ],
  },

  watch: {
    selected: debounce(function () {
      const v = this.selected || {};
      this.$emit("select", { ...v });
    }, 200),

    searchText() {
      this.onSearch();
    },
  },

  methods: {
    ...mapActions("product", ["GET_PRODUCTS_API"]),

    async getEntries(ee) {
      const isReset = ee?.reset;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          sortOrders: this.$getSortOrders(this.options),
          search: `${this.searchText || ""}`.trim(),
        },
      };

      if (isReset) {
        this.options.page = 1;
        params.options.offset = 0;
      }

      if (!params.options.sortBy) delete params.options.sortDirection;

      await this.GET_PRODUCTS_API(params);

      if (document.activeElement?.id != this.ln && !this.isShowSuggests) this.setFocusToItem();

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSearch: debounce(function () {
      this.getEntries({ reset: true });
    }, 500),

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.uniqKey);
    },

    onExit() {
      this.$router.replace("/pharmacy-requirements");
    },

    onKeyupDown() {
      if (this.isLoading) return
      this.setFocusToItem();
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-center">
      <v-btn
        min-width="40"
        width="40"
        outlined
        color="primary"
        replace
        @click="onExit"
        depressed
        class="mr-3"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>

      <h3 v-text="tn('show_products_links_btn')" />
      <v-spacer />

      <div style="max-width: 300px; width: 100%">
        <SearchSuggestBox
          @keyup-down="onKeyupDown({ enter: true })"
          @show-suggests="(e) => isShowSuggests = e"
          :ln="ln"
          :searchBoxHotKey="113"
          v-model="searchText"
        />
      </div>

      <div class="px-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :loading="isLoading"
        :title="tn('refresh_btn')"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>
    </div>

    <slot />

    <div class="mt-1" />

    <v-data-table
      v-if="tableHeaders.length && tableHeight > 0"
      :headers="tableHeaders"
      dense
      fixed-header
      disable-pagination
      hide-default-footer
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="entriesCount"
      :height="tableHeight"
      key="guid"
      class="table-footer-nowrap"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr
          class="cursor-pointer"
          @click.stop="onSelect({ item })"
          :class="{
            'tr-active': selected.guid == item.guid,
          }"
        >
          <template v-for="head in headers">
            <td
              v-if="head.value == 'number'"
              :key="head.value"
              class="px-0 text-center"
            >
              <span
                class="text-nowrap"
                v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
              />
            </td>

            <td
              v-else-if="head._filter"
              :key="head.value + 1"
            >
              <div
                class="text-nowrap"
                v-if="head._html"
                v-html="head._filter(item[head.value], item)"
                :style="{ minWidth: head.minWidth }"
              />
              <div
                v-else
                class="text-nowrap"
                v-text="head._filter(item[head.value], item)"
                :style="{ textAlign: head._align, minWidth: head.minWidth }"
              />
            </td>

            <td
              v-else-if="head.value == 'name'"
              :key="head.value + 4"
            >
              <table-focus-input
                :ref="item.id"
                :tabindex="index + 1"
                :item="item"
                :uniKey="uniqKey"
                @upKey="onUpKey({ item })"
                @downKey="onDownKey({ item })"
                @pageUp="onPageUp({ item })"
                @pageDown="onPageDown({ item })"
              />
              <div
                :style="{ minWidth: head.minWidth }"
                v-text="item[head.value]"
              />
            </td>

            <td
              v-else
              :key="head.value + 5"
            >
              <div
                :style="{ minWidth: head.minWidth }"
                v-text="item[head.value]"
              />
            </td>
          </template>
        </tr>
      </template>

      <template #footer>
        <TablePaginationButtons
          :loading="isLoading"
          :options="options"
          :lastPage="lastPage"
          :perPageInput="perPageInput"
          :pageInput="pageInput"
          :entriesCount="entriesCount"
          :onLastPage="onLastPage"
          :onChangePerPageInput="onChangePerPageInput"
          :onChangePageInput="onChangePageInput"
        />
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped>
.translate-3 {
  transform: translateY(-4px);
}
</style>
