<script>
export default {
  props: {
    text: String,

    width: {
      type: [Number, String],
      default: () => 500,
    },

    acceptText: {
      type: String,
      default: () => null,
    },

    hideCancel: {
      type: Boolean,
      default: () => false,
    },

    cancelText: {
      type: String,
      default: () => null,
    },

    cancelColor: {
      type: String,
      default: () => "green darken-1",
    },

    acceptColor: {
      type: String,
      default: () => "green darken-1",
    },

    tryCount: {
      type: [Number, String],
      default: () => 0,
    },
  },

  data: () => ({
    dialog: true,
    isLoading: false,
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close", v), 100);
    },
  },

  methods: {
    onAccept() {
      const params = {
        close: () => (this.dialog = false),
        setLoading: (v) => (this.isLoading = !!v),
      };
      this.$emit("accept", params);
    },

    onClose() {
      this.$emit("no");
      this.dialog = false;
    },
  },
};
</script>

<template>
  <v-dialog
    @keydown.enter="onAccept"
    v-model="dialog"
    :max-width="width"
  >
    <v-card>
      <input
        class="_hidden-item"
        autofocus
      />
      <div class="text-center px-5 py-8">
        <v-icon class="icon">mdi-alert-circle-outline</v-icon>
        <h2
          class="mt-5 pb-2"
          v-html="`${text} ${$number(tryCount)}.`"
        />

        <div class="d-flex justify-space-between pt-10 px-5">
          <v-btn
            outlined
            v-if="!hideCancel"
            :color="cancelColor"
            @click="onClose"
            width="47%"
          >
            {{ cancelText || $t("confirm_dialog.cancel") }}
          </v-btn>
          <v-btn
            :loading="isLoading"
            width="47%"
            outlined
            :color="acceptColor"
            @click="onAccept"
          >
            {{ acceptText || $t("confirm_dialog.confirm") }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.icon {
  font-size: 70px !important;
}

.alert-info {
  font-size: 14px;
  font-weight: 400;
}

._hidden-item {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>
