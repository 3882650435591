<script>
import tnVue from "@/mixins/tn.vue";
import ModalsTemplate from "@/components/modals-template.vue";

export default {
  components: { ModalsTemplate },

  props: {
    value: Object,
  },

  data: (vm) => ({
    dialog: true,
    ln: "price_list.show.viewing_access_permission.filter",
    isLoading: false,
    filterData: { ...vm.value },
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    async onApply() {
      if (this.$refs.form.validate()) {
        this.$emit("apply", this.filterData);
        this.dialog = false;
      }
    },
  },
};
</script>

<template>
  <modals-template
    :width="320"
    :title="tn('title')"
    v-model="dialog"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-radio-group
        v-model="filterData.isAllowed"
        mandatory
        hide-details
        class="ma-0 pa-0"
      >
        <v-radio
          :label="tn('form.all')"
          :value="null"
        />
        <v-radio
          :label="tn('form.allowed')"
          :value="true"
        />
        <v-radio
          :label="tn('form.dis_allowed')"
          :value="false"
        />
      </v-radio-group>

      <v-btn
        :loading="isLoading"
        color="primary"
        @click="onApply"
        block
        elevation="0"
        class="mb-1 mt-5"
      >
        {{ tn("apply_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
