import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = 'PriceListViewerProductLink'

export const state = () => ({
  itemsData: {},
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(
        payload?.items.map((x, i) => {
          x._id = i + 1;
          return x;
        })
      ),
      total: vm.$number(payload?.totalCount),
    };
  },
};

export const actions = {
  async GET_API({ commit }, { options }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();
    commit("SET_ITEMS");

    const params1 = { ...options };

    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$post(`${url}/List`, params, request);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  async ADD_API(ctx, payload) {
    if (payload?.guid) {
      const params = {
        guid: payload.guid,
        manufacturerName: payload.manufacturerName,
        productName: payload.productName,
      }
      const res = await vm.$axios.$put(url, params);
      return res?.succeeded;
    } else {
      const res = await vm.$axios.$post(url, payload);
      return res?.succeeded;
    }
  },

  async DELETE_API(ctx, payload) {
    const uri = `${url}/Delete`;
    return !!(await vm.$axios.$post(uri, payload));
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
}
