<script>
export default {
  props: {
    show: Boolean,
    hideOverlay: Boolean,
  },
};
</script>

<template>
  <div>
    <v-dialog
      :value="show"
      :hide-overlay="hideOverlay"
      persistent
      width="300"
      style="z-index: 20001"
    >
      <v-card class="pa-5">
        {{ $t("loading") }}
        <v-progress-linear
          height="5"
          indeterminate
          class="mt-1"
        />
      </v-card>
    </v-dialog>
    <div
      v-if="show && hideOverlay"
      class="custom-overlay"
    />
  </div>
</template>

<style lang="scss" scoped>
.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
}
</style>
