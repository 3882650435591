export const dxColumnSize = {
  priceListProviderName: "180px",
  productName: "300px",
  manufacturer: "200px",
  selectionName: "180px",
  selectionManufacturer: "180px",
  selectionQtyStockNeedAndStock: "70px",
  price: "80px",
  amount: "100px",
  totalAmount: "80px",
  date: "90px",
  qty: "80px",
  priceTypeName: "90px",
  qtyString: "80px",
};
