<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [tnVue],

  components: { ModalsTemplate },

  props: {
    value: Object,
  },

  data: () => ({
    dialog: true,

    options: {},
    itemsPerPage: 50,

    loaders: {},

    ln: "selection_auto.duplicates",
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },

    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("selection-auto", {
      entries: "GET_DUPLICATE_ITEMS",
      entriesCount: "GET_DUPLICATE_ITEMS_COUNT",
    }),

    size: (vm) => vm.$store.getters.GET_SIZE,
    tableHeight: (vm) => vm.size.height - 350,

    tableHeaders: (vm) => [
      {
        text: "№",
        value: "number",
        width: 10,
        sortable: false,
        class: "disable-resize",
      },
      {
        text: vm.tn("table.name"),
        value: "productName",
      },
      {
        text: vm.tn("table.manufacturer"),
        value: "brandName",
      },
    ],

    isLoading: (vm) => !!Object.keys(vm.loaders).length,
  },

  methods: {
    ...mapActions("selection-auto", {
      getEntriesApi: "GET_DUPLICATE_ITEMS_API",
    }),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = { options: { ...this.options } };

      params.options.sortBy = this.$getSort(params.options.sortBy);
      params.options.sortDesc = this.$getSort(params.options.sortDesc, true);

      if (this.searchText) params.options.search = this.searchText;

      params.filterData = { ...this.filterData };
      await this.getEntriesApi(params);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },
  },
};
</script>

<template>
  <modals-template
    :width="900"
    :title="tn('title')"
    v-model="dialog"
  >
    <div v-text="tn('sub_title')" />
    <v-data-table
      v-if="tableHeight > 0"
      :headers="tableHeaders"
      dense
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [25, 50],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: $t('table.per_page_text'),
      }"
      :height="tableHeight"
    >
      <template #item.number="{ index }">
        <td class="px-0 text-center">
          <span
            class="text-nowrap"
            v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
          />
        </td>
      </template>
    </v-data-table>

    <div class="d-flex pt-5">
      <v-spacer />

      <v-btn
        min-width="200px"
        @click="dialog = false"
        elevation="0"
        color="primary"
      >
        {{ tn("close_btn") }}
      </v-btn>
    </div>
  </modals-template>
</template>
