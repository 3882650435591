<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";
import { mapActions } from "vuex";

export default {
  components: { ModalsTemplate },

  props: {
    value: Object,
  },

  data: () => ({
    dialog: true,
    isLoading: false,

    formData: {
      stockNeed: null,
      salePrice: null,
      stockQuantity: null,
      saleQuantityDay30: null,
    },

    ln: "selection_auto.edit",
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  created() {
    if (this.value?.guid) {
      this.formData = this.value;
    }

    document.addEventListener("keyup", this.onKeyup);
  },

  mounted() {
    const children = this.$refs.form.$children;
    for (let index = 0; index < children.length; index++) {
      const input = children[index].$el.querySelector(".v-input__slot .v-text-field__slot input");

      if (input) {
        input.className = `my-popup-input-${index + 1}`;
      }
    }
  },

  destroyed() {
    document.removeEventListener("keyup", this.onKeyup);
  },

  methods: {
    ...mapActions("selection-auto", ["POST_API"]),

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const formData = { ...this.formData };

        const status = await this.POST_API(formData);
        this.isLoading = false;
        if (status) {
          this.$emit("saved");
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },

    onKeyup(e) {
      if (e?.keyCode == 13) {
        e?.preventDefault();
        const isFocused = document.activeElement.className.includes("my-popup-input");

        if (isFocused) {
          const nextIndex = document.activeElement.tabIndex + 1;
          const next = this.$refs[`input${nextIndex}`];

          if (next) {
            next?.focus();
          } else {
            this.$refs.input1.focus();
          }
        } else {
          this.$refs.input1.focus();
        }
      }
    },
  },
};
</script>

<template>
  <modals-template
    :width="500"
    :title="tn('title')"
    v-model="dialog"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <h4 class="pb-5">
        {{ formData.productName }} <br />
        {{ formData.brandName }}
      </h4>

      <v-text-field
        :label="tn('form.need')"
        autofocus
        outlined
        dense
        v-model="formData.stockNeed"
        v-inputmask="$masks.number"
        ref="input1"
        tabindex="1"
      />

      <v-text-field
        :label="tn('form.price')"
        outlined
        dense
        v-model="formData.salePrice"
        v-inputmask="$masks.number"
        ref="input2"
        tabindex="2"
      />

      <v-text-field
        :label="tn('form.quantity')"
        outlined
        dense
        v-model="formData.stockQuantity"
        v-inputmask="$masks.number"
        ref="input3"
        tabindex="3"
      />

      <v-text-field
        :label="tn('form.selling')"
        outlined
        @keyup.enter="onSave"
        dense
        v-model="formData.saleQuantityDay30"
        v-inputmask="$masks.number"
        ref="input4"
        tabindex="4"
      />

      <v-btn
        height="40"
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        depressed
        class="mb-1"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
