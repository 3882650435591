<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    ModalsTemplate,
  },

  props: {
    value: Object,
  },

  data: () => ({
    dialog: true,
    isLoading: false,

    ln: "price_list.table.filter",

    formData: {},
    tableHeight: 200,

    selectedRows: [],

    searchText: null,
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  computed: {
    ...mapGetters("references", { entries: "GET_REGIONS" }),

    tableHeaders: (vm) => [{ text: vm.tn("table.name"), value: "name", width: "100%" }],
  },

  created() {
    if (Object.values(this.value).length) {
      this.formData = this.value;
      const rs = this.value.regionGuids;
      this.selectedRows = this.$array(rs).map((x) => ({ guid: x }));
    }
  },

  methods: {
    async onApply() {
      const formData = { ...this.formData };
      const rs = this.$array(this.selectedRows);

      formData.regionGuids = rs.map((x) => x.guid).filter((x) => x);
      if (!formData.regionGuids.length) {
        delete formData.regionGuids;
      }

      this.$emit("apply", formData);
      this.dialog = false;
    },

    onReset() {
      this.selectedRows = [];
      this.formData = {};
      this.$nextTick(() => this.onApply());
    },
  },
};
</script>

<template>
  <modals-template
    :width="600"
    v-model="dialog"
    :title="tn('title')"
    :persistent="false"
  >
    <div class="d-flex align-center">
      <div class="d-flex align-items-center">
        <h4 v-text="tn('regions')" />
        <div
          v-if="selectedRows.length"
          class="pl-1 error--text"
        >
          ({{ selectedRows.length }})
          <span
            @click="selectedRows = []"
            class="span-link"
          >
            {{ tn("clear") }}
          </span>
        </div>
      </div>
    </div>

    <div class="pt-1"></div>
    <div class="pb-2">
      <v-text-field
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        :label="tn('search')"
        v-model="searchText"
        clearable
      />
    </div>
    <v-data-table
      dense
      :headers="tableHeaders"
      :items="entries"
      :mobile-breakpoint="0"
      :height="tableHeight"
      :search="searchText"
      disable-sort
      show-select
      v-model="selectedRows"
      item-key="guid"
      key="guid"
      class="remove-checkbox-margin"
      checkbox-color="primary"
      hide-default-footer
      disable-pagination
    />

    <div class="d-flex align-center justify-center">
      <v-btn
        height="45"
        :loading="isLoading"
        color="error"
        @click="onReset"
        elevation="0"
        class="mt-5 mb-1 w-49"
      >
        {{ tn("reset_btn") }}
      </v-btn>
      <div class="px-2" />
      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onApply"
        elevation="0"
        class="mt-5 mb-1 w-49"
      >
        {{ tn("apply_btn") }}
      </v-btn>
    </div>
  </modals-template>
</template>
