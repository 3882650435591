import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = "PriceListViewerProductImport";

export const state = () => ({
  importData: {},
});

export const mutations = {
  SET_IMPORT(state, payload) {
    state.importData = {
      items: vm.$array(
        payload?.items.map((x, i) => {
          x._id = i + 1;
          return x;
        })
      ),
      total: vm.$number(payload?.totalCount),
    };
  },

  REPLACE_ITEM(state, payload) {
    state.importData.items = state.importData.items.map((x) =>
      x.guid == payload.guid ? payload : x
    );
  },
};

export const actions = {
  async GET_API({ commit }, { options, isCancel }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_IMPORT");

    if (isCancel) return;

    const params1 = { ...options };
    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$get(`${url}/List`, params, request);
    if (res?.succeeded) commit("SET_IMPORT", res?.data);
  },

  async SET_LINK({ commit }, payload) {
    const params = {
      guid: payload.guid,
      productGuid: payload.productGuid,
    };

    const res = await vm.$axios.$post(`${url}/link`, params);
    let status = res?.succeeded;
    if (status) commit("REPLACE_ITEM", res.data);
    return status;
  },

  async SET_UNLINK({ commit }, payload) {
    const params = {
      guid: payload.guid,
    };

    const res = await vm.$axios.$post(`${url}/unlink`, params);
    let status = res?.succeeded;
    if (status) commit("REPLACE_ITEM", res.data);
    return status;
  },

  async DELETE_API(ctx, guid) {
    const res = await vm.$axios.$delete(url + "/" + guid);
    return res?.succeeded;
  },

  async DELETE_SESSION_API(ctx, guid) {
    const res = await vm.$axios.$delete(url + "/DeleteImport/" + guid);
    return res?.succeeded;
  },

  async APPLY_TO_LIST(ctx, payload) {
    const res = await vm.$axios.$post(url + "/Apply", payload);
    return res?.succeeded ? res.data : null;
  },

  async DELETE_UNLINKED(ctx, payload) {
    const res = await vm.$axios.$delete(
      url + "/DeleteUnlinked/" + payload.sessionGuid
    );
    return res?.succeeded;
  },
};

export const getters = {
  GET: (state) => state.importData.items,
  GET_COUNT: (state) => state.importData.total,
};
