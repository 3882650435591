<script>
import LoadingDialog from "./components/loading-dialog.vue";
import * as debounce from "debounce";

export default {
  components: { LoadingDialog },

  data: () => ({
    isShowRoute: true,
  }),

  watch: {
    "$vuetify.theme.dark"(v) {
      localStorage.setItem("theme--dark", v);
    },

    "$i18n.locale"(v) {
      this.$store.dispatch("SET_INIT", true);
      localStorage.setItem("language", v);
      this.$nextTick(() => this.$store.dispatch("SET_INIT", false));
    },

    "$route.params"() {
      this.isShowRoute = false;
      this.$nextTick(() => (this.isShowRoute = true));
    },
  },

  computed: {
    snackbar: (vm) => vm.$store.getters.GET_SNACKBAR,
    snackbar2: (vm) => vm.$store.getters.GET_SNACKBAR2,

    isInit: (vm) => vm.$store.getters.GET_INIT,
    isLoading: (vm) => vm.$store.getters.GET_LOADING,
    isLoading2: (vm) => vm.$store.getters.GET_LOADING2,
    size: (vm) => vm.$store.getters.GET_SIZE,
  },

  mounted() {
    this.onResize();
  },

  methods: {
    onResize: debounce(function () {
      const size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      if (size.width < 1264) {
        size.height = size.height - 35;
      }

      this.$store.dispatch("SET_SIZE", size);
    }, 500),
  },
};
</script>

<template>
  <v-app v-resize="onResize">
    <v-snackbar
      :top="snackbar.top == undefined ? true : snackbar.top"
      :right="snackbar.right"
      :bottom="snackbar.bottom"
      v-model="snackbar.isShow"
      :color="snackbar.color"
      :timeout="8500"
      style="z-index: 20011"
    >
      <div v-html="snackbar.text" />
      <template slot="action">
        <v-btn
          depressed
          min-width="40"
          width="40"
          :title="$t('close')"
          dark
          color="transparent"
          @click="snackbar.isShow = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      right
      bottom
      v-model="snackbar2.isShow"
      color="warning"
      :timeout="10000"
      style="z-index: 20011"
    >
      <div v-html="snackbar2.text" />
      <template slot="action">
        <v-btn
          depressed
          min-width="40"
          width="40"
          :title="$t('close')"
          dark
          color="transparent"
          @click="snackbar2.isShow = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <router-view v-if="!isInit && isShowRoute && size.height > 0" />
    <LoadingDialog :show="isInit || isLoading" />
    <LoadingDialog
      v-if="!isInit && !isLoading"
      :show="isLoading2"
      :hideOverlay="true"
    />
  </v-app>
</template>
