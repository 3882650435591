<script>
import { mapActions } from "vuex";
import { debounce } from "debounce";

export default {
  props: {
    object: {
      type: Object,
      default: () => ({}),
    },

    index: {
      type: Number,
      required: true,
    },

    isLast: {
      type: Boolean,
    },
  },

  data: () => ({
    entries: [],
    value: null, // Don't replace, parent uses with parameter 'value'
    oldValue: null,
    isLoaded: false,
    select: null,

    loaders: {},
  }),

  watch: {
    value(v) {
      if (v == this.select?.name) return;
      this.onSearch();
    },
  },

  computed: {
    isLoading: (vm) => !!Object.keys(vm.loaders).length,
  },

  methods: {
    ...mapActions("product", ["SEARCH"]),
    ...mapActions("pharmacy-requirements-join", ["SET_LINK", "SET_UNLINK"]),

    onSearch: debounce(async function () {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const text = `${this.object.productName} (${this.object.manufacturerName})`;
      const array = await this.SEARCH({ search: this.value, text });
      this.isLoaded = true;
      this.entries = array;
      this.$refs.autocomplete.isMenuActive = true;

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    }, 200),

    async onSetLink(item) {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.$refs.autocomplete.isMenuActive = true;

      const params = {
        guid: this.object.guid,
        productGuid: item.guid,
      };

      const status = await this.SET_LINK(params);
      if (status) this.$emit("selected");

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    async onSetUnlink() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.entries = [];

      const params = {
        guid: this.object.guid,
      };

      const status = await this.SET_UNLINK(params);
      if (status) {
        this.value = null;
        this.select = null;
      }

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onKeyDown(event) {
      if (event.keyCode == 27) {
        this.value = null;
        this.select = null;
      }
    },
  },
};
</script>

<template>
  <div
    class="reset-text-field position-relative"
    :id="`text-field-${index}`"
  >
    <v-autocomplete
      :attach="isLast ? undefined : `#text-field-${index}`"
      :search-input.sync="value"
      :items="entries"
      :loading="isLoading"
      item-text="name"
      item-value="guid"
      outlined
      dense
      hide-details
      no-filter
      v-model="select"
      return-object
      ref="autocomplete"
      style="height: 24px"
      @keydown.esc="onKeyDown"
    >
      <template #item="{ item, on, attrs }">
        <v-list-item
          style="border-bottom: 1px solid #e0e0e0"
          v-bind="attrs"
          v-on="on"
          @click="onSetLink(item)"
        >
          <v-list-item-title style="white-space: initial">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:append>
        <div>
          <v-btn
            x-small
            color="error"
            icon
            v-if="object.status == 1"
            @click="onSetUnlink"
            :loading="isLoading"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>
