<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "debounce";

import tnVue from "@/mixins/tn.vue";
import TableResizeVue from "@/mixins/table-resize.vue";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import Edit from "@/components/modals/selection-auto/edit.vue";
import SelectionAutoDuplicates from "@/components/modals/selection-auto/selection-auto-duplicates.vue";
import TableFilter from "@/components/modals/selection-auto/table-filter.vue";
import ImportFile from "./import-file.vue";
import TableFocusInput from "./table-focus-input.vue";
import TableCog from "@/components/table-cog.vue";
import RoundingCriteria from './rounding-criteria.vue'

export default {
  components: {
    TableFocusInput,
    ConfirmDialog,
    Edit,
    ImportFile,
    SelectionAutoDuplicates,
    TableFilter,
    TableCog,
    RoundingCriteria
  },

  props: {
    tableHeight: Number,
    ln: String,
  },

  data: () => ({
    sortBy: null,
    options: {},
    itemsPerPage: 50,

    loaders: {},
    selected: {},
    searchText: null,

    isToPrev: false,
    isSavePosition: false,

    isShowDeleteAll: false,
    isShowDelete: false,
    isShowDeleteDuplicates: false,

    isShowEdit: false,
    isShowMerge: false,
    isShowDuplicates: false,
    isShowRoundingCriteria: false,

    uniqKey: "selected-auto-list",

    isSelectionFilter: false,
    isShowCog: true,
    selectionFilterData: {},
    filteredHeaders: [],
  }),

  mixins: [tnVue, TableResizeVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("selection-auto", {
      entries: "GET",
      entriesCount: "GET_COUNT",
    }),

    pagesCount: (vm) => Math.ceil(vm.entriesCount / vm.itemsPerPage),
    isLoading: (vm) => !!Object.keys(vm.loaders).length,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.name"),
          value: "productName",
          width: vm.$dxcs.productName,
          _required: true,
        },
        {
          text: vm.tn("table.manufacturer"),
          value: "brandName",
          width: vm.$dxcs.manufacturer,
          headers: [{ text: "Travel", value: "travelQ1" }],
          _required: true,
        },
        {
          text: vm.tn("table.created_on"),
          value: "createdOn",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.modified_at"),
          value: "modifiedOn",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.need"),
          value: "stockNeed",
          width: vm.$dxcs.qty,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.quantity"),
          value: "stockQuantity",
          width: vm.$dxcs.qty,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.price"),
          value: "salePrice",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceRetailSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceRetailSalePrice"),
          value: "productReferentPriceRetailSalePrice",
          width: vm.$dxcs.price,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceWholeSalePrice"),
          value: "productReferentPriceWholeSalePrice",
          width: vm.$dxcs.price,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceDate"),
          value: "productReferentPriceDate",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.selling"),
          value: "saleQuantityDay30",
          width: vm.$dxcs.qty,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          value: "actions",
          class: "disable-resize",
          width: 30,
          sortable: false,
          _resize: false,
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),
  },

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },

    tableHeaders() {
      this.isShowCog = false;
      this.$nextTick(() => (this.isShowCog = true));
    },

    selected(v = {}) {
      this.$emit("select", { ...v });
    },
  },

  mounted() {
    document.addEventListener("keyup", this.onKeyup);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyup);
  },

  methods: {
    ...mapActions("selection-auto", {
      getEntriesApi: "GET_API",
      addApi: "POST_API",
      deleteApi: "DELETE_API",
      deleteAllApi: "DELETE_ALL_API",
      deleteDuplicatesApi: "DELETE_DUPLICATES_API",
      exportExcel: "EXPORT_EXCEL",
      startSelectionAuto: "START_SELECTION_AUTO",
    }),

    ...mapActions("pharmacy-requirements", {
      mergeApi: "MERGE_API",
    }),

    async getEntries(ee) {
      const isReset = ee?.reset;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          search: this.searchText,
        },
      };

      if (isReset) {
        this.options.page = 1;
        params.options.offset = 0;
      }

      params.filterData = {};

      await this.getEntriesApi(params);

      if (ee?.isntFocus || this.checkFocused()) {
        this.selected = {};
      } else {
        this.setFocusToItem();
      }

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onKeyup(e) {
      e.preventDefault();
      if (e.keyCode == 115) {
        this.$refs.searchBox?.focus();
      }
    },

    checkFocused() {
      return document.activeElement.className.includes(this.uniqKey);
    },

    setFocusToItem() {
      if (this.isSavePosition && this.selected?._id) {
        this.isSavePosition = false;
        const input = this.$setInputFocus(this.selected._id, this.uniqKey);
        if (!input) {
          this.isToPrev = true;
          this.setFocusToItem();
        }
      } else if (this.isToPrev) {
        this.isToPrev = false;
        const last = this.$lastArrayItem(this.entries);
        if (!last) return;
        this.selected = { ...last };
        this.$setInputFocus(this.selected._id, this.uniqKey);
      } else {
        const first = this.$firstArrayItem(this.entries);
        if (!first) return;
        this.selected = { ...first };
        this.$setInputFocus(this.selected._id, this.uniqKey);
      }
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onClear() {
      this.searchText = null;
      this.$nextTick(() => this.getEntries());
    },

    onPageUp() {
      if (this.options.page > 1) {
        this.isSavePosition = true;
        this.options.page--;
      }
    },

    onPageDown() {
      if (this.options.page < this.pagesCount) {
        this.isSavePosition = true;
        this.options.page++;
      }
    },

    onUpKey({ item }) {
      if (item._id == 1 && this.options.page > 1) {
        this.isToPrev = true;
        this.options.page--;
      } else {
        this.isToPrev = false;
        if (this.$setInputFocus(item._id - 1, this.uniqKey)) {
          const select = this.entries[item._id - 2];
          if (select) this.selected = { ...select };
        }
      }
    },

    onDownKey({ item }) {
      this.isToPrev = false;
      if (this.entries.length == item._id && this.options.page < this.pagesCount) {
        this.options.page++;
      } else if (this.$setInputFocus(item._id + 1, this.uniqKey)) {
        const select = this.entries[item._id];
        if (select) this.selected = { ...select };
      }
    },

    setFocusToTable() {
      this.isSavePosition = true;
      this.$nextTick(() => this.setFocusToItem());
    },

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.uniqKey);
    },

    onEditShow({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowEdit = true));
    },

    async onStart() {
      if (await this.checkDuplicates()) {
        this.isShowDuplicates = true;
      } else {
        this.isSelectionFilter = true;
      }
    },

    async checkDuplicates() {
      const params = { options: { limit: 0, offset: 0 } };
      await this.$store.dispatch("selection-auto/GET_DUPLICATE_ITEMS_API", params);
      return this.$store.getters["selection-auto/GET_DUPLICATE_ITEMS_COUNT"];
    },

    onDeleteShow({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowDelete = true));
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);
      await this.deleteApi(this.selected);
      setLoading(false);
      close();
      this.getEntries();
    },

    onDeleteAllShow() {
      this.isShowDeleteAll = true;
    },

    async onDeleteAll({ close, setLoading }) {
      setLoading(true);
      await this.deleteAllApi();
      setLoading(false);
      close();
      this.getEntries();
    },

    async onDeleteDuplicates({ close, setLoading }) {
      setLoading(true);
      await this.deleteDuplicatesApi();
      setLoading(false);
      close();
      this.getEntries();
    },

    async onMerge({ close, setLoading }) {
      setLoading(true);
      await this.mergeApi();
      setLoading(false);
      close();
      this.getEntries();
    },

    async onExportExcel() {
      this.$store.dispatch("SET_LOADING", true);
      await this.exportExcel();
      this.$store.dispatch("SET_LOADING", false);
    },

    async onSelectionFilter(params) {
      this.$store.dispatch("SET_LOADING", true);
      await this.startSelectionAuto(params);
      this.$store.dispatch("SET_LOADING", false);
    },
  },
};
</script>

<template>
  <div>
    <ConfirmDialog
      :text="tn('delete_alert')"
      @close="isShowDelete = false"
      v-if="isShowDelete"
      @accept="onDelete"
    />

    <ConfirmDialog
      :text="tn('delete_all_alert')"
      @close="isShowDeleteAll = false"
      v-if="isShowDeleteAll"
      @accept="onDeleteAll"
    />

    <ConfirmDialog
      :text="tn('delete_duplicates_alert')"
      @close="isShowDeleteDuplicates = false"
      v-if="isShowDeleteDuplicates"
      @accept="onDeleteDuplicates"
    />

    <ConfirmDialog
      :text="tn('merge_alert')"
      @close="isShowMerge = false"
      v-if="isShowMerge"
      @accept="onMerge"
    />

    <Edit
      :value="selected"
      @close="isShowEdit = false"
      v-if="isShowEdit"
      @saved="getEntries"
    />

    <SelectionAutoDuplicates
      @close="isShowDuplicates = false"
      v-if="isShowDuplicates"
    />

    <RoundingCriteria
      v-if="isShowRoundingCriteria"
      :ln="`${ln}.send`"
      @refresh="getEntries"
      @close="isShowRoundingCriteria = false"
    />

    <TableFilter
      :ln="ln + '.selection_filter'"
      v-if="isSelectionFilter"
      :value="selectionFilterData"
      @close="isSelectionFilter = false"
      @apply="onSelectionFilter"
    />

    <div class="d-flex align-center">
      <slot name="actions" />

      <div class="py-6 pl-2"></div>
      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :loading="isLoading"
        :title="tn('refresh_btn')"
        class="mr-2"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <ImportFile
        :title="tn('import_excel_btn')"
        :disabled="isLoading"
        :notFoundAlert="tn('data_not_found_alert')"
        :tn="tn"
      />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="onExportExcel"
        :disabled="isLoading"
        :title="tn('export_excel_btn')"
        class="mr-2"
      >
        <v-icon> mdi-file-excel-outline </v-icon>
      </v-btn>

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="onDeleteAllShow"
        :disabled="isLoading"
        :title="tn('delete_btn')"
        class="mr-2"
      >
        <v-icon> mdi-delete-outline </v-icon>
      </v-btn>

      <v-btn
        color="error lighten-1"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="isShowDeleteDuplicates = true"
        :disabled="isLoading"
        :title="tn('delete_duplicates_btn')"
        class="mr-2"
      >
        <v-icon> mdi-delete-sweep-outline </v-icon>
      </v-btn>

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="isShowMerge = true"
        :disabled="isLoading"
        :title="tn('merge_btn')"
        class="mr-2"
      >
        <v-icon> mdi-source-branch </v-icon>
      </v-btn>

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="isShowRoundingCriteria = true"
        :title="tn('rounding_criteria_btn')"
        class="mr-2"
      >
        <v-icon> mdi-circle-edit-outline </v-icon>
      </v-btn>

      <v-spacer />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="onStart"
        :disabled="isLoading"
        :title="tn('search_btn')"
        class="mr-2"
      >
        <v-icon> mdi-magnify </v-icon>
      </v-btn>

      <v-spacer />

      <table-cog
        :headersData="tableHeaders"
        v-if="isShowCog"
        @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
        :cacheKey="uniqKey"
      />

      <div class="mx-1" />

      <v-text-field
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        style="max-width: 300px; width: 100%"
        @click:append="onSearch"
        @keyup.enter="onSearch"
        @click:clear="onClear"
        :label="tn('search')"
        clearable
        v-model="searchText"
        ref="searchBox"
      />
    </div>

    <slot />

    <div class="mt-1" />

    <v-data-table
      v-if="filteredHeaders.length && tableHeight > 0"
      :headers="filteredHeaders"
      dense
      :height="tableHeight"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :items="entries"
      :server-items-length="entriesCount"
      :items-per-page.sync="itemsPerPage"
      fixed-header
      must-sort
      :sort-by="sortBy"
      :footer-props="{
        itemsPerPageOptions: [25, 50],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: $t('table.per_page_text'),
      }"
      key="guid"
      class="table-footer-nowrap fixed-right"
      v-resize-table="{ tableHeaders: filteredHeaders, callback: onUpdateTdSizes }"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr
          class="cursor-pointer"
          @click.stop="onSelect({ item })"
          :class="{
            'tr-active': selected._id == item._id,
            'tr-active-v2': item.isDuplicated,
          }"
        >
          <template v-for="head in headers">
            <td
              v-if="head.value == 'number'"
              :key="head.value"
              class="px-0 text-center"
            >
              <span
                class="text-nowrap"
                v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
              />
            </td>

            <td
              v-else-if="head.value == 'productName'"
              :key="head.value + 4"
              :style="{ width: head.width }"
            >
              <table-focus-input
                :ref="item.id"
                :tabindex="index + 1"
                :item="item"
                :uniKey="uniqKey"
                @upKey="onUpKey({ item })"
                @enter="onDownKey({ item })"
                @downKey="onDownKey({ item })"
                @pageUp="onPageUp({ item })"
                @pageDown="onPageDown({ item })"
              />
              <div
                :style="{ minWidth: head.minWidth }"
                v-text="item[head.value]"
              />
            </td>

            <td
              v-else-if="head.value == 'actions'"
              :key="head.value + 3"
              class="px-0"
              :style="{ width: head.width }"
            >
              <div class="d-flex justify-center">
                <v-btn
                  :title="tn('table.actions.edit')"
                  @click="onEditShow({ item })"
                  depressed
                  min-width="36"
                  width="36"
                  min-height="36"
                  height="36"
                  color="transparent"
                >
                  <v-icon color="secondary"> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  :title="tn('table.actions.delete')"
                  @click="onDeleteShow({ item })"
                  depressed
                  min-width="36"
                  width="36"
                  min-height="36"
                  height="36"
                  color="transparent"
                >
                  <v-icon color="secondary"> mdi-delete </v-icon>
                </v-btn>
              </div>
            </td>

            <td
              v-else
              :key="head.value"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div
                :style="{ minWidth: head.minWidth, textAlign: head._align, ...head._getStyle?.(item[head.value], item) }"
                v-html="head._filter ? head._filter(item[head.value], item) : item[head.value]"
                :class="{ 'text-nowrap': head._wrap }"
              />
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped>
.tb-action-wrap {
  transform: translateY(-9px);
}
</style>
