import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;
let cancelSource1 = null;
let cancelSource2 = null;

export const state = () => ({
  itemsData: {},
  linkedProductsData: {},
});

export const mutations = {
  SET(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.data?.items).map((x, i) => {
        x._id = i + 1;
        return x;
      }),
      total: vm.$number(payload?.data?.totalCount),
    };
  },

  SET_LINKED_PRODUCTS(state, payload) {
    const data = payload?.data;
    state.linkedProductsData = {
      items: vm.$array(data?.items).map((x, i) => {
        x._id = i + 1;
        return x;
      }),
      total: vm.$number(data?.totalCount),
    };
  },

  REPLACE_LINKED_PRODUCT(state, payload) {
    state.linkedProductsData.items = state.linkedProductsData.items.map((x) => (x.guid == payload.guid ? payload : x));
  },
};

export const actions = {
  async GET_PRODUCTS_API({ commit }, { options, isCancel }) {
    cancelSource2?.cancel();
    cancelSource2 = CancelToken.source();

    commit("SET");
    if (isCancel) return;

    const params = { ...options };
    const request = { progress: false, cancelToken: cancelSource2.token };
    const res = await vm.$axios.$post("Product/Linked/PriceListViewerProduct", params, request);
    if (res?.succeeded) commit("SET", { data: res?.data });
  },

  async GET_API({ commit }, { options, isCancel, filterData }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_LINKED_PRODUCTS");
    if (isCancel) return;

    const params = { ...options, ...filterData };
    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$post("Product/Linked", params, request);
    if (res?.succeeded) commit("SET_LINKED_PRODUCTS", { data: res?.data });
  },

  async SEARCH(ctx, { search, text }) {
    cancelSource1?.cancel();
    cancelSource1 = CancelToken.source();

    const res = await vm.$axios.$get("Product/Lookup", { search, text });
    return res.succeeded ? res.data.items : [];
  },

  async SEARCH_SUGGEST(ctx, payload) {
    cancelSource1?.cancel();
    cancelSource1 = CancelToken.source();
    const res = await vm.$axios.$get("ProductSuggestion", payload);
    return res.succeeded ? res.data.items : [];
  },

  async GET_FILTER_LETTER_API() {
    return vm.$array((await vm.$axios.$get("Product/FirstLetter"))?.data);
  },

  async FAVORITE_API({ commit }, payload) {
    let status = false;
    const url = `PriceListViewerFavoriteProduct/${payload.guid}`;
    payload.isFavorite = !payload.isFavorite;
    if (payload.isFavorite) status = (await vm.$axios.$post(url))?.succeeded;
    else status = (await vm.$axios.$delete(url))?.succeeded;

    if (status) commit("REPLACE_LINKED_PRODUCT", payload);
  },

  async ALERT_API({ commit }, payload) {
    let status = false;
    const url = `PriceListViewerProductAlert/${payload.guid}`;
    payload.isAlert = !payload.isAlert;
    if (payload.isAlert) status = (await vm.$axios.$post(url))?.succeeded;
    else status = (await vm.$axios.$delete(url))?.succeeded;

    if (status) commit("REPLACE_LINKED_PRODUCT", payload);
  },
};

export const getters = {
  GET: (state) => vm.$array(state.linkedProductsData?.items),
  GET_COUNT: (state) => state.linkedProductsData?.total || 0,
  GET_PRODUCT: (state) => vm.$array(state.itemsData?.items),
  GET_PRODUCT_COUNT: (state) => state.itemsData?.total || 0,
};
