import router from "@/router";
import Vue from "vue";
const vm = Vue.prototype;

const url = "PriceListViewerChangePhoneRequest";
const url2 = "PriceListViewerChangeInnRequest";
const url3 = "PriceListViewerJoinBotRequest";

export const state = () => ({
  //
});

export const mutations = {
  //
};

export const actions = {
  async SEND_SMS_CODE(ctx, payload) {
    const uri = router.currentRoute.name == "inn" ? url2 : url;
    const res = await vm.$axios.$post(`${uri}/sendSmsCode`, payload);
    return res?.succeeded;
  },

  async GET_USER_PHONE_API() {
    const res = await vm.$axios.$get(`${url}/phone`);
    return res?.succeeded ? res.data : null;
  },

  async GET_TELEGRAM_CONNECT_REQUEST_API() {
    const res = await vm.$axios.$get(`${url3}/GetCurrent`);
    return res?.succeeded ? res.data : null;
  },

  async SET_TELEGRAM_CONNECT_REQUEST_API() {
    const res = await vm.$axios.$post(`${url3}`);
    return res?.succeeded ? res.data : null;
  },

  async CHECK_TELEGRAM_CONNECT_REQUEST_API() {
    const res = await vm.$axios.$get(`${url3}/Check`);
    return res?.succeeded ? res.data : null;
  },

  async EDIT_PHONE(ctx, payload) {
    const res = await vm.$axios.$post(`${url}/editPhone`, payload);
    return res?.succeeded;
  },

  async GET_USER_INN_API() {
    const res = await vm.$axios.$get(`${url2}/Inn`);
    return res?.succeeded ? res.data : null;
  },

  async EDIT_INN(ctx, payload) {
    const res = await vm.$axios.$post(`${url2}/editInn`, payload);
    return res?.succeeded;
  },
};

export const getters = {
  //
};
