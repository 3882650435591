import Vue from "vue";
import Inputmask from "inputmask";

const inputmaskPlugin = {
  install(Vue) {
    Vue.directive("inputmask", {
      bind(el, binding) {
        if (el.tagName == "INPUT") {
          Inputmask(binding.value).mask(el);
        } else {
          Inputmask(binding.value).mask(el.getElementsByTagName("INPUT"));
        }
      },
    });
  },
};

Vue.use(inputmaskPlugin);
