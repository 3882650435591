import Vue from "vue";
const vm = Vue.prototype;

const url = "PriceListViewerOrganizationStatistic";

export const state = () => ({
  //
});

export const mutations = {
  //
};

export const actions = {
  async SET_LINK({ commit }, payload) {
    const params = {
      guid: payload.guid,
      productGuid: payload.productGuid,
    };

    const res = await vm.$axios.$put(`${url}/link`, params);
    let status = res?.succeeded;

    if (res?.data?.rowCount > 1) {
      return res?.data?.rowCount;
    } else {
      if (status) commit("pharmacy-requirements/REPLACE_ITEM", res.data, { root: true });
      return status;
    }
  }

};

export const getters = {
  //
};
