import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;
let cancelSource2 = null;

export const state = () => ({
  providersData: {},
  productsData: {},
});

export const mutations = {
  SET_PROVIDERS_DATA(state, payload) {
    const data = payload?.data;

    state.providersData = {
      items: vm.$array(data?.items).map((x, i) => {
        x._id = i + 1;
        return x;
      }),
      total: vm.$number(data?.totalCount),
    };
  },

  SET_PRODUCTS_DATA(state, payload) {
    const data = payload?.data;

    state.productsData = {
      items: vm.$array(data?.items).map((x, i) => {
        x._id = i + 1;
        return x;
      }),
      total: vm.$number(data?.totalCount),
    };
  },
};

export const actions = {
  async GET_PROVIDERS_API({ commit }, { options, isCancel }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_PROVIDERS_DATA");
    if (isCancel) return;

    const request = { progress: false, cancelToken: cancelSource.token };
    const guid = options.guid;
    delete options.guid;

    const params1 = { ...options };

    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const url = `PriceListViewerProductSelection/Result/${guid}/ProviderList`;
    const res = await vm.$axios.$get(url, params, request);
    if (res?.succeeded) commit("SET_PROVIDERS_DATA", { data: res?.data });
  },

  async GET_PRODUCTS_API({ commit }, { options, isCancel }) {
    cancelSource2?.cancel();
    cancelSource2 = CancelToken.source();

    commit("SET_PRODUCTS_DATA");
    if (isCancel) return;

    const request = { progress: false, cancelToken: cancelSource2.token };
    const guid = options.guid;
    delete options.guid;

    const params1 = { ...options };

    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const url = `PriceListViewerProductSelection/Result/${guid}/ProductList`;
    const res = await vm.$axios.$get(url, params, request);
    if (res?.succeeded) commit("SET_PRODUCTS_DATA", { data: res?.data });
  },
};

export const getters = {
  GET_PROVIDERS: (state) => vm.$array(state.providersData?.items),
  GET_PROVIDERS_COUNT: (state) => state.providersData?.total || 0,
  GET_PRODUCTS: (state) => vm.$array(state.productsData?.items),
  GET_PRODUCTS_COUNT: (state) => state.productsData?.total || 0,
};
