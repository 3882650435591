<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";

import TransitionExpand from "@/components/transition-expand.vue";
import TableFilterProviders from "./table-filter-providers.vue";
import TableFilterRegions from "./table-filter-regions.vue";

export default {
  components: {
    ModalsTemplate,
    TransitionExpand,
    TableFilterProviders,
    TableFilterRegions,
  },

  props: {
    value: Object,
  },

  data: () => ({
    dialog: true,
    isLoading: false,

    ln: "price_list.compare.table.filter",

    formData: { isFavoriteProvider: null },
    isShowProviders: true,
    isShowRegions: false,

    tableHeight: 150,

    selectedProvidersCount: 0,
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  computed: {
    favoriteOrOthers: (vm) => [
      {
        name: vm.tn("all_providers"),
        value: null,
      },
      {
        name: vm.tn("show_fav_providers"),
        value: true,
      },
      {
        name: vm.tn("other_providers"),
        value: false,
      },
    ],
  },

  created() {
    if (Object.values(this.value).length) {
      this.formData = this.value;

      if (this.value.regionGuids?.length) {
        this.isShowRegions = true;
      }
    }
  },

  methods: {
    async onApply() {
      if (this.$refs.form.validate()) {
        const formData = { ...this.formData };

        const providerGuids = this.$array(this.$refs.providers?.selectedRows);
        const regionGuids = this.$array(this.$refs.regions?.selectedRows);

        formData.providerGuids = providerGuids.map((x) => x.guid).filter((x) => x);
        if (!formData.providerGuids.length) {
          delete formData.providerGuids;
        }

        formData.regionGuids = regionGuids.map((x) => x.guid).filter((x) => x);
        if (!formData.regionGuids.length) {
          delete formData.regionGuids;
        }

        this.$emit("apply", formData);
        this.dialog = false;
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },

    onReset() {
      this.$refs.providers.selectedRows = [];
      this.$refs.regions.selectedRows = [];
      this.formData = {};
      this.$nextTick(() => this.onApply());
    },

    onChangeFavoriteOrOthers() {
      if (this.formData.isFavoriteProvider != false) {
        this.$refs.providers.selectedRows = [];
      }
    },
  },
};
</script>

<template>
  <modals-template
    :width="600"
    v-model="dialog"
    :title="tn('title')"
    :persistent="false"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <div class="d-flex align-center">
        <v-btn
          @click="isShowProviders = !isShowProviders"
          color="primary"
          outlined
          x-small
          icon
        >
          <v-icon v-if="isShowProviders"> mdi-minus </v-icon>
          <v-icon v-else> mdi-plus </v-icon>
        </v-btn>

        <div class="d-flex align-items-center">
          <h4
            class="pl-2"
            v-text="tn('providers')"
          />
          <div
            v-if="selectedProvidersCount"
            class="pl-1 error--text"
          >
            ({{ selectedProvidersCount }})
            <span
              @click="$refs.providers.selectedRows = []"
              class="span-link"
            >
              {{ tn("clear_providers") }}
            </span>
          </div>
        </div>
      </div>

      <TransitionExpand>
        <div v-show="isShowProviders">
          <div class="pt-2" />
          <v-select
            dense
            v-model="formData.isFavoriteProvider"
            :items="favoriteOrOthers"
            outlined
            hide-details
            item-text="name"
            item-value="value"
            @change="onChangeFavoriteOrOthers"
          />

          <TransitionExpand>
            <div v-show="formData.isFavoriteProvider == false">
              <div class="pt-2" />
              <table-filter-providers
                @setCount="(v) => (selectedProvidersCount = v)"
                :value="formData.providerGuids"
                ref="providers"
                :tableHeight="tableHeight"
              />
            </div>
          </TransitionExpand>
        </div>
      </TransitionExpand>

      <div class="pt-4" />

      <table-filter-regions
        @switchHide="isShowRegions = !isShowRegions"
        :isShow="isShowRegions"
        :value="formData.regionGuids"
        ref="regions"
        :tableHeight="tableHeight"
      />

      <div class="d-flex align-center justify-center">
        <v-btn
          height="45"
          :loading="isLoading"
          color="error"
          @click="onReset"
          elevation="0"
          class="mt-5 mb-1 w-49"
        >
          {{ tn("reset_btn") }}
        </v-btn>
        <div class="px-2" />
        <v-btn
          height="45"
          :loading="isLoading"
          color="primary"
          @click="onApply"
          elevation="0"
          class="mt-5 mb-1 w-49"
        >
          {{ tn("apply_btn") }}
        </v-btn>
      </div>
    </v-form>
  </modals-template>
</template>
