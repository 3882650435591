<script>
import tnVue from "@/mixins/tn.vue";
import { mapActions } from "vuex";
import ModalsTemplate from "@/components/modals-template.vue";

export default {
  components: { ModalsTemplate },

  mixins: [tnVue],

  props: {
    ln: String,
  },

  data: () => ({
    dialog: true,
    isInit: false,
    formData: {
      less1: 1,
      large1: 25,
    },
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 120);
    },
  },

  created() {
    const formData = this.$browserStore.getSession("rt-requirements-send-criteria");
    if (formData?.less1 && formData?.large1) {
      this.formData = { ...formData }
    }
    this.isInit = true;
  },

  methods: {
    ...mapActions("selection-auto", {
      roundStockNeedQtyApi: "ROUND_STOCK_NEED_QTY_API",
    }),

    async onApply() {
      this.$store.dispatch("SET_LOADING", true);

      if (!this.$refs.form.validate()) return;

      const status = await this.roundStockNeedQtyApi({ ...this.formData });
      if (status) {
        this.$browserStore.setSession("rt-requirements-send-criteria", this.formData);
        this.$emit("refresh");
        this.dialog = false;
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("success"),
          color: "success",
        });
      }

      this.$store.dispatch("SET_LOADING", false);
    },

    toDouble(num) {
      if (num == 100) return 1;
      return "0." + `${num}`.padStart(2, "0");
    },

    toDouble1(num) {
      if (num == 100) return 1;
      return "1." + `${num}`.padStart(2, "0");
    },
  },
};
</script>

<template>
  <ModalsTemplate
    v-if="isInit"
    v-model="dialog"
    :title="tn('title')"
    :width="420"
  >
    <v-form
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <div class="pb-2">
        {{ tn("form.small_than_one") }}
      </div>
      <div class="d-flex align-center">
        <v-text-field
          v-model.number="formData.less1"
          v-inputmask="$masks.numberMax({ max: 100, min: 0, digits: 0 })"
          style="max-width: 100px"
          autofocus
          hide-details
          dense
          outlined
          :rules="[$rules.required]"
        />
        <div class="ma-1" />
        <div style="color:#000000de;font-size: 20px;">: 100</div>
      </div>

      <div class="pt-2 pb-3">
        {{ tn("form.about") }}
        <br />

        {{ tn("form.need_quantity") }}:
        <span :class="formData.less1 > 1 && 'error--text'">
          {{ toDouble(formData.less1 > 0 ? formData.less1 - 1 : 0) }}
          &#8594;
          {{ formData.less1 == 0 ? 1 : 0 }}
        </span>

        <br />
        {{ tn("form.need_quantity") }}: {{ toDouble(formData.less1) }} &#8594; 1
      </div>

      <v-divider />

      <div class="pt-3 pb-2">
        {{ tn("form.large_than_one") }}
      </div>
      <div class="d-flex align-center">
        <v-text-field
          v-model.number="formData.large1"
          v-inputmask="$masks.numberMax({ max: 99, min: 0, digits: 0 })"
          style="max-width: 100px"
          hide-details
          dense
          outlined
          :rules="[$rules.non_zero]"
        />
        <div class="ma-1" />
        <div style="color:#000000de;font-size: 20px;">: 100</div>
      </div>

      <div class="pt-2 pb-5">
        {{ tn("form.about") }}
        <br />
        {{ tn("form.need_quantity") }}:
        <span :class="formData.large1 > 1 && 'error--text'">
          {{ toDouble1(formData.large1 > 1 ? formData.large1 - 1 : 0) }}
          &#8594; 1
        </span>

        <br />
        {{ tn("form.need_quantity") }}: {{ toDouble1(formData.large1) }} &#8594; 2
      </div>
    </v-form>

    <v-btn
      dark
      block
      color="primary"
      depressed
      @click="onApply"
    >
      {{ tn("send_btn") }}
    </v-btn>
  </ModalsTemplate>
</template>
