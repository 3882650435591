<script>
import TableFocusInput from "@/components/table-focus-input.vue";

export default {
  components: { TableFocusInput },

  data: () => ({
    loaders: {},

    options: {
      itemsPerPage: 50,
    },

    searchText: null,
    selected: {},

    isToPrev: false,
    isSavePosition: false,

    filteredHeaders: [],
  }),

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,
    pagesCount: (vm) => Math.ceil(vm.entriesCount / vm.options.itemsPerPage),
    isLoading: (vm) => !!Object.keys(vm.loaders).length,
  },

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  mounted() {
    document.addEventListener("keyup", this.onKeyup);
    document.addEventListener("keydown", this.onKeydown);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyup);
    document.removeEventListener("keydown", this.onKeydown);
  },

  methods: {
    setFocusToItem() {
      if (this.isSavePosition && this.selected?._id) {
        this.isSavePosition = false;
        const input = this.$setInputFocus(this.selected._id, this.uniqKey);
        if (!input) {
          this.isToPrev = true;
          this.setFocusToItem();
        }
      } else if (this.isToPrev) {
        this.isToPrev = false;
        const last = this.$lastArrayItem(this.entries);
        if (!last) return;
        this.selected = { ...last };
        this.$setInputFocus(this.selected._id, this.uniqKey);
      } else {
        const first = this.$firstArrayItem(this.entries);
        if (!first) return;
        this.selected = { ...first };
        this.$setInputFocus(this.selected._id, this.uniqKey);
      }
    },

    onPageUp() {
      if (this.options.page > 1) {
        this.isSavePosition = true;
        this.options.page--;
      }
    },

    onPageDown() {
      if (this.options.page < this.pagesCount) {
        this.isSavePosition = true;
        this.options.page++;
      }
    },

    onUpKey({ item }) {
      if (item._id == 1 && this.options.page > 1) {
        this.isToPrev = true;
        this.options.page--;
      } else {
        this.isToPrev = false;
        if (this.$setInputFocus(item._id - 1, this.uniqKey)) {
          const select = this.entries[item._id - 2];
          if (select) this.selected = { ...select };
        }
      }
    },

    onDownKey({ item }) {
      this.isToPrev = false;
      if (this.entries.length == item._id && this.options.page < this.pagesCount) {
        this.options.page++;
      } else if (this.$setInputFocus(item._id + 1, this.uniqKey)) {
        const select = this.entries[item._id];
        if (select) this.selected = { ...select };
      }
    },

    setFocusToTable() {
      this.isSavePosition = true;
      this.$nextTick(() => this.setFocusToItem());
    },

    onKeydown(e) {
      if (e?.code == "PageDown" || e?.code == "PageUp") {
        e.preventDefault();
      }
    },
  },
};
</script>
