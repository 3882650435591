<script>
import tnVue from "@/mixins/tn.vue";
import { mapActions } from "vuex";
import ModalsTemplate from "@/components/modals-template.vue";

export default {
  components: { ModalsTemplate },

  props: {
    priceListProviderGuid: String,
    ln: String,
    value: Object,
    contactList: Array,
  },

  data() {
    const contacts = this.contactList.map((item, index) => ({ ...item, _index: index + 1 }));
    let phone = null;

    if (this.value?.phone) {
      const item = this.contactList.find((x) => x.phone == this.value.phone);
      if (item) phone = item.phone.replace(/^998/, "");
    }

    return {
      dialog: true,
      isLoading: false,
      phone: phone,
      contacts: contacts,
    };
  },

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    ...mapActions("share-contact", {
      addApi: "ADD_API",
      checkApi: "GET_CHECK_API",
    }),

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        const phone = `998${this.phone}`;
        let numbersList = [...this.contacts];
        const existingContactIndex = numbersList.findIndex((obj) => obj.phone == this.value.phone);

        if (existingContactIndex != -1) numbersList[existingContactIndex].phone = phone;
        else if (this.phone) numbersList.push({ phone: phone });

        const params = {
          providerGuid: this.priceListProviderGuid,
          contactList: numbersList.map((contact) => contact.phone),
        };

        const status = await this.addApi(params);
        this.isLoading = false;

        if (status) {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.$t("success"),
            color: "success",
          });
          this.$emit("saved");
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template
    :width="320"
    :title="tn('title')"
    v-model="dialog"
  >
    <v-form
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      {{ tn("form.phone_number") }}
      <v-text-field
        v-model="phone"
        :rules="[$rules.phone]"
        v-inputmask="$masks.phone"
        hide-details
        dense
        outlined
        autofocus
        @keyup.enter="onSave"
      />
    </v-form>

    <div class="d-flex justify-center pt-5">
      <v-btn
        height="40"
        color="primary"
        max-width="300"
        width="100%"
        depressed
        @click="onSave"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </div>
  </modals-template>
</template>
