<script>
import tnVue from "@/mixins/tn.vue";
import { mapActions, mapGetters } from "vuex";

import TableCog from "@/components/table-cog.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TablePagination from "@/mixins/table-pagination.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import { getCurrentDate } from "@/filters/date";

const currentDate = getCurrentDate();
const datePlus1Month = getCurrentDate(null, 1);
const datePlus3Month = getCurrentDate(null, 3);
const datePlus6Month = getCurrentDate(null, 6);

export default {
  mixins: [tnVue, TableNavFunctionsVue, TablePagination, TableResizeVue],
  components: { TableCog },

  props: {
    ln: String,
    tableHeight: Number,
    priceListViewerOrderItem: Object,
    onHide: Function,
    isDetailsBtn: Boolean,
  },

  data: (vm) => ({
    sortBy: null,
    options: vm.$getSortLocal({ key: "price-list-viewer-order-items" }),
    uniqKey: "price-list-viewer-order-items",
  }),

  computed: {
    ...mapGetters("price-list-viewer-order-items", ["GET", "GET_COUNT", "GET_TOTALS"]),

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,
    entriesTotals: (vm) => vm.GET_TOTALS,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "_number",
          width: 10,
          sortable: false,
          class: "disable-resize",
          _required: true,
          _resize: false,
          align: "center",
        },
        {
          text: vm.tn("table.name"),
          value: "productName",
          _required: true,
          minWidth: "180px",
          width: "450px",
        },
        {
          text: vm.tn("table.manufacturer"),
          value: "manufacturerName",
          _required: true,
          width: "200px",
        },
        {
          text: vm.tn("table.order"),
          value: "quantityOrdered",
          _filter: (v) => vm.$sum(v),
          _align: "right",
          width: "120px",
        },
        {
          text: vm.tn("table.confirmation"),
          value: "quantityAccepted",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.quantityOrdered;
            if (v && mxp && v > mxp) return "red";
          },
          _align: "right",
          width: "120px",
        },
        {
          text: vm.tn("table.shelf_life"),
          value: "shelfLife",
          _filter: (v) => vm.$date(v),
          width: "98px",
        },
        {
          text: vm.tn("table.order_amount"),
          value: "totalAmountOrdered",
          _filter: (v) => vm.$sum(v),
          _align: "right",
          width: "120px",
        },
        {
          text: vm.tn("table.amount_confirmation"),
          value: "totalAmountAccepted",
          _filter: (v) => vm.$sum(v),
          _align: "right",
          width: "160px",
        },
        {
          text: "",
          value: "actions",
          width: "100%",
          maxWidth: "450px",
          sortable: false,
          _resize: false,
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),
  },

  beforeDestroy() {
    this.getEntriesApi({ clear: true });
  },

  methods: {
    ...mapActions("price-list-viewer-order-items", {
      getEntriesApi: "GET_API",
    }),

    async getEntries(payload) {
      if (payload?.reset) this.options.page = 1;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.$setSortLocal({
        key: "price-list-viewer-order-items",
        options: this.options,
      });

      const params = {
        options: {
          orderGuid: this.priceListViewerOrderItem?.guid,
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
        },
      };

      if (!params.options.sortBy) delete params.options.sortDirection;

      await this.getEntriesApi(params);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.uniqKey);
    },

    getShelfStyle(item) {
      const style = {};
      if (item.shelfLife == "0001-01-01T00:00:00") return style;
      else if (item.shelfLife <= currentDate) style.backgroundColor = "#ff958f";
      else if (item.shelfLife <= datePlus1Month) style.backgroundColor = "#ffced6";
      else if (item.shelfLife <= datePlus3Month) style.backgroundColor = "#ffbd45";
      else if (item.shelfLife <= datePlus6Month) style.backgroundColor = "yellow";
      return style;
    },

    onExit() {
      if (!this.$router.back()) this.$router.replace("/orders");
    },
  },
};
</script>

<template>
  <div>
    <div
      class="d-flex align-center pt-1"
      :class="isDetailsBtn ? 'pb-1' : 'pb-0'"
    >
      <v-btn
        color="primary"
        class="mr-5"
        @click.stop="onHide"
        outlined
      >
        {{ tn("details_btn") }}
        <div class="pl-1" />
        <v-icon v-if="isDetailsBtn"> mdi-chevron-down </v-icon>
        <v-icon v-else> mdi-chevron-right </v-icon>
      </v-btn>

      <v-divider class="mr-1" />
      <template v-if="isDetailsBtn">
        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          @click="getEntries"
          :disabled="isLoading"
          :loading="isLoading"
          :title="tn('refresh_btn')"
          class="ml-5"
        >
          <v-icon> mdi-sync </v-icon>
        </v-btn>

        <div class="mx-1" />

        <table-cog
          :headersData="tableHeaders"
          @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
          :cacheKey="'price-list-viewer-order-items-table-cog'"
        />
      </template>
    </div>

    <template v-if="isDetailsBtn && priceListViewerOrderItem?.guid">
      <div v-if="priceListViewerOrderItem?.guid">
        <v-data-table
          v-if="filteredHeaders.length && tableHeight > 0"
          :headers="filteredHeaders"
          dense
          fixed-header
          disable-pagination
          hide-default-footer
          class="fixed-left fixed-right"
          :items="entries"
          :mobile-breakpoint="0"
          :loading="isLoading"
          :options.sync="options"
          :server-items-length="entriesCount"
          :height="tableHeight"
          v-resize-table="{ tableHeaders: filteredHeaders, callback: onUpdateTdSizes }"
        >
          <template v-slot:item="{ item, headers, index }">
            <tr
              class="cursor-pointer"
              @click.stop="onSelect({ item })"
              :class="selected.guid == item.guid && 'tr-active'"
            >
              <template v-for="head in headers">
                <td
                  v-if="head.value == '_number'"
                  :key="head.value"
                  class="px-0 text-center"
                >
                  <span
                    class="text-nowrap"
                    v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
                  />
                  <table-focus-input
                    :ref="item.guid"
                    :tabindex="item._id"
                    :item="item"
                    :uniqKey="uniqKey"
                    @upKey="onUpKey({ item })"
                    @downKey="onDownKey({ item })"
                    @pageUp="onPageUp({ item })"
                    @pageDown="onPageDown({ item })"
                  />
                </td>

                <td
                  v-else-if="head.value == 'shelfLife'"
                  :key="head.value + 1"
                  :style="{
                    width: tdSizes[head.value] || head.width,
                    maxWidth: tdSizes[head.value] || head.width,
                    ...getShelfStyle(item),
                  }"
                >
                  <div
                    class="text-nowrap"
                    v-html="head._filter(item[head.value], item)"
                    :style="{
                      textAlign: head._align,
                      minWidth: head.minWidth,
                      color: head._getColor ? head._getColor(item[head.value], item) : null,
                    }"
                  >
                  </div>
                </td>

                <td
                  v-else
                  :key="head.value + 2"
                  :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
                >
                  <div
                    :style="{ minWidth: head.minWidth, textAlign: head._align, ...head._getStyle?.(item[head.value], item) }"
                    v-html="head._filter ? head._filter(item[head.value], item) : item[head.value]"
                    :class="{ 'text-nowrap': head._wrap }"
                  />
                </td>
              </template>
            </tr>
          </template>

          <template
            v-if="entriesCount > 1"
            #body.append="bodyProps"
          >
            <tr class="sticky-table-footer">
              <td
                v-for="(header, i) in bodyProps.headers"
                :key="i"
              >
                <div
                  v-if="header.value == '_number'"
                  class="text-nowrap text-center"
                  v-text="$sum(entriesTotals.count)"
                />
                <div
                  v-if="header.value"
                  class="text-nowrap text-right"
                  v-text="$sum(entriesTotals[header.value], true)"
                />
              </td>
            </tr>
          </template>

          <template #footer>
            <TablePaginationButtons
              :loading="isLoading"
              :options="options"
              :lastPage="lastPage"
              :perPageInput="perPageInput"
              :pageInput="pageInput"
              :entriesCount="entriesCount"
              :onLastPage="onLastPage"
              :onChangePerPageInput="onChangePerPageInput"
              :onChangePageInput="onChangePageInput"
            />
          </template>
        </v-data-table>
      </div>
    </template>
  </div>
</template>
