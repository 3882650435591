<script>
const initMover = () => {
  (function () {
    // make vuetify dialogs movable
    const d = {};
    document.addEventListener("mousedown", (e) => {
      const closestDialog = e.target.closest(".v-dialog.v-dialog--active");

      if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-toolbar__title")) {
        // element which can be used to move element
        d.el = closestDialog; // element which should be moved
        d.mouseStartX = e.clientX;
        d.mouseStartY = e.clientY;
        d.elStartX = d.el.getBoundingClientRect().left;
        d.elStartY = d.el.getBoundingClientRect().top;
        d.el.style.position = "fixed";
        d.el.style.margin = 0;
        d.oldTransition = d.el.style.transition;
        d.el.style.transition = "none";
      }
    });
    document.addEventListener("mousemove", (e) => {
      if (d.el === undefined) return;
      d.el.style.left = Math.min(Math.max(d.elStartX + e.clientX - d.mouseStartX, 0), window.innerWidth - d.el.getBoundingClientRect().width) + "px";
      d.el.style.top = Math.min(Math.max(d.elStartY + e.clientY - d.mouseStartY, 0), window.innerHeight - d.el.getBoundingClientRect().height) + "px";
    });
    document.addEventListener("mouseup", () => {
      if (d.el === undefined) return;
      d.el.style.transition = d.oldTransition;
      d.el = undefined;
    });
    setInterval(() => {
      // prevent out of bounds
      const dialog = document.querySelector(".v-dialog.v-dialog--active");
      if (dialog === null) return;
      dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
      dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
    }, 100);
  })();
};

export default {
  props: {
    title: String,
    width: {
      type: [String, Number],
      default: () => 550,
    },
    maxWidth: {
      type: [String, Number],
      default: () => null,
    },
    contentClass: {
      type: String,
      default: () => "py-5",
    },
    persistent: {
      type: Boolean,
      default: () => true,
    },
    hideToolbar: {
      type: Boolean,
      default: () => false,
    },
    fullscreen: {
      type: Boolean,
      default: () => false,
    },
    closeBtn: {
      type: Boolean,
      default: () => true,
    },

    value: Boolean,
  },

  computed: {
    model: {
      get: (vm) => vm.value,
      set(v) {
        this.$emit("input", v);
      },
    },
  },

  mounted() {
    if (this.hideToolbar) return;
    initMover();
  },
};
</script>

<template>
  <v-dialog
    :fullscreen="fullscreen"
    :persistent="persistent"
    v-model="model"
    :width="width"
    :max-width="maxWidth"
  >
    <v-card
      color="bg-secondary"
      rounded="lg"
    >
      <v-toolbar
        dense
        v-if="!hideToolbar"
        style="padding-left: 8px; padding-right: 8px; padding-top: 10px"
        :color="'transparent'"
        elevation="0"
      >
        <v-toolbar-title
          class="cursor-move"
          v-html="title"
        />
        <v-spacer />

        <div
          v-if="closeBtn"
          @click="model = false"
          v-ripple
          class="btn-close"
        >
          <v-icon color="#676B92">mdi-close</v-icon>
        </div>
      </v-toolbar>

      <v-card-text :class="contentClass">
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.btn-close {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: white;
  position: static;
  border-radius: 8px;
  cursor: pointer;
}
</style>
