<script>
import { debounce } from "debounce";
import tnVue from "@/mixins/tn.vue";

export default {
  mixins: [tnVue],
  props: {
    ln: String,
    value: String,

    searchBoxHotKey: {
      type: [String, Number],
      default: () => 115,
    },
    searchName: {
      type: String,
      default: () => "search",
    },
  },

  data: () => ({
    entries: [],
    isShowSuggests: false,
    suggestTextBeginIndex: 0,
    suggestTextEndIndex: 0,
    isSelectedSuggest: false,
    isLoading: false
  }),

  mounted() {
    document.addEventListener("keyup", this.onKeyup);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyup);
  },

  computed: {
    searchText: {
      get: (vm) => vm.value,
      set(v) {
        this.$emit("input", v);
      },
    },
  },

  watch: {
    searchText: debounce(async function (value) {
      if (this.isSelectedSuggest) {
        this.isSelectedSuggest = false;
        return;
      }

      if (!`${value || ""}`.length) return;

      this.isShowSuggests = false;
      this.entries = [];

      const cursorPosition = this.getCursorPosition();

      const lastWord = this.getLastWord(value, cursorPosition);
      const params = { limit: 20, search: lastWord };
      this.isLoading = true
      const searchItems = await this.$store.dispatch("product/SEARCH_SUGGEST", params);
      this.isLoading = false
      if (this.$array(searchItems).length) {
        this.entries = searchItems;
      }

      if (this.entries.length) {
        this.isShowSuggests = true;
        this.$refs.searchBox?.focus();
      }
    }, 300),

    isShowSuggests(v) {
      this.$emit('show-suggests', v)
    },
  },

  methods: {
    onKeyup(event) {
      if (event.keyCode == this.searchBoxHotKey) {
        this.$refs.searchBox?.focus();
        this.$nextTick(() => {
          const input = this.$refs.searchBox?.$el?.querySelector("input");
          input?.select();
        });
        return;
      }
    },

    replaceDown() {
      if (this.isLoading) return

      if (!this.isShowSuggests) {
        this.$emit("keyup-down");
      } else {
        const item = document.querySelector(".my-suggests .my-suggest-item-0");
        item.focus();
      }
    },

    replaceUp() {
      const index = this.entries.length - 1;
      const item = document.querySelector(`.my-suggests .my-suggest-item-${index}`);
      item?.focus();
    },

    onSelectSuggest(suggest) {
      this.isSelectedSuggest = true;

      this.isShowSuggests = false;
      this.entries = [];

      const txt = `${this.searchText || ""}`;
      const beginIndex = this.suggestTextBeginIndex;
      const endIndex = this.suggestTextEndIndex;
      const part1 = txt.slice(0, beginIndex);
      const part2 = txt.slice(endIndex, txt.length);
      this.searchText = `${part1} ${suggest} ${part2}`.replaceAll("  ", " ").trim();

      this.$refs.searchBox?.focus();
    },

    onClear() {
      this.entries = [];
      this.searchText = null;
      this.$refs.searchBox?.focus();
    },

    onFocus() {
      if (this.entries.length) {
        this.isShowSuggests = true;
      }
    },

    getCursorPosition() {
      const input = this.$refs.searchBox?.$el?.querySelector("input");
      return input?.selectionStart || 0;
    },

    getLastWord(value, cursorPosition) {
      const p1 = value.slice(0, cursorPosition).split(" ").pop();
      this.suggestTextBeginIndex = this.$number(cursorPosition - p1.length);

      const p2 = value.slice(cursorPosition, value.length).split(" ").shift();
      this.suggestTextEndIndex = this.$number(cursorPosition + p2.length);

      return p1 + p2;
    },

    onKeyupDown(i, e) {
      e.preventDefault();

      const index = i == this.entries.length - 1 ? 0 : i + 1;
      const item = document.querySelector(`.my-suggests .my-suggest-item-${index}`);
      item?.focus();
    },

    onKeyupUp(i, e) {
      e.preventDefault();

      const index = i > 0 ? i - 1 : this.entries.length - 1;
      const item = document.querySelector(`.my-suggests .my-suggest-item-${index}`);
      item?.focus();
    },

    getEntries() {
      if (this.isShowSuggests) this.isShowSuggests = false
      else this.$emit("keyup-down")
    },

    onEsc() {
      this.$refs.searchBox?.focus();
      this.$nextTick(() => (this.isShowSuggests = false));
    },
  },
};
</script>

<template>
  <v-menu
    offset-y
    v-model="isShowSuggests"
    :close-on-content-click="false"
  >
    <template #activator="{}">
      <v-text-field
        style="max-width: 470px;"
        :id="ln"
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        @keyup.enter="getEntries"
        @click:append="getEntries"
        @focus="onFocus"
        @keyup.down="replaceDown"
        @keyup.up="replaceUp"
        @keyup.esc="onEsc"
        class="w-100 my-2"
        :label="tn(`${searchName}`)"
        clearable
        v-model="searchText"
        autocomplete="off"
        ref="searchBox"
      />
    </template>

    <v-card class="my-suggests">
      <div
        v-for="(item, index) in entries"
        @click="onSelectSuggest(item)"
        @keyup.enter="onSelectSuggest(item)"
        @keydown.up="onKeyupUp(index, $event)"
        @keydown.down="onKeyupDown(index, $event)"
        @keyup.esc="onEsc"
        :key="item"
        tabindex="-1"
        :class="`my-suggest-item-${index}`"
        class="_suggest-item"
        v-text="item"
      />
    </v-card>
  </v-menu>
</template>

<style lang="scss" scoped>
.my-suggests {
  max-height: 255px;
  overflow-x: auto;

  ._suggest-item {
    padding: 6px 10px;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;

    &:focus {
      outline: 0;
      background-color: #e1efff;
      border-color: #ec3e34;
    }
  }
}
</style>
