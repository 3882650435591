<script>
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import PriceListItems from "@/components/price-list-items/price-list-items.vue";

const urlItemsByBasketItemGuid = `PriceListLineItem/BasketAlternative`;

export default {
  components: {
    PriceListItems,
  },

  data: () => ({
    ln: "basket.move",
    uniqKey: "basket-move",

    isFilter: false,
    filterData: {},

    sortBy: "Amount",

    isShowDelete: false,

    urlItemsByBasketItemGuid,
    isShowAnalog: null,
    isShowReplacement: null,
    isMyMarkup: false,
    markupText: null,
  }),

  mixins: [tnVue, TableResizeVue, TableNavFunctionsVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("basket", { basketLineItem: "GET_LINE_ITEM_BY_GUID" }),

    ...mapGetters("price-list-items", ["GET", "GET_COUNT", "GET_TOTALS"]),

    entries: (vm) => vm.GET(urlItemsByBasketItemGuid),
    entriesCount: (vm) => vm.GET_COUNT(urlItemsByBasketItemGuid),
    entriesTotals: (vm) => vm.GET_TOTALS(urlItemsByBasketItemGuid),

    tableHeight: (vm) => vm.size.height - 170,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.provider"),
          value: "priceListProviderName",
          width: vm.$dxcs.priceListProviderName,
          _required: true,
        },
        {
          text: vm.tn("table.product"),
          value: "productName",
          _slot: true,
          width: vm.$dxcs.productName,
          _required: true,
        },
        {
          text: vm.tn("table.manufacturer"),
          value: "manufacturerName",
          width: vm.$dxcs.manufacturer,
          _required: true,
        },
        {
          text: vm.tn("table.shelf_life"),
          value: "shelfLife",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.price_type"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
        },
        {
          text: vm.tn("table.amount"),
          value: "amount",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
          _required: true,
        },
        {
          text: vm.tn("table.discount_amount"),
          value: "amountDiscount",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.my_selling_price"),
          value: "mySellingPrice",
          sortable: false,
          visible: vm.isMyMarkup,
          _filter: (v, item) => {
            const a = item.amountDiscount || item.amount;
            const b = vm.markupText;
            const diff = Math.round(+(a * (b / 100)).toFixed(2));
            const sellingPrice = Math.round(+(a + diff).toFixed(2));

            if (diff) {
              let vl = `<span class="green--text">(+${vm.$sum(diff)})</span> `;

              const refRtSlPrice = item.productReferentPriceRetailSalePrice;
              if (sellingPrice && refRtSlPrice && sellingPrice > refRtSlPrice) {
                return `${vm.$sum(vl)} <span class="red--text">${vm.$sum(sellingPrice)}</span>`;
              }
              return `${vm.$sum(vl)} ${vm.$sum(sellingPrice)}`;
            }

            return `${vm.$sum(sellingPrice)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceDate"),
          value: "productReferentPriceDate",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.$t("table.productReferentPriceWholeSalePrice"),
          value: "productReferentPriceWholeSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceWholeSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceRetailSalePrice"),
          value: "productReferentPriceRetailSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceRetailSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.tn("table.load"),
          value: "load",
        },
        {
          text: vm.tn("table.priceListDateTime"),
          value: "priceListDateTime",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.actions.count"),
          value: "actions",
          class: "disable-resize",
          sortable: false,
          _resize: false,
          width: 85,
        },
      ]
        .map((x) => {
          if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
          return x;
        })
        .filter((e) => e.visible != false),

    actionsList: (vm) => [
      {
        name: vm.tn("actions.show_price_btn"),
        action: (item) => window.open(`/price-list/${item.priceListGuid}`, "_blank"),
        icon: "mdi-information",
      },
      {
        name: vm.tn("actions.info_btn"),
        action: (item) => {
          vm.$windowOpen("price-list-provider", item.priceListProviderGuid, item.priceListProviderName, 650, 800, "preview=1");
        },
        icon: "mdi-information",
      },
    ],

    basketLineItemGuid: (vm) => vm.$route.params.basketLineItemGuid,
    priceListLineItemGuid: (vm) => vm.$route.params.priceListLineItemGuid,

    isShowTable() {
      return this.basketLineItem?.guid;
    },
  },

  async created() {
    await this.getByGuidApi({ guid: this.basketLineItemGuid });
    if (!this.basketLineItem?.id) this.$router.push("/basket");
  },

  methods: {
    ...mapActions("basket", { getByGuidApi: "GET_LINE_ITEM_BY_GUID_API" }),
    ...mapActions("price-list-items", ["GET_API"]),

    getItems(params) {
      params.filterData.priceListLineItemGuid = this.priceListLineItemGuid;
      params.options.isShowAnalog = this.isShowAnalog;
      params.options.isShowReplacement = this.isShowReplacement;
      return this.GET_API(params);
    },

    onShowAnalog() {
      this.isShowAnalog = !this.isShowAnalog;
      this.$refs.priceListItems?.getEntries({ reset: true });
    },

    onShowReplacement() {
      this.isShowReplacement = !this.isShowReplacement;
      this.$refs.priceListItems?.getEntries({ reset: true });
    },

    getRowStyle(item) {
      if (item.productState == 2) return { backgroundColor: "#cdfad4" };
      else if (item.productState == 3) return { backgroundColor: "#f5facd" };
      else return { backgroundColor: "#fff" };
    },
  },
};
</script>

<template>
  <v-container fluid>
    <price-list-items
      ref="priceListItems"
      :GET_API="getItems"
      :entries="entries"
      @markup="(v) => (markupText = v)"
      @isMyMarkup="(v) => (isMyMarkup = v)"
      :entriesCount="entriesCount"
      :tableHeaders="tableHeaders"
      :ln="ln"
      :sortBy="sortBy"
      :tableHeight="tableHeight"
      :uniqKey="uniqKey"
      :url="urlItemsByBasketItemGuid"
      :actionsList="actionsList"
      :activeItem="basketLineItem"
      isShowTotals
      :getRowStyle="getRowStyle"
      :v-resize-table="{ tableHeaders: tableHeaders, callback: onUpdateTdSizes }"
    >
      <template #actions-prepend="slotProps">
        <v-btn
          :color="isShowAnalog ? '#cdfad4' : 'primary'"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          :outlined="!isShowAnalog"
          elevation="0"
          @click="onShowAnalog"
          :disabled="slotProps.loading"
          :loading="slotProps.loading"
          :title="tn('show_analog_btn')"
          class="mr-2"
        >
          <v-icon :color="isShowAnalog ? 'primary' : ''"> А </v-icon>
        </v-btn>

        <v-btn
          :color="isShowReplacement ? '#f5facd' : 'primary'"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          :outlined="!isShowReplacement"
          elevation="0"
          @click="onShowReplacement"
          :disabled="slotProps.loading"
          :loading="slotProps.loading"
          :title="tn('show_replacement_btn')"
          class="mr-2"
        >
          <v-icon :color="isShowReplacement ? 'primary' : ''"> З </v-icon>
        </v-btn>
      </template>

      <template #productName="slotProps">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="text-left px-1"
              v-bind="attrs"
              v-on="on"
              v-text="slotProps.item.productName"
            />
          </template>
          <span v-if="slotProps.item.productState == 1">{{ tn("original") }}</span>
          <span v-else-if="slotProps.item.productState == 2">{{ tn("analog") }}</span>
          <span v-else-if="slotProps.item.productState == 3">{{ tn("replacement") }}</span>
        </v-tooltip>
      </template>

      <template #title>
        <h3>
          {{ basketLineItem.productName }}
          ({{ basketLineItem.manufacturerName }})
        </h3>
        <small>
          <b> {{ tn("header.shelf_life") }}: </b>
          {{ basketLineItem.shelfLife | date }};
          <b> {{ tn("header.price_type") }}: </b>
          {{ basketLineItem.priceTypeName }};
          <b> {{ tn("header.amount") }}: </b>
          {{ basketLineItem.amount | sum }};
          <b> {{ tn("header.quantity") }}: </b>
          {{ basketLineItem.basketQuantity | sum }};
          <b> {{ tn("header.total_amount") }}: </b>
          {{ basketLineItem.totalAmount | sum }}
        </small>
      </template>

      <template #body-append="bodyProps">
        <td
          v-for="(header, i) in bodyProps.headers"
          :key="i"
        >
          <div
            v-if="header.value == 'number'"
            class="text-nowrap text-center"
          >
            {{ entriesCount | sum }}
          </div>

          <div
            v-else-if="header.value == 'amount'"
            class="text-nowrap text-right"
          >
            {{ entriesTotals.totalAmount | sum }}
          </div>

          <div
            v-else-if="header.value == 'actions'"
            class="text-nowrap text-right"
          >
            {{ entriesTotals.totalQuantity | sum }}
          </div>

          <div v-else />
        </td>
      </template>
    </price-list-items>
  </v-container>
</template>
