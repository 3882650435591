<script>
export default {
  props: {
    deadline: {
      type: Number,
      default: () => 0,
    },

    showTotal: {
      type: Boolean,
      default: () => false,
    },

    showDays: {
      type: Boolean,
      default: () => true,
    },

    showHours: {
      type: Boolean,
      default: () => true,
    },
  },

  data: (vm) => ({
    currentTime: null,
    curSeconds: vm.deadline,
  }),

  mounted() {
    this.countdown();
  },

  methods: {
    countdown() {
      if (this.deadline > 0) {
        const total = (this.curSeconds -= 1000);

        if (total > 0) {
          const seconds = Math.floor((total / 1000) % 60);
          const minutes = Math.floor((total / 1000 / 60) % 60);
          const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
          const days = Math.floor(total / (1000 * 60 * 60 * 24));

          this.currentTime = {
            total,
            days,
            hours,
            minutes,
            seconds,
          };
          setTimeout(this.countdown, 1000);
        } else {
          this.currentTime = null;
          this.$emit("timeEnd");
        }
      }
    },

    timerView() {
      const { days, hours, minutes, seconds } = this.currentTime;
      const viDays = this.showDays && this.showHours ? `${sl(days)}:` : "";
      const viHours = this.showHours ? `${sl(hours)}:` : "";

      return `${viDays}${viHours}${sl(minutes)}:${sl(seconds)}`;

      function sl(value) {
        return value ? `0${value}`.slice(-2) : "00";
      }
    },

    getTotal() {
      return this.currentTime?.total ? this.currentTime.total / 1000 : 0;
    },
  },
};
</script>

<template>
  <div v-if="showTotal">
    {{ getTotal() }}
  </div>
  <div v-else>
    <div v-if="currentTime">
      {{ timerView() }}
    </div>
    <div v-else>{{ showDays ? "00:" : "" }}00:00:00</div>
  </div>
</template>
