import { render, staticRenderFns } from "./pharmacy-requirements-import.vue?vue&type=template&id=5709c776&scoped=true"
import script from "./pharmacy-requirements-import.vue?vue&type=script&lang=js"
export * from "./pharmacy-requirements-import.vue?vue&type=script&lang=js"
import style0 from "./pharmacy-requirements-import.vue?vue&type=style&index=0&id=5709c776&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5709c776",
  null
  
)

export default component.exports