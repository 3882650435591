import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;
let cancelSource2 = null;

const url = 'PriceListViewerFavoriteProvider'

export const state = () => ({
  withRegionsData: {},
  othersWithRegionsData: {}
});

export const mutations = {
  SET_WITH_REGIONS(state, payload) {
    state.withRegionsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    }
  },

  SET_OTHERS_WITH_REGIONS(state, payload) {
    state.othersWithRegionsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    }
  },
};

export const actions = {
  async GET_WITH_REGIONS_API({ commit }, { options, filterData }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();
    commit("SET_WITH_REGIONS");
    options.isFavorite = true
    const res = await getWithRegionsAxios({ options, filterData }, cancelSource)
    if (res?.succeeded) commit("SET_WITH_REGIONS", res?.data);
  },

  async GET_OTHERS_WITH_REGIONS_API({ commit }, { options, filterData }) {
    cancelSource2?.cancel();
    cancelSource2 = CancelToken.source();
    commit("SET_OTHERS_WITH_REGIONS");

    const res = await getWithRegionsAxios({ options, filterData }, cancelSource2)
    if (res?.succeeded) commit("SET_OTHERS_WITH_REGIONS", res?.data);
  },

  async ADD_API(ctx, params) {
    const res = await vm.$axios.$post(url, params);
    return res?.succeeded
  },

  async DELETE_API(ctx, guid) {
    const res = await vm.$axios.$delete(url + '/' + guid);
    return res?.succeeded
  }
};

export const getters = {
  GET_WITH_REGIONS: (state) => state.withRegionsData.items,
  GET_WITH_REGIONS_COUNT: (state) => state.withRegionsData.total,
  GET_OTHERS_WITH_REGIONS: (state) => state.othersWithRegionsData.items,
  GET_OTHERS_WITH_REGIONS_COUNT: (state) => state.othersWithRegionsData.total,
};

function getWithRegionsAxios({ options, filterData }, ct) {
  const params1 = {
    limit: options.itemsPerPage,
    offset: options.itemsPerPage * (options.page - 1),
    sortBy: options.sortBy,
    sortDirection: options.sortBy ? options.sortDesc : null,
    search: options.search,
    isFavorite: options.isFavorite,
    ...filterData
  };

  let params = {}

  for (const iterator in params1) {
    if (params1[iterator] != null || params1[iterator] != undefined) {
      params[iterator] = params1[iterator]
    }
  }

  const request = { progress: false, cancelToken: ct.token };
  return vm.$axios.$post(url + '/WithRegions/Get', params, request);
}
