<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";
import DateBox from "@/components/fields/date-box.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalsTemplate,
    DateBox,
  },

  props: {
    value: Object,
    ln: String,
  },

  data: () => ({
    dialog: true,
    isLoading: false,
    formData: {},
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  computed: {
    ...mapGetters("references", {
      priceTypes: "GET_PRICE_TYPES",
    }),
  },

  created() {
    if (Object.values(this.value).length) this.formData = this.value;
  },

  methods: {
    async onApply() {
      if (this.$refs.form.validate()) {
        const formData = { ...this.formData };

        if (!`${formData.shelfLifeFrom || ""}`.trim()) delete formData.shelfLifeFrom;

        this.$emit("apply", formData);
        this.dialog = false;
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },

    onReset() {
      this.formData = {};
      this.$nextTick(() => this.onApply());
    },
  },
};
</script>

<template>
  <modals-template
    :width="500"
    v-model="dialog"
    :title="tn('title')"
    :persistent="false"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <div>{{ tn("form.shelfLifeFrom") }}</div>
      <date-box
        autofocus
        v-model="formData.shelfLifeFrom"
        clearable
        dense
      />

      <div>{{ tn("form.priceType") }}</div>
      <v-select
        multiple
        v-model="formData.priceTypeGuidList"
        :items="priceTypes"
        item-value="guid"
        item-text="name"
        clearable
        dense
        outlined
      />

      <v-checkbox
        v-model="formData.showCheapest"
        :label="tn('form.showCheapest')"
      />

      <div class="d-flex align-center justify-center">
        <v-btn
          height="45"
          :loading="isLoading"
          color="error"
          @click="onReset"
          elevation="0"
          class="mt-5 mb-1 w-49"
        >
          {{ tn("reset_btn") }}
        </v-btn>
        <div class="px-2" />
        <v-btn
          height="45"
          :loading="isLoading"
          color="primary"
          @click="onApply"
          elevation="0"
          class="mt-5 mb-1 w-49"
        >
          {{ tn("apply_btn") }}
        </v-btn>
      </div>
    </v-form>
  </modals-template>
</template>
