import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = 'PriceListProvider'

export const state = () => ({
  nameItemsData: {}
});

export const mutations = {
  SET_ONLY_NAME(state, payload) {
    state.nameItemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    }
  },
};

export const actions = {
  async GET_ONLY_NAME_API({ commit }, { options, filterData = {} }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();
    commit("SET_ONLY_NAME");

    const params = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      sortBy: options.sortBy,
      sortDirection: options.sortBy ? options.sortDesc : null,
      search: options.search,
      ...filterData
    };

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$get(url + '/OnlyName', params, request);
    if (res?.succeeded) commit("SET_ONLY_NAME", res?.data);
  }
};

export const getters = {
  GET_ONLY_NAME: (state) => state.nameItemsData.items,
  GET_ONLY_NAME_COUNT: (state) => state.nameItemsData.total,
};
