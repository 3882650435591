<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";

import DateBox from "@/components/fields/date-box.vue";

export default {
  components: {
    ModalsTemplate,
    DateBox,
  },

  props: {
    value: Object,
  },

  data: (vm) => ({
    dialog: true,
    ln: "price_list_viewer_order.table.filter",
    isLoading: false,
    filterData: { ...vm.value },
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    async onApply() {
      if (this.$refs.form.validate()) {
        this.$emit("apply", this.filterData);
        this.dialog = false;
      }
    },

    onReset() {
      this.filterData = {};
      this.$nextTick(() => this.onApply());
    },
  },
};
</script>

<template>
  <modals-template
    :width="600"
    v-model="dialog"
    :title="tn('title')"
    :persistent="false"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-row>
        <v-col
          cols="6"
          class="py-0"
        >
          <div>{{ tn("form.dateFrom") }}</div>
          <date-box
            autofocus
            v-model="filterData.dateFrom"
            clearable
            dense
          />
        </v-col>

        <v-col
          cols="6"
          class="py-0"
        >
          <div>{{ tn("form.dateTo") }}</div>
          <date-box
            v-model="filterData.dateTill"
            clearable
            dense
          />
        </v-col>

        <!-- <v-col
          cols="12"
          class="py-0"
        >
          <div>{{ tn("form.productName") }}</div>
          <v-text-field
            outlined
            dense
            v-model="filterData.productName"
          />
        </v-col> -->
      </v-row>

      <div class="d-flex align-center justify-center">
        <v-btn
          height="45"
          :loading="isLoading"
          color="error"
          @click="onReset"
          elevation="0"
          class="mt-5 mb-1 w-49"
        >
          {{ tn("reset_btn") }}
        </v-btn>

        <div class="px-2" />

        <v-btn
          height="45"
          :loading="isLoading"
          color="primary"
          @click="onApply"
          elevation="0"
          class="mt-5 mb-1 w-49"
        >
          {{ tn("apply_btn") }}
        </v-btn>
      </div>
    </v-form>
  </modals-template>
</template>
