import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = 'PriceListViewerIndividualDiscount'

export const state = () => ({
  itemsData: {}
});

export const mutations = {
  SET(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    }
  },
};

export const actions = {
  async GET_API({ commit }, { options, filterData }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();
    commit("SET");

    const params1 = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      sortBy: options.sortBy,
      sortDirection: options.sortBy ? options.sortDesc : null,
      search: options.search,
      ...filterData
    };

    let params = {}

    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator]
      }
    }

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$post(url + '/PriceListProvider', params, request);
    if (res?.succeeded) commit("SET", res?.data);
  },

  async ADD_API(ctx, params) {
    const res = await vm.$axios.$post(url, params);
    return res?.succeeded
  },

  async DELETE_API(ctx, guid) {
    const res = await vm.$axios.$delete(url + '/' + guid);
    return res?.succeeded
  }
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
};
