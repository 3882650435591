import Vue from "vue";

let timeData = {
  local: null,
};

export const getRealTime = (dd, mm) => {
  const diff = new Date().getTime() - timeData.local;
  const _date = new Date(diff);
  if (dd) _date.setDate(_date.getDate() + dd);
  if (mm) _date.setMonth(_date.getMonth() + mm);
  return _date;
};

Vue.prototype.$getRealTime = getRealTime;
