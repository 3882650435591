import router from "@/router";
import Vue from "vue";

const browserStore = {
  delLocal(a) {
    localStorage.removeItem(a);
  },

  setLocal(a, v) {
    localStorage.setItem(a, JSON.stringify(v));
  },

  getLocal(v) {
    const u = localStorage.getItem(v);
    if (u && u != "undefined") return JSON.parse(u);
  },

  localClear() {
    localStorage.clear();
  },

  delSession(a) {
    sessionStorage.removeItem(a);
  },

  setSession(a, v) {
    sessionStorage.setItem(a, JSON.stringify(v));
  },

  getSession(a) {
    const u = sessionStorage.getItem(a);
    if (u) return JSON.parse(u);
  },

  sessionClear() {
    sessionStorage.clear();
  },
};

const setSortLocal = ({ key, options }) => {
  const _key = `sort-${getPath()}-${key || ""}`;
  const data = { sortBy: options.sortBy, sortDesc: options.sortDesc };
  browserStore.setSession(_key, data);
};

const getSortLocal = (params) => {
  const { key, options = {} } = params || {};

  const _key = `sort-${getPath()}-${key || ""}`;
  const data = browserStore.getSession(_key) || {};
  if (data?.sortBy) options.sortBy = data.sortBy;
  if (data.sortDesc) options.sortDesc = data.sortDesc;
  return options;
};

export default browserStore;
Vue.prototype.$setSortLocal = setSortLocal;
Vue.prototype.$getSortLocal = getSortLocal;
Vue.prototype.$browserStore = browserStore;

function getPath() {
  const params = Object.values(router.currentRoute.params);
  return router.currentRoute.path
    .split("/")
    .filter((x) => x && !params.find((y) => y == x))
    .join("__");
}
