export default {
  text: {
    regex: "[a-zA-Z'` ]*$",
    showMaskOnHover: false,
  },

  date: {
    alias: "datetime",
    inputFormat: "dd.mm.yyyy",
    outputFormat: "yyyy-mm-dd",
    placeholder: "дд-мм-гггг",
    showMaskOnHover: false,
    autoUnmask: true,
  },

  datetime: {
    alias: "datetime",
    inputFormat: "dd.mm.yyyy HH:MM",
    outputFormat: "yyyy-mm-dd HH:MM",
    placeholder: "дд-мм-гггг чч:мм",
    showMaskOnHover: false,
    autoUnmask: true,
  },

  number: {
    alias: "numeric",
    groupSeparator: " ",
    autoUnmask: true,
    rightAlign: false,
    showMaskOnHover: false,
    min: 0,
    showMaskOnFocus: false,
  },

  numberMax: (params) => {
    const { min, max, left, digits, separator } = params || {};
    return {
      alias: "numeric",
      groupSeparator: separator != undefined ? separator : " ",
      autoUnmask: true,
      rightAlign: !left,
      showMaskOnHover: false,
      min: min || 0,
      max: max || undefined,
      digits: digits >= 0 ? digits : 2,
      showMaskOnFocus: false,
    };
  },

  numberMax5: {
    alias: "numeric",
    groupSeparator: " ",
    autoUnmask: true,
    rightAlign: true,
    showMaskOnHover: false,
    min: 0,
    max: 99999.99,
    digits: 2,
    showMaskOnFocus: false,
  },

  number_only: {
    alias: "numeric",
    autoUnmask: true,
    rightAlign: false,
    showMaskOnHover: false,
    min: 0,
    showMaskOnFocus: false,
  },

  number4Length: {
    mask: "9999",
    showMaskOnHover: false,
    autoUnmask: true,
  },

  percent: {
    alias: "percentage",
    max: 100,
    digits: 2,
    autoUnmask: true,
    rightAlign: false,
    showMaskOnHover: false,
    showMaskOnFocus: false,
  },

  email: {
    mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
    greedy: false,
    onBeforePaste: (pastedValue) => {
      pastedValue = pastedValue.toLowerCase();
      return pastedValue.replace("mailto:", "");
    },
    definitions: {
      "*": {
        validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~-]",
        cardinality: 1,
        casing: "lower",
      },
    },
  },

  phone: {
    mask: "+\\9\\98 99 999 99 99",
    autoUnmask: true,
    showMaskOnHover: true,
  },
};
