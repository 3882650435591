import Vue from "vue";
const vm = Vue.prototype;

export const state = () => ({
  regions: [],
  basketStatus: [],
  priceTypes: [],
});

export const mutations = {
  SET_REGIONS(state, payload) {
    state.regions = vm.$array(payload);
  },
  SET_BASKET_STATUS(state, payload) {
    state.basketStatus = vm.$array(payload);
  },
  SET_PRICE_TYPES(state, payload) {
    state.priceTypes = vm.$array(payload);
  },
};

export const actions = {
  async INIT_REFERENCES({ commit }) {
    let res = null;
    if ((res = await vm.$axios.$get(`region/all`)) && res?.succeeded) {
      commit("SET_REGIONS", res.data.items);
    }
    if ((res = await vm.$axios.$get(`basket/statusLookUp`)) && res?.succeeded) {
      commit("SET_BASKET_STATUS", res.data);
    }
    if ((res = await vm.$axios.$get(`PriceType/All`)) && res?.succeeded) {
      commit("SET_PRICE_TYPES", res.data.items);
    }
  },
};

export const getters = {
  GET_REGIONS: (state) => state.regions,
  GET_BASKET_STATUS: (state) => state.basketStatus,
  GET_PRICE_TYPES: (state) => state.priceTypes,
};
