<script>
import debounce from "debounce";
import tnVue from "@/mixins/tn.vue";

import SelectionList from "./components/selection-list.vue";
import SelectedList from "./components/selected-list.vue";
import ShowPharmacyRequirements from "@/components/show-pharmacy-requirements.vue";

export default {
  components: {
    SelectionList,
    SelectedList,
    ShowPharmacyRequirements,
  },

  data: () => ({
    ln: "selection_auto",
    isDrawer: true,

    selectionList: {},
    selectedList: {},
    isShowItems: false,
    isHide: null,
    isShowOrganizationStatistic: null,
    isSelectedTable: true,
    leftSideWidth: 454
  }),

  mixins: [tnVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,
    tableHeight: (vm) => vm.size.height - 165,

    getTableHeight() {
      let tbHeight = null;
      switch (this.isShowOrganizationStatistic) {
        case true:
          tbHeight = this.isHide ? this.tableHeight / 2.1 : this.tableHeight / 1.06;
          break;
        case false:
          tbHeight = this.tableHeight;
          break;
        case null:
          tbHeight = this.isHide ? this.tableHeight / 2.1 : this.tableHeight / 1.06;
          break;
      }
      return tbHeight;
    },

    getSelectedGuid() {
      let guid = null;
      switch (this.isSelectedTable) {
        case true:
          guid = this.selectionList?.guid;
          break;
        case false:
          guid = this.selectedList?.productGuid;
          break;
      }
      return guid;
    },
  },

  watch: {
    selectionList: debounce(function (newVal, oldVal) {
      if (newVal?.guid == oldVal?.guid) return;
      if (!this.isSelectedTable) return;

      this.isShowItems = false;
      this.$nextTick(() => {
        if (newVal?.guid) this.isShowItems = true;
        this.$refs.table3?.getEntries({ reset: true });
      });
    }, 200),

    selectedList: debounce(function (newVal, oldVal) {
      if (newVal?.guid == oldVal?.guid) return;
      if (this.isSelectedTable) return;

      this.isShowItems = false;
      this.$nextTick(() => {
        if (newVal?.productGuid) this.isShowItems = true;
        this.$refs.table3?.getEntries({ reset: true });
      });
    }, 200),
  },

  created() {
    const savedWidth = localStorage.getItem(`${this.ln}_left_side_width`);
    if (savedWidth) this.leftSideWidth = parseInt(savedWidth, 10);
  },

  mounted() {
    document.addEventListener("keydown", this.onDocumentKeyup);
    this.onResizableLeftSide();
  },

  destroyed() {
    document.removeEventListener("keydown", this.onDocumentKeyup);
  },

  methods: {
    onDocumentKeyup(event) {
      if (event.code != "Tab") return;
      event.preventDefault();

      const table1 = this.$refs.table1;
      const table2 = this.$refs.table2;
      if (!this.isDrawer || table1?.checkFocused()) {
        table2.isSavePosition = true;
        table2.setFocusToItem();
      } else {
        table1.isSavePosition = true;
        table1.setFocusToItem();
      }
    },

    onRefresh() {
      this.$refs.table2?.getEntries({ reset: true, isntFocus: true });
    },

    onShowOrganizationStatistic(value) {
      if (value == true) return (this.isShowOrganizationStatistic = true);
      else if (value == false) return (this.isShowOrganizationStatistic = false);
      else if (value == undefined) return (this.isShowOrganizationStatistic = null);
    },

    onResizableLeftSide() {
      const el = this.$refs.resizable;
      const resizer = el.querySelector('.resizer');
      resizer.addEventListener('mousedown', (e) => {
        e.preventDefault();
        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResize);
      });

      const resize = (e) => {
        const width = e.pageX - el.getBoundingClientRect().left;
        if (width > 100 && width < window.innerWidth * 0.5) {
          this.leftSideWidth = width;
        }
      };

      const stopResize = () => {
        window.removeEventListener('mousemove', resize);
        localStorage.setItem(`${this.ln}_left_side_width`, this.leftSideWidth);
      };
    }
  },
};
</script>

<template>
  <div>
    <v-container fluid>
      <div class="page-wrap">
        <div
          ref="resizable"
          :class="!isDrawer && 'left-side-closed'"
          class="left-side"
          :style="{ width: leftSideWidth + 'px' }"
        >
          <selection-list
            :isFocusable="isDrawer"
            class="left-side-list"
            ref="table1"
            :ln="ln + '.selection_list'"
            :tableHeight="tableHeight - 42"
            @select="(v) => (selectionList = v)"
            @showOrganizationStatistic="onShowOrganizationStatistic"
            @refresh="onRefresh"
          />
          <div class="resizer" />
        </div>

        <div
          class="content-side"
          :class="!isDrawer && 'left-side-closed'"
        >
          <selected-list
            ref="table2"
            :tableHeight="getTableHeight"
            :ln="ln + '.selected_list'"
            @select="(v) => (selectedList = v)"
          >
            <template #actions>
              <v-btn
                color="primary"
                min-width="40"
                width="40"
                height="40"
                min-height="40"
                outlined
                @click="isDrawer = !isDrawer"
                :title="isDrawer ? tn('close') : tn('open')"
              >
                <v-icon v-if="isDrawer"> mdi-arrow-left </v-icon>
                <v-icon v-else> mdi-plus-box-outline </v-icon>
              </v-btn>
            </template>
          </selected-list>

          <ShowPharmacyRequirements
            v-if="isShowItems && isShowOrganizationStatistic != false"
            ref="table3"
            :tableHeight="tableHeight / 2.44"
            :productGuid="getSelectedGuid"
            :uniqKey="ln"
            :isShowSearchBtn="false"
            @isHide="(e) => (isHide = e)"
          >
            <template #actions>
              <div style="max-width: 200px">
                <v-select
                  v-model="isSelectedTable"
                  dense
                  :items="[
                    { text: tn('left_side'), value: true },
                    { text: tn('upper_side'), value: false },
                  ]"
                  item-text="text"
                  item-value="value"
                  outlined
                  hide-details
                />
              </div>
            </template>
          </ShowPharmacyRequirements>
        </div>
      </div>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  height: 100%;

  .left-side {
    max-width: 50%;
    overflow: hidden;
    transition: all 0.2s;
    position: relative;

    &.left-side-closed {
      max-width: 0;

      .left-side-list {
        max-height: 50vh;
      }
    }
  }

  .resizer {
    width: 10px;
    height: 100%;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    cursor: ew-resize;
  }

  .content-side {
    flex: 3;
    overflow-y: auto;
    position: relative;

    padding-left: 10px;
    margin-left: -1px;

    &.left-side-closed {
      margin-left: -12px;
      padding-left: 12px;
    }
  }
}
</style>
