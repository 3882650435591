<script>
import debounce from "debounce";
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import AutocompleteBox from "./components/autocomplete-box.vue";

export default {
  mixins: [tnVue],

  components: {
    AutocompleteBox,
    ConfirmDialog,
  },

  data: (vm) => ({
    sortBy: null,
    options: {},
    itemsPerPage: 50,
    rowsPerPage: [50, 100],

    selected: {},

    loaders: {},

    ln: "selection_auto_join",

    searchText: null,

    isShowDelete: null,
    isShowClose: null,
    isUnidentifiedProducts: null,

    sessionGuid: vm.$route.params.guid,
    editingItem: null,
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("selection-auto-join", {
      entries: "GET",
      entriesCount: "GET_COUNT",
    }),

    size: (vm) => vm.$store.getters.GET_SIZE,

    tableHeaders: (vm) => [
      {
        text: "№",
        value: "number",
        width: 40,
        align: "center",
        sortable: false,
      },
      {
        text: "",
        value: "status",
        width: 40,
        align: "center",
      },
      {
        text: vm.tn("table.name"),
        value: "linkedProductName",
        width: "50%",
      },
      {
        text: vm.tn("table.name_excel"),
        value: "productName",
        width: "50%",
      },
      {
        text: "",
        value: "actions",
        width: 35,
        sortable: false,
      },
    ],

    tableHeight: (vm) => vm.size.height - 160,

    isLoading: (vm) => !!Object.keys(vm.loaders).length,
  },

  methods: {
    ...mapActions("selection-auto-join", {
      getEntriesApi: "GET_API",
      deleteApi: "DELETE_API",
      deleteSessionApi: "DELETE_SESSION_API",
      applyToList: "APPLY_TO_LIST",
      onDeleteUnlinked: "DELETE_UNLINKED",
    }),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          sessionGuid: this.sessionGuid,
          search: this.searchText,
        },
      };

      await this.getEntriesApi(params);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onCopy(trId, event, item) {
      if (window.getSelection) {
        const text = window.getSelection().toString();
        if (text) {
          try {
            navigator.clipboard.writeText(text);
          } catch {
            /**/
          }
          this.editingItem = item.guid;
          this.$nextTick(() => {
            setTimeout(() => {
              const ref = this.$refs[trId];
              if (ref) {
                const el = ref.$el;
                const input = el.querySelector("input");

                if (input?.focus) {
                  if (ref.oldValue && event.altKey) {
                    ref.value = `${ref.oldValue} ${text}`;
                  } else ref.value = text;
                  ref.oldValue = ref.value;

                  input.focus();
                  this.$nextTick(() => ref.onSearch());
                }
              }
            }, 5);
          });
        }
      }
    },

    onShowEdit(trId, item) {
      if (this.editingItem == item.guid) return;

      this.editingItem = item.guid;
      this.$nextTick(() => {
        setTimeout(() => {
          const ref = this.$refs[trId];
          if (ref) {
            const el = ref.$el;
            const input = el.querySelector("input");
            if (input?.focus) {
              ref.value = item.linkedProductName;
              if (ref.value) ref.value += ` (${item.linkedManufacturerName})`;
              input.focus();
            }
          }
        }, 5);
      });
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onClear() {
      this.searchText = null;
      this.$nextTick(() => this.getEntries());
    },

    async onSetUnlink(trId, item) {
      if (this.editingItem == item.guid) return;
      this.editingItem = item.guid;

      this.$nextTick(() => {
        setTimeout(async () => {
          const ref = this.$refs[trId];
          if (ref) {
            await ref.onSetUnlink();
            const el = ref.$el;
            const input = el.querySelector("input");
            if (input?.focus) input.focus();
          }
        }, 5);
      });
    },

    onDeleteShow({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowDelete = true));
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);
      await this.deleteApi(this.selected?.guid);
      setLoading(false);
      close();
      this.getEntries();
    },

    async onExit({ close, setLoading }) {
      setLoading(true);
      const status = await this.deleteSessionApi(this.sessionGuid);
      setLoading(false);
      if (status) {
        close();
        this.$router.replace("/selection/auto");
      }
    },

    async onApply() {
      const status = await this.applyToList({ sessionGuid: this.sessionGuid });
      if (status == 1) {
        this.$router.replace("/selection/auto");
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("saved_success"),
          color: "success",
        });
      } else if (status == 2) {
        this.isUnidentifiedProducts = true;
      }
    },

    async onDeleteUnidentifiedProducts({ close, setLoading }) {
      setLoading(true);
      const params = { sessionGuid: this.sessionGuid };
      const status = await this.onDeleteUnlinked(params);
      close();
      setLoading(true);

      await this.getEntries();
      if (status) this.onApply();
    },
  },
};
</script>

<template>
  <div class="px-5 pt-5">
    <ConfirmDialog
      :text="tn('delete_alert')"
      @close="isShowDelete = false"
      v-if="isShowDelete"
      @accept="onDelete"
    />

    <ConfirmDialog
      :text="tn('delete_session_alert')"
      @close="isShowClose = false"
      v-if="isShowClose"
      @accept="onExit"
    />

    <ConfirmDialog
      :text="tn('unidentified_products_alert')"
      @close="isUnidentifiedProducts = false"
      v-if="isUnidentifiedProducts"
      @accept="onDeleteUnidentifiedProducts"
      hideInfo
    />

    <div class="d-flex align-center">
      <h4>
        {{ tn("title") }}
      </h4>

      <v-spacer />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <div class="px-1" />

      <v-text-field
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        style="max-width: 300px; width: 100%"
        @click:append="onSearch"
        @keyup.enter="onSearch"
        @click:clear="onClear"
        :label="tn('search')"
        clearable
        v-model="searchText"
        ref="searchBox"
      />

      <div class="px-1" />

      <v-btn
        @click="onApply"
        outlined
        color="primary"
        height="40"
        min-height="40"
        class="transform-none"
      >
        {{ tn("add_btn") }}
      </v-btn>

      <div class="px-1" />

      <v-btn
        @click="isShowClose = true"
        outlined
        color="red"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </div>

    <div class="mt-3" />

    <v-data-table
      :headers="tableHeaders"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="entriesCount"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: $t('table.per_page_text'),
      }"
      :height="tableHeight"
      item-key="guid"
      class="fixed-right"
      fixed-header
    >
      <template #item.number="{ index }">
        <td class="text-center">
          <span
            class="text-nowrap"
            v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
          />
        </td>
      </template>

      <template #item.status="{ item }">
        <v-icon
          color="success"
          v-if="item.status == 1"
        >
          mdi-check
        </v-icon>
        <v-icon
          color="error"
          v-else
        >
          mdi-close
        </v-icon>
      </template>

      <template #item.linkedProductName="{ item, index }">
        <td class="pa-0">
          <AutocompleteBox
            v-if="editingItem == item.guid"
            :guid="sessionGuid"
            :isLast="entries.length - 7 < index"
            :ref="`ac-${index}`"
            :object="item"
            :index="index"
            @selected="editingItem = null"
          />
          <div
            v-else
            @click.stop="onShowEdit(`ac-${index}`, item)"
            style="height: 100%; width: 100%; position: relative"
            class="px-2 py-1"
          >
            {{ item.linkedProductName }}
            <template v-if="item.linkedManufacturerName"> ({{ item.linkedManufacturerName }}) </template>
            <v-btn
              x-small
              color="error"
              icon
              v-if="item.status == 1"
              @click.stop="onSetUnlink(`ac-${index}`, item)"
              absolute
              style="right: 3px; top: 3px"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </td>
      </template>

      <template #item.productName="{ item, index }">
        <td @mouseup="onCopy(`ac-${index}`, $event, item)">
          <div>{{ item.productName }} ({{ item.manufacturerName }})</div>
        </td>
      </template>

      <template #item.actions="{ item }">
        <td class="px-0">
          <div class="tb-action-wrap justify-center transform-7">
            <v-btn
              @click="onDeleteShow({ item })"
              icon
              small
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped>
.transform-7 {
  transform: translateY(-7px);
}
</style>
