<script>
import { mapActions } from "vuex";

import tnVue from "@/mixins/tn.vue";

import AuthTemplate from "./components/auth-template.vue";
import ConfirmRegisterSmsCode from "./components/confirm-register-sms-code.vue";
import Recaptcha from "./components/recaptcha.vue";

export default {
  components: {
    AuthTemplate,
    ConfirmRegisterSmsCode,
    Recaptcha,
  },

  data: () => ({
    isLoading: false,

    ln: "register",

    isRecaptcha: false,
    isSendedSms: false,

    formData: {
      storeName: null,
      inn: null,
      firstName: null,
      lastName: null,
      address: null,
      phone: null,
    },

    isRememberMe: false,
  }),

  mixins: [tnVue],

  created() {
    const phone = this.$route.query.phone;
    if (phone?.length == 9 && this.$number(phone)) {
      this.formData.phone = phone;

      const remember = this.$route.query.remember;
      if (remember?.length) this.isRememberMe = true;
    } else {
      this.$router.replace("/");
    }
  },

  methods: {
    ...mapActions("auth", ["REGISTER"]),

    async onRegister() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const params = { ...this.formData };
        params.phone = "998" + params.phone;
        const status = await this.REGISTER(params);
        if (status) this.isRecaptcha = true;
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <auth-template>
      <v-card outlined>
        <Recaptcha
          @next="
            () => {
              isRecaptcha = false;
              isSendedSms = true;
            }
          "
          :phone="'998' + formData.phone"
          v-if="isRecaptcha"
        />

        <ConfirmRegisterSmsCode
          @change="isSendedSms = false"
          :phone="'998' + formData.phone"
          :isRememberMe="isRememberMe"
          v-else-if="isSendedSms"
        />

        <v-card-text v-else>
          <v-form
            :disabled="isLoading"
            ref="form"
            lazy-validation
            onSubmit="return false;"
          >
            <h2 class="black--text">{{ tn("register") }}</h2>
            <div class="pt-3" />

            <div class="black--text">{{ tn("form.store_name") }}</div>
            <v-text-field
              outlined
              dense
              :rules="[$rules.required]"
              required
              autofocus
              hide-details
              v-model="formData.storeName"
              @keyup.enter="onRegister"
            />

            <div class="pt-3" />
            <div class="black--text">{{ tn("form.inn") }}</div>
            <v-text-field
              outlined
              dense
              :rules="[$rules.required]"
              required
              hide-details
              v-model="formData.inn"
              v-inputmask="$masks.number_only"
              @keyup.enter="onRegister"
              maxlength="10"
            />

            <div class="pt-3" />
            <div class="black--text">{{ tn("form.address") }}</div>
            <v-text-field
              outlined
              dense
              :rules="[$rules.required]"
              required
              hide-details
              v-model="formData.address"
              @keyup.enter="onRegister"
            />

            <div class="pt-3" />
            <div class="black--text">{{ tn("form.first_name") }}</div>
            <v-text-field
              outlined
              dense
              :rules="[$rules.required]"
              required
              hide-details
              v-model="formData.firstName"
              @keyup.enter="onRegister"
            />

            <div class="pt-3" />
            <div class="black--text">{{ tn("form.last_name") }}</div>
            <v-text-field
              outlined
              dense
              :rules="[$rules.required]"
              required
              hide-details
              v-model="formData.lastName"
              @keyup.enter="onRegister"
            />

            <div class="pt-3" />
            <div class="black--text">{{ tn("form.phone") }}</div>
            <v-text-field
              outlined
              dense
              v-inputmask="$masks.phone"
              :rules="[$rules.phone]"
              hide-details
              v-model="formData.phone"
              disabled
              @keyup.enter="onRegister"
            />
          </v-form>

          <div class="pt-5" />
          <v-btn
            :loading="isLoading"
            @click="onRegister"
            elevation="0"
            width="100%"
            color="primary"
          >
            {{ tn("register_btn") }}
          </v-btn>
        </v-card-text>
      </v-card>

      <div class="pt-3">
        {{ tn("login") }}
        <router-link :to="`/?phone=${formData.phone}&remember=${isRememberMe}`">
          {{ tn("login_link") }}
        </router-link>
      </div>
    </auth-template>
  </div>
</template>
