<script>
import { mapGetters } from "vuex";

import TransitionExpand from "@/components/transition-expand.vue";

import tnVue from "@/mixins/tn.vue";

export default {
  components: { TransitionExpand },
  props: {
    tableHeight: Number,
    value: Array,
    isShow: Boolean,
  },

  data: () => ({
    ln: "selection.table.filter.region",

    selectedRows: [],

    searchText: null,
  }),

  mixins: [tnVue],

  computed: {
    ...mapGetters("references", { entries: "GET_REGIONS" }),

    tableHeaders: (vm) => [{ text: vm.tn("table.name"), value: "name", width: "100%" }],

    isShowRegions: {
      get: (vm) => vm.isShow,
      set() {
        this.$emit("switchHide");
      },
    },
  },

  created() {
    this.selectedRows = this.$array(this.value).map((x) => ({ guid: x }));
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-center">
      <v-btn
        @click="isShowRegions = !isShowRegions"
        color="primary"
        outlined
        x-small
        icon
      >
        <v-icon v-if="isShowRegions"> mdi-minus </v-icon>
        <v-icon v-else> mdi-plus </v-icon>
      </v-btn>

      <div class="d-flex align-items-center">
        <h4
          class="pl-2"
          v-text="tn('title')"
        />
        <div
          v-if="selectedRows.length"
          class="pl-1 error--text"
        >
          ({{ selectedRows.length }})
          <span
            @click="selectedRows = []"
            class="span-link"
          >
            {{ tn("clear") }}
          </span>
        </div>
      </div>
    </div>

    <TransitionExpand>
      <div v-show="isShowRegions">
        <div class="pt-1"></div>
        <div class="pb-2">
          <v-text-field
            outlined
            hide-details
            dense
            append-icon="mdi-magnify"
            :label="tn('search')"
            v-model="searchText"
            clearable
          />
        </div>
        <v-data-table
          dense
          :headers="tableHeaders"
          :items="entries"
          :mobile-breakpoint="0"
          hide-default-footer
          disable-pagination
          :height="tableHeight"
          :search="searchText"
          disable-sort
          show-select
          v-model="selectedRows"
          item-key="guid"
          key="guid"
          class="remove-checkbox-margin"
          checkbox-color="primary"
        />
      </div>
    </TransitionExpand>
  </div>
</template>
