<script>
import tnVue from "@/mixins/tn.vue";
import { mapActions } from "vuex";
import ModalsTemplate from "@/components/modals-template.vue";

export default {
  components: { ModalsTemplate },
  props: {
    ln: String,
    itemData: Object,
    value: Object,
  },

  data: (vm) => ({
    dialog: true,
    isLoading: false,
    formData: { ...(vm.value || {}) },
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    ...mapActions("pharmacy-requirements-product-link", ["ADD_API"]),

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        const params = { ...this.formData, linkedProductGuid: this.itemData.guid };
        const status = await this.ADD_API(params);
        if (status) {
          this.formData = {};
          this.dialog = false;
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.$t("success"),
            color: "success",
          });
          this.$emit("saved");
        }

        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <ModalsTemplate
    :width="600"
    :title="tn('title')"
    v-model="dialog"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-row>
        <v-col cols="12">
          {{ tn("form.product") }}
          <br />
          <div
            v-text="itemData.name"
            style="font-weight: bold; font-size: 16px"
          />
        </v-col>

        <v-col
          cols="12"
          class="pb-5"
        >
          {{ tn("form.name") }}
          <v-text-field
            autofocus
            outlined
            dense
            v-model="formData.productName"
            required
            :rules="[$rules.required]"
            hide-details
          />
        </v-col>

        <v-col
          cols="12"
          class="pb-5"
        >
          {{ tn("form.manufacturer") }}
          <v-text-field
            outlined
            dense
            v-model="formData.manufacturerName"
            required
            :rules="[$rules.required]"
            hide-details
          />
        </v-col>
      </v-row>

      <div class="d-flex justify-center mt-8">
        <v-btn
          :loading="isLoading"
          color="primary"
          @click="onSave"
          elevation="0"
          class="my-1"
          width="60%"
        >
          {{ tn("save_btn") }}
        </v-btn>
      </div>
    </v-form>
  </ModalsTemplate>
</template>
