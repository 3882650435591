import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
import { parseDateAsync } from "@/utils/parse-date-async";
const CancelToken = axios.CancelToken;
let cancelSource = null;

export const state = () => ({
  itemsData: {},
  importItems: [],
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(
        payload?.items.map((x, i) => {
          x._id = i + 1;
          return x;
        })
      ),
      total: vm.$number(payload?.totalCount),
      totals: {
        totalAmount: vm.$number(payload?.totalAmount),
        quantity: vm.$number(payload?.totalQuantity),
      },
    };
  },

  SET_IMPORT_ITEMS(state, payload = []) {
    state.importItems = vm.$array(payload);
  },
};

export const actions = {
  async GET_API({ commit }, { options }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_ITEMS");

    const params1 = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      sortBy: options.sortBy,
      sortDirection: options.sortDesc,
      search: options.search,
      basketGuid: options.basketGuid,
    };

    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$post("BasketPriceListProviderResult/List", params, request);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  SET_IMPORT_ITEMS({ commit }, payload) {
    commit("SET_IMPORT_ITEMS", payload);
  },

  async IMPORT_ITEMS_API({ state }, { fields, basketGuid }) {
    const lineItems = await correctItemsAsync(state.importItems, fields);

    let status = false;
    let data;
    if (lineItems?.length) {
      const res = await vm.$axios.$post("BasketPriceListProviderResult/Import", {
        basketGuid,
        lineItems,
      });
      status = res?.succeeded;
      data = res?.data;
    }

    return { status, count: lineItems?.length, data };
  },

  async DELETE_ALL_API(ctx, basketGuid) {
    const _url = `BasketPriceListProviderResult/${basketGuid}/All`;
    const res = await vm.$axios.$delete(_url);
    return res?.succeeded;
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
  GET_TOTALS: (state) => state.itemsData.totals,
  GET_IMPORT_ITEMS: (state) => state.importItems,
};

async function correctItemsAsync(lineItems, fields) {
  const _lineItems = lineItems?.length ? lineItems.slice(1) : [];

  const lifs = [];
  for (const key in fields) {
    if (trim(fields[key])) {
      lifs.push({ key, param: fields[key] });
    }
  }

  let newItems = [];
  for (const lineItem of _lineItems) {
    const params = {};

    lifs.forEach((li) => (params[li.param] = trim(lineItem[li.key])));

    if (params.nm) {
      const nms = `${params.nm}`.split("\\");
      params.manufacturerName = nms.length > 1 ? nms.pop() : "";
      params.productName = nms.join();
      delete params.nm;
    }

    // fix decimals
    Object.keys(params)
      .filter((_key) => !(_key == "productName" || _key == "manufacturerName" || _key == "shelfLife"))
      .forEach((_dkey) => (params[_dkey] = fixDecimal(params[_dkey])));

    params.shelfLife = await parseDateAsync(params.shelfLife);
    if (!params.shelfLife) delete params.shelfLife;

    if (trim(params.productName) && trim(params.manufacturerName)) {
      newItems.push(params);
    }
  }

  return newItems;
}

function fixDecimal(string) {
  let str = `${string || ""}`;

  if (str.includes(".")) {
    // Agar . bo'lsa u holda hamma , olib tashlash kerek
    str = str.replace(/,/g, "");
  } else {
    // Agar nuqta bo'lmasa va bir dona , bo'lsa u holda , -> .
    if ((str.match(/,/g) || []).length == 1) {
      str = str.replace(/,/g, ".");
    }
  }

  str = str.replace(/[^,.0-9]/g, "");

  if (str == "0") return null;
  return vm.$number(str) || null;
}

function trim(str) {
  return `${str || ""}`.trim();
}
