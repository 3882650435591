import Vue from "vue";
import * as XLSX from "xlsx";
const vm = Vue.prototype;

export const readExcelData = (file) => {
  return new Promise((resolve) => {
    try {
      const reader = new FileReader();

      reader.onload = (fileReader) => {
        const unitData = new Uint8Array(fileReader.target.result);
        const params = { type: "array", cellText: false, cellDates: true };
        const workbook = XLSX.read(unitData, params);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const lineItems = XLSX.utils
          .sheet_to_json(worksheet, {
            defval: "",
            header: 1,
            raw: false,
            dateNF: "yyyy-mm-dd",
          })
          .filter((elms) => {
            const new_elmps = vm.$array(elms);
            const emps = new_elmps.filter((x) => !x || x == "");

            // agar bitta columnda data bo'lsa chiqmaydi
            if (emps.length == new_elmps.length - 1) return false;
            return emps.length != new_elmps.length;
          })
          .map((x) => Object.assign({}, x));

        resolve(lineItems);
      };

      reader.readAsArrayBuffer(file);
    } catch (e) {
      resolve(null);
    }
  });
};
