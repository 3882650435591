<script>
import Inputmask from "inputmask";

export default {
  props: {
    required: Boolean,
    clearable: Boolean,
    value: String,
    dense: Boolean,
    hideDetails: Boolean,
    autofocus: Boolean,
    disabled: Boolean,
  },

  data: () => ({
    model: null,
    isValid: true,
  }),

  created() {
    const date = this.$date(this.value, true);
    if (this.value) this.model = date;
  },

  methods: {
    onBlur() {
      const isDot = Inputmask.isValid(this.model, {
        alias: "datetime",
        inputFormat: "dd.mm.yyyy",
      });

      if (isDot) {
        this.model = Inputmask.unmask(this.model, {
          alias: "datetime",
          inputFormat: "dd.mm.yyyy",
          outputFormat: "yyyy-mm-dd",
        });
      } else {
        this.isValid = Inputmask.isValid(this.model, {
          alias: "datetime",
          inputFormat: "yyyy-mm-dd",
        });

        this.$emit("input", this.isValid ? this.model.replace(/ /, "") : "");
      }
    },
  },
};
</script>

<template>
  <v-text-field
    v-inputmask="$masks.date"
    @blur="onBlur"
    outlined
    :error="!isValid"
    @keyup.enter="onBlur"
    @input="isValid = true"
    @focus="
      () => {
        isValid = true;
        $emit('focus');
      }
    "
    v-model="model"
    :required="required"
    :clearable="clearable"
    :rules="required ? [$rules.required] : []"
    :dense="dense"
    :hide-details="hideDetails"
    :autofocus="autofocus"
    :disabled="disabled"
  />
</template>
