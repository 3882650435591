var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","min-width":"40","width":"40","height":"40","min-height":"40","outlined":"","title":_vm.tn('show_by_provider_btn')},on:{"click":_vm.changeView}},[_c('v-icon',[_vm._v(" mdi-format-indent-increase ")])],1),_c('v-btn',{attrs:{"color":"primary","min-width":"40","width":"40","height":"40","min-height":"40","outlined":"","loading":_vm.isLoading,"title":_vm.tn('refresh_btn')},on:{"click":_vm.getEntries}},[_c('v-icon',[_vm._v(" mdi-sync ")])],1),_c('div',{staticClass:"px-1"}),_c('table-cog',{attrs:{"headersData":_vm.tableHeaders,"cacheKey":_vm.cacheKey},on:{"apply":(v) => (_vm.filteredHeaders = _vm.$compareArray(_vm.tableHeaders, v, 'value'))}}),_c('div',{staticClass:"mx-1 py-6"}),_c('v-text-field',{ref:"searchBox",staticClass:"w-100",attrs:{"outlined":"","hide-details":"","dense":"","append-icon":"mdi-magnify","label":_vm.tn('search'),"clearable":""},on:{"click:append":_vm.onSearch,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSearch.apply(null, arguments)},"click:clear":_vm.onClear},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_vm._t("default"),_c('div',{staticClass:"mt-1"}),(_vm.filteredHeaders.length && _vm.tableHeight > 0)?_c('v-data-table',{key:"guid",staticClass:"table-footer-nowrap",attrs:{"headers":_vm.filteredHeaders,"dense":"","height":_vm.tableHeight,"mobile-breakpoint":0,"loading":_vm.isLoading,"options":_vm.options,"items":_vm.entries,"server-items-length":_vm.entriesCount,"items-per-page":_vm.itemsPerPage,"fixed-header":"","must-sort":"","sort-by":_vm.sortBy,"footer-props":{
      itemsPerPageOptions: [25, 50],
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageText: _vm.$t('table.per_page_text'),
    }},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item, headers, index }){return [_c('tr',{staticClass:"cursor-pointer",class:{
          'tr-active': _vm.selected.guid == item.guid,
        },on:{"click":function($event){$event.stopPropagation();return _vm.onSelect({ item })}}},[_vm._l((headers),function(head){return [(head.value == 'number')?_c('td',{key:head.value,staticClass:"px-0 text-center"},[_c('span',{staticClass:"text-nowrap",domProps:{"textContent":_vm._s(_vm.$sum((_vm.options.page - 1) * _vm.options.itemsPerPage + (index + 1)))}})]):(head._filter)?_c('td',{key:head.value + 1},[(head._html)?_c('div',{staticClass:"text-nowrap",style:({ minWidth: head.minWidth }),domProps:{"innerHTML":_vm._s(head._filter(item[head.value], item))}}):_c('div',{staticClass:"text-nowrap",style:({ textAlign: head._align, minWidth: head.minWidth }),domProps:{"textContent":_vm._s(head._filter(item[head.value], item))}})]):(head.value == 'name')?_c('td',{key:head.value + 4},[_c('table-focus-input',{ref:item.id,refInFor:true,attrs:{"tabindex":index + 1,"item":item,"uniKey":_vm.cacheKey},on:{"upKey":function($event){return _vm.onUpKey({ item })},"downKey":function($event){return _vm.onDownKey({ item })},"pageUp":function($event){return _vm.onPageUp({ item })},"pageDown":function($event){return _vm.onPageDown({ item })}}}),_c('div',{style:({ minWidth: head.minWidth }),domProps:{"textContent":_vm._s(item[head.value])}})],1):_c('td',{key:head.value + 4},[_c('div',{style:({ minWidth: head.minWidth }),domProps:{"textContent":_vm._s(item[head.value])}})])]})],2)]}}],null,false,2486941859)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }