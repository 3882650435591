<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<template>
  <v-footer style="flex-wrap: wrap">
    <div> {{ $t("rights_reserved") }} </div>
    <v-spacer class="d-none d-md-flex" />
    <div>версия {{ $version }}</div>
  </v-footer>
</template>
