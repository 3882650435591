<script>
import { mapActions } from "vuex";

import tnVue from "@/mixins/tn.vue";
import Timer from "@/components/timer.vue";

export default {
  components: { Timer },

  props: {
    phone: String,
    isRememberMe: Boolean,
  },

  data: () => ({
    isLoading: false,
    ln: "confirm_register_sms_code",

    formData: {},

    isTimeLeft: false,
  }),

  mixins: [tnVue],

  methods: {
    ...mapActions("auth", ["CONFIRM_REGISTER_SMS_CODE", "RE_SEND_SMS_CODE"]),

    async onConfirm() {
      this.isLoading = true;
      const params = {
        ...this.formData,
        phone: this.phone,
        isRememberMe: this.isRememberMe,
      };
      const status = await this.CONFIRM_REGISTER_SMS_CODE(params);
      if (status) {
        const msg = this.tn("registration_success");
        this.$store.dispatch("SET_SNACKBAR", { text: msg, color: "success" });
      }

      this.isLoading = false;
    },

    async onResendCode() {
      if (this.isLoading) return;
      this.isLoading = true;

      const status = await this.RE_SEND_SMS_CODE({
        phone: this.phone,
      });
      if (status) this.isTimeLeft = false;

      this.isLoading = false;
    },
  },
};
</script>

<template>
  <v-card-text>
    <h2 class="black--text">{{ tn("title") }}</h2>
    <div class="pt-5">
      <div>
        {{ tn("code_sent_number") }}
      </div>
      <span class="text-nowrap">
        {{ phone | tel }}
      </span>
    </div>
    <div class="pt-3" />
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <div class="black--text">{{ tn("form.sms") }}</div>
      <v-text-field
        outlined
        dense
        autofocus
        hide-details
        :rules="[$rules.number4Length]"
        v-inputmask="$masks.number4Length"
        v-model="formData.smsCode"
        @keyup.enter="onConfirm"
      />
    </v-form>
    <div class="pt-3" />

    <div v-if="isTimeLeft">
      <a @click="onResendCode"> Еще запросить SMS-код </a>
    </div>
    <div
      v-else
      class="d-flex align-center"
    >
      <div class="pt-1">Можно еще запросить SMS-код через:&nbsp;</div>
      <Timer
        class="title"
        show-total
        :deadline="180000"
        @timeEnd="isTimeLeft = true"
      />
    </div>

    <a @click="$emit('change')">
      {{ tn("restart_registration") }}
    </a>

    <div class="pt-3" />

    <v-btn
      @click="onConfirm"
      :loading="isLoading"
      elevation="0"
      width="100%"
      color="primary"
    >
      {{ tn("confirm_btn") }}
    </v-btn>
  </v-card-text>
</template>
