<script>
import tnVue from "@/mixins/tn.vue";
import { mapGetters, mapActions } from "vuex";
import ModalsTemplate from "@/components/modals-template.vue";

import CreateUser from "@/components/modals/price-list-viewing-access-permission/create-user.vue";
import PermissionFilter from "@/components/modals/price-list-viewing-access-permission/permission-filter.vue";
import PriceListUserPermissionHideSwitch from "./price-list-user-permission-hide-switch.vue";
import TransitionExpand from "@/components/transition-expand.vue";

export default {
  components: {
    CreateUser,
    ModalsTemplate,
    PermissionFilter,
    PriceListUserPermissionHideSwitch,
    TransitionExpand,
  },

  props: {
    priceListObject: Object,
  },
  mixins: [tnVue],

  data: (vm) => ({
    ln: "price_list.show.viewing_access_permission",

    options: {},
    itemsPerPage: 50,
    rowsPerPage: [50, 100],
    loaders: {},

    isCreateUser: false,
    dialog: true,
    isFilter: false,

    filterData: vm.$getFilterData() || { isAllowed: true },
    selected: {},
    isPublic: vm.priceListObject?.isPublic,
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },

    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("provider-permission", {
      entries: "GET",
      entriesCount: "GET_COUNT",
    }),

    size: (vm) => vm.$store.getters.GET_SIZE,
    tableHeight: (vm) => vm.size.height - 550,
    isLoading: (vm) => !!Object.keys(vm.loaders).length,

    tableHeaders: (vm) => [
      {
        text: "№",
        value: "number",
        width: 10,
        sortable: false,
      },
      {
        text: vm.tn("table.phone_number"),
        value: "priceListViewerPhone",
      },
      {
        text: vm.tn("table.created_on"),
        value: "createdOn",
        width: 10,
        _filter: (v) => vm.$date(v),
      },
      {
        text: vm.tn("table.modified_at"),
        value: "modifiedOn",
        width: 10,
        _filter: (v) => vm.$date(v),
      },
      {
        text: vm.tn("table.viewing_access"),
        value: "isAllowed",
        width: "142px",
      },
      {
        text: "",
        value: "_actions",
      },
    ],

    isFilterBadge() {
      const values = Object.values(this.filterData);
      const filters = values.filter((x) => x === 0 || `${x || ""}`.length);
      return filters.length;
    },
  },

  methods: {
    ...mapActions("provider-permission", ["GET_API", "GET_PUBLIC_API"]),

    async getEntries(payload) {
      if (payload?.reset) this.options.page = 1;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = { options: { ...this.options } };

      params.options.sortBy = this.$getSort(params.options.sortBy);
      params.options.sortDesc = this.$getSort(params.options.sortDesc, true);
      params.options.providerGuid = this.priceListObject.providerGuid;

      if (!params.options.sortBy) delete params.options.sortDesc;

      params.filterData = { ...this.filterData };

      await this.GET_API(params);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    async onExportExcel() {
      this.$store.dispatch("SET_LOADING", true);
      const guid = this.priceListObject.guid;
      const providerGuid = { providerGuid: this.priceListObject.providerGuid };
      const url = `PriceListProviderPermission/${guid}/Export`;
      await this.$axios.$postResFile(url, providerGuid);
      this.$store.dispatch("SET_LOADING", false);
    },

    async onChange(isPublic) {
      this.$store.dispatch("SET_LOADING", true);

      const params = {
        guid: this.priceListObject.providerGuid,
        isPublic: isPublic,
      };
      await this.GET_PUBLIC_API(params);
      this.$store.dispatch("SET_LOADING", false);
    },

    onFilter(filterData) {
      this.filterData = filterData;
      this.$setFilterData(filterData);
      this.$nextTick(() => this.getEntries());
    },
  },
};
</script>

<template>
  <modals-template
    :width="675"
    v-model="dialog"
    :title="tn('title')"
    :persistent="false"
  >
    <CreateUser
      v-if="isCreateUser"
      :value="selected"
      :providerGuid="priceListObject.providerGuid"
      @close="isCreateUser = false"
      @saved="getEntries"
    />

    <PermissionFilter
      v-if="isFilter"
      :value="filterData"
      @close="isFilter = false"
      @apply="onFilter"
    />

    <div class="mb-3">
      <v-radio-group
        @change="onChange"
        v-model="isPublic"
        mandatory
        hide-details
        class="ma-0 pa-0"
      >
        <v-radio
          :label="tn('filter.form.public_price_list')"
          :value="true"
        />
        <v-radio
          :label="tn('filter.form.available_for_the_following_users')"
          :value="false"
        />
      </v-radio-group>
    </div>

    <TransitionExpand>
      <div v-show="!isPublic">
        <div class="d-flex align-center py-2">
          <v-spacer />

          <v-btn
            color="primary"
            min-width="40"
            width="40"
            height="40"
            min-height="40"
            outlined
            @click="getEntries"
            :disabled="isLoading"
            :loading="isLoading"
            :title="tn('refresh_btn')"
          >
            <v-icon> mdi-sync </v-icon>
          </v-btn>

          <div class="mx-1" />

          <v-badge
            dot
            overlap
            color="error"
            :value="isFilterBadge"
          >
            <v-btn
              color="primary"
              min-width="40"
              width="40"
              height="40"
              min-height="40"
              outlined
              @click="isFilter = true"
              :title="tn('filter_btn')"
            >
              <v-icon> mdi-filter </v-icon>
            </v-btn>
          </v-badge>

          <div class="mx-1" />

          <v-btn
            color="primary"
            min-width="40"
            width="40"
            height="40"
            min-height="40"
            outlined
            :loading="isLoading"
            :title="tn('export_excel_btn')"
            @click="onExportExcel"
          >
            <v-icon> mdi-file-excel-outline </v-icon>
          </v-btn>

          <div class="mx-1" />

          <v-btn
            color="primary"
            min-width="40"
            width="40"
            height="40"
            min-height="40"
            outlined
            :loading="isLoading"
            :title="tn('add_btn')"
            @click="isCreateUser = true"
          >
            <v-icon> mdi-plus</v-icon>
          </v-btn>
        </div>

        <v-data-table
          :headers="tableHeaders"
          :height="tableHeight"
          dense
          :items="entries"
          :mobile-breakpoint="0"
          :loading="isLoading"
          fixed-header
          :options.sync="options"
          :server-items-length="entriesCount"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{
            itemsPerPageOptions: [25, 50],
            showCurrentPage: true,
            showFirstLastPage: true,
            itemsPerPageText: $t('table.per_page_text'),
          }"
          class="fixed-right"
        >
          <template v-slot:item="{ item, headers, index }">
            <tr
              class="cursor-pointer"
              @click="selected = { ...item }"
              :class="selected.guid == item.guid && 'tr-active'"
            >
              <template v-for="head in headers">
                <td
                  v-if="head.value == 'number'"
                  :key="head.value"
                  class="px-0 text-center"
                >
                  <span
                    class="text-nowrap"
                    v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
                  />
                </td>

                <td
                  v-else-if="head._filter"
                  :key="head.value"
                >
                  <div
                    class="text-nowrap"
                    v-if="head._html"
                    v-html="head._filter(item[head.value], item)"
                  />
                  <div
                    v-else
                    class="text-nowrap"
                    v-text="head._filter(item[head.value], item)"
                  />
                </td>

                <td
                  v-else-if="head.value == 'isAllowed'"
                  :key="head.value"
                  class="px-0"
                >
                  <div class="d-flex justify-center ml-4">
                    <price-list-user-permission-hide-switch :item="item" />
                  </div>
                </td>

                <td
                  v-else
                  :key="head.value"
                >
                  <div v-text="item[head.value]" />
                </td>
              </template>
            </tr>
          </template>
        </v-data-table>
      </div>
    </TransitionExpand>
  </modals-template>
</template>
