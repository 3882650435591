<script>
import TableCog from "@/components/modals/table-cog.vue";
import tnVue from "@/mixins/tn.vue";

export default {
  components: {
    TableCog,
  },

  props: {
    headersData: {
      type: Array,
      required: true,
    },
    cacheKey: {
      type: String,
      default: () => window.pathname,
    },
  },

  data: () => ({
    isShowCog: false,
    ln: "table.cog",
    selectedItems: [],
    headerItems: [],
  }),

  mixins: [tnVue],

  computed: {
    isShowBadge: (vm) => vm.headerItems.length != vm.selectedItems.length,
  },

  created() {
    const select = this.$browserStore.getLocal(this.cacheKey);
    this.onApply(select, true);
  },

  methods: {
    onApply(values, init) {
      let array = this.$copyJson(this.headersData);

      if (this.$array(values).length) {
        array = array.map((x) => values.find((y) => y.value == x.value) || x);
      }

      if (!init) this.$browserStore.setLocal(this.cacheKey, array);
      array.sort((a, b) => a._order - b._order);
      this.headerItems = array.filter((x) => x.value != "actions");
      const selectedItems = this.headerItems.filter((x) => !x._hide);
      this.selectedItems = [...selectedItems];

      const actions = this.headersData.find((x) => x.value == "actions");
      if (actions) selectedItems.push({ ...actions });

      this.$emit("apply", selectedItems);
    },

    onReset() {
      this.$browserStore.delLocal(this.cacheKey);
      this.$browserStore.delLocal(`${this.cacheKey}-tb-sizes`);
      this.$store.dispatch("SET_INIT", true);
      this.$nextTick(() => this.$store.dispatch("SET_INIT", false));
    },
  },
};
</script>

<template>
  <div>
    <TableCog
      v-if="isShowCog"
      :items="headerItems"
      :selectedItems="selectedItems"
      @close="isShowCog = false"
      @apply="onApply"
      @reset="onReset"
      :tn="tn"
    />

    <v-badge
      dot
      overlap
      color="error"
      :value="isShowBadge"
    >
      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="isShowCog = true"
        :title="tn('cog_btn')"
      >
        <v-icon> mdi-table-cog </v-icon>
      </v-btn>
    </v-badge>
  </div>
</template>
