import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
import router from "@/router";
const CancelToken = axios.CancelToken;
let cancelSource = null;

let importErrorCount = 0;
let isImportCancel = false;
let importSessionGuid = null;
let importCheckTimeout = null;

const url = 'PriceListViewerOsonKassaSetting'
const url2 = 'PriceListViewerOrganizationStatisticImportRequest/Requirements/Import'
const url3 = 'PriceListViewerOrganizationStatisticImportRequest'

export const state = () => ({
  itemsData: {},
  importItems: [],
  kassaSyncData: {},
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    };
  },

  SET_KASSA_SYNC_DATA: (state, payload) => {
    state.kassaSyncData = payload
  },
};

export const actions = {
  async GET_API({ commit }) {
    const res = await vm.$axios.$get(`${url}/List`,);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  async ADD_API({ commit }, payload) {
    const res = await vm.$axios.$post(url, payload);
    const status = res?.succeeded;

    if (status) commit("SET_KASSA_SYNC_DATA", res.data);
    return status;
  },

  async CHANGE_API({ commit }, payload) {
    const res = await vm.$axios.$put(url + "/Change", payload);
    const status = res?.succeeded;

    if (status) commit("SET_KASSA_SYNC_DATA", res.data);
    return status;
  },

  async START_IMPORT_API({ state }, payload) {
    const res = await vm.$axios.$post(url2, payload, getAxiosConfig(state.kassaSyncData));
    if (res?.succeeded) {
      importSessionGuid = res?.data
      const status = await checkSelectionCompleteAsync(res.data);
      if (status) router.push(`/pharmacy-requirements/join/${res.data}`);
    }

    return res?.succeeded;
  },

  async GET_BRANCHES_API({ state }) {
    if (!state.kassaSyncData?.token) return []
    const res = await vm.$axios.$get('OsonKassa/BranchList', null, getAxiosConfig(state.kassaSyncData));
    return vm.$array(res?.data?.items);
  },

  async DELETE_API(ctx, { item }) {
    const res = await vm.$axios.$delete(`${url}/Delete/${item.guid}`);
    return res?.succeeded;
  },

  async CANCEL_IMPORT_API() {
    if (!importSessionGuid) return
    const res = await vm.$axios.$post(`${url3}/Cancel`, { guid: importSessionGuid });
    isImportCancel = res?.succeeded;
    return isImportCancel;
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
  GET_KASSA_SYNC_DATA: (state) => state.kassaSyncData || {},
}

function getAxiosConfig(data) {
  return {
    headers: { OsonKassaToken: data?.token }
  }
}

async function checkSelectionCompleteAsync(guid) {
  return new Promise((resolve) => checkSelectionComplete(guid, (e) => resolve(e)));
}

async function checkSelectionComplete(guid, callback) {
  cancelSource?.cancel();
  cancelSource = CancelToken.source();

  if (isImportCancel) {
    if (importCheckTimeout) clearTimeout(importCheckTimeout)

    isImportCancel = false;
    return callback(false);
  }

  const request = { progress: false, cancelToken: cancelSource.token };
  const res = await vm.$axios.$get(`${url3}/${guid}/Check`, {}, request);

  if (res?.succeeded) {
    if (importCheckTimeout) clearTimeout(importCheckTimeout)
    importErrorCount = 0;
    if (res.data) return callback(true);
  } else {
    importErrorCount++;
  }

  if (importErrorCount <= 5) {
    importCheckTimeout = setTimeout(() => checkSelectionComplete(guid, callback), 5000);
  } else {
    return callback(false);
  }
}
