function createDiv() {
  const div = document.createElement('div');
  div.style.top = 0;
  div.style.right = 0;
  div.style.width = '5px';
  div.style.position = 'absolute';
  div.style.cursor = 'col-resize';
  div.style.userSelect = 'none';
  div.style.height = '100%';
  div.style.zIndex = 1
  return div;
}

function paddingDiff(col) {
  if (getStyleVal(col, 'box-sizing') == 'border-box') {
    return 0;
  }

  const padLeft = getStyleVal(col, 'padding-left');
  const padRight = getStyleVal(col, 'padding-right');
  return (parseInt(padLeft) + parseInt(padRight));
}

function getStyleVal(elm, css) {
  return (window.getComputedStyle(elm, null).getPropertyValue(css))
}

function setResizeTable(tableWrap, { callback, tableHeaders }) {
  if (!callback) {
    console.error("v-resize-table", "callback is required")
    return;
  }

  tableWrap.classList.add('resize-table');

  const table = tableWrap.querySelector('table');

  const cols = table.getElementsByTagName('tr')[0]?.children;
  if (!cols) return;

  for (let i = 0; i < cols.length; i++) {
    const head = tableHeaders[i]
    cols[i].setAttribute('data-column-index', i)
    cols[i].setAttribute('data-column', head.value)
    cols[i].setAttribute('title', head.text)

    if (i == cols.length - 1 && head.value == '_actions_btn') {
      cols[i].style.setProperty('padding-left', '0px', 'important');
      cols[i].style.setProperty('padding-right', '0px', 'important');
    }

    if (head?._resize !== false) {
      const div = createDiv();

      cols[i].appendChild(div);
      if (head?.value) {
        if (head.width) {
          cols[i].style.width = head.width;
          cols[i].style.minWidth = head.width;
          cols[i].style.maxWidth = head.width;
        }
        setListeners(table, div);
      }
    }
  }

  function setListeners(table, div) {
    let pageX, curCol, curColWidth, curCols;

    div.addEventListener('mousedown', function (e) {
      //table.style.tableLayout = "fixed"
      curCol = e.target.parentElement;

      if (curCol.dataset.columnIndex >= 0) {
        curCols = [];

        for (let index = 0; index < table?.rows?.length; index++) {
          const column = table.rows[index]?.cells?.[curCol.dataset.columnIndex]
          curCols.push(column)
        }
      }

      pageX = e.pageX;
      curColWidth = curCol.offsetWidth - paddingDiff(curCol);
    });

    div.addEventListener('mouseover', function (e) {
      e.target.style.borderRight = '2px solid #0000ff';
    })

    div.addEventListener('mouseout', function (e) {
      e.target.style.borderRight = '';
    })

    div.addEventListener('click', function (e) {
      e.stopPropagation()
    })

    document.addEventListener('mousemove', function (e) {
      if (!curCol) return
      const diffX = e.pageX - pageX;
      const width = (curColWidth + diffX) + 'px';

      for (let index = 0; index < curCols?.length; index++) {
        const element = curCols[index];
        if (!element) return
        element.style.width = width;
        element.style.minWidth = width;
        element.style.maxWidth = width;
      }

      if (curCol.dataset.column) callback(curCol.dataset.column, width)
    });

    document.addEventListener('mouseup', function () {
      curCols = undefined;
      curCol = undefined;
      pageX = undefined;
      curColWidth = undefined;
    });
  }
}

export default {
  install(Vue) {
    Vue.directive("resize-table", {
      bind(el, binding) {
        if (!el || !binding) return
        setResizeTable(el, binding.value);
      },
    })
  },
};
