<script>
import tnVue from "@/mixins/tn.vue";
import { mapActions } from "vuex";

export default {
  props: {
    phone: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    ln: "re_captcha",

    image: null,

    formData: {},
  }),

  mixins: [tnVue],

  created() {
    this.init();
  },

  methods: {
    ...mapActions("auth", ["RECAPTCHA", "CONFIRM_RECAPTCHA"]),

    async init() {
      this.$store.dispatch("SET_LOADING", true);
      const image = await this.RECAPTCHA({ phone: this.phone });
      if (image) this.image = "data:image/png;base64," + image;
      this.$store.dispatch("SET_LOADING", false);
    },

    async onConfirm() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const params = { ...this.formData, phone: this.phone };
        const status = await this.CONFIRM_RECAPTCHA(params);
        if (status) this.$emit("next");

        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <v-card-text>
    <h2 class="black--text">{{ tn("confirmation") }}</h2>

    <div class="pt-3" />
    <div class="image-wrap">
      <img
        class="image-view"
        v-if="image"
        :src="image"
        alt=""
      />
    </div>

    <div class="text-right pt-1">
      <a
        @click="init"
        class="select-none"
        v-text="tn('change_image')"
      />
    </div>
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <div class="pt-3" />
      <div class="black--text">{{ tn("form.enter_code_image") }}</div>
      <v-text-field
        outlined
        dense
        autofocus
        hide-details
        :rules="[$rules.number4Length]"
        v-inputmask="$masks.number4Length"
        v-model="formData.recaptchaCode"
        @keyup.enter="onConfirm"
      />
    </v-form>
    <div class="pt-5" />

    <v-btn
      :loading="isLoading"
      @click="onConfirm"
      elevation="0"
      width="100%"
      color="primary"
    >
      {{ tn("confirm_btn") }}
    </v-btn>
  </v-card-text>
</template>

<style lang="scss" scoped>
.image-wrap {
  display: flex;
  justify-content: center;

  .image-view {
    max-width: 400px;
    width: 100%;
  }
}
</style>
