<script>
import tnVue from "@/mixins/tn.vue";
import debounce from "debounce";
import { mapActions, mapGetters } from "vuex";
import ProductsList from "./components/products-list.vue";
import SearchSuggestBox from "@/components/search-suggest-box.vue";
import AddProductLink from "@/components/modals/pharmacy-requirements/add-product-link.vue";
import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import TableFocusInput from "./components/table-focus-input.vue";

export default {
  components: {
    ProductsList,
    SearchSuggestBox,
    AddProductLink,
    ConfirmDialog,
    TableFocusInput,
  },

  data: () => ({
    ln: "pharmacy_requirements",
    cacheKey: "pharmacy-requirements-products-list-2",

    sortBy: null,
    options: {},
    itemsPerPage: 50,

    loaders: {},

    idsObject: {},
    selected: {},
    itemData: {},

    searchText: null,
    isAddProductLink: false,
    isShowSelectedDelete: false,

    isToPrev: false,
    isSavePosition: false,

    isShowTable: false,
  }),

  mixins: [tnVue],

  watch: {
    itemData(v) {
      this.isShowTable = false;
      if (v?.guid) {
        this.getEntries({ reset: true });
        this.$nextTick(() => (this.isShowTable = true));
      }
    },

    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },

    searchText() {
      this.onSearch();
    },
  },

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("pharmacy-requirements-product-link", {
      entries: "GET",
      entriesCount: "GET_COUNT",
    }),

    tableHeight: (vm) => vm.size.height - 170,
    pagesCount: (vm) => Math.ceil(vm.entriesCount / vm.itemsPerPage),
    isLoading: (vm) => !!Object.keys(vm.loaders).length,
    selectedIds: (vm) => Object.keys(vm.idsObject),

    isSelectAll: {
      get() {
        const count = this.selectedIds?.length;
        return count && count == this.entries?.length;
      },
      set(v) {
        if (!v) this.idsObject = {};
        else {
          const items = {};
          this.entries.forEach((item) => (items[item.guid] = true));
          this.idsObject = items;
        }
      },
    },

    tableHeaders: (vm) => [
      {
        text: "",
        value: "_checkbox",
        sortable: false,
        _required: true,
        width: 10,
      },
      {
        text: "#",
        value: "number",
        width: 10,
        sortable: false,
        align: "center",
        class: "disable-resize",
      },
      {
        text: vm.tn("products_list.table.productName"),
        value: "productName",
        minWidth: "180px",
      },
      {
        text: vm.tn("products_list.table.manufacturer"),
        value: "manufacturerName",
        minWidth: "180px",
      },
      {
        text: "",
        value: "actions",
        width: 0,
        sortable: false,
        class: "disable-resize",
      },
    ],
  },

  mounted() {
    document.addEventListener("keydown", this.onDocumentKeydown);
  },

  destroyed() {
    document.removeEventListener("keydown", this.onDocumentKeydown);
  },

  methods: {
    ...mapActions("pharmacy-requirements-product-link", {
      getItemsByProductApi: "GET_API",
      deleteApi: "DELETE_API",
    }),

    async getEntries(payload) {
      if (payload?.reset) this.options.page = 1;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          sortOrders: this.$getSortOrders(this.options),
          isCancel: !this.itemData.guid,
          productGuid: this.itemData.guid,
        },
      };

      if (`${this.searchText || ""}`.trim()) params.options.search = this.searchText;
      if (!params.options.sortBy) delete params.options.sortDirection;

      await this.getItemsByProductApi(params);

      if (this.checkFocused()) {
        this.setFocusToItem();
      } else {
        this.selected = {};
      }

      this.idsObject = {};

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSearch: debounce(function () {
      this.getEntries({ reset: true });
    }, 500),

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.cacheKey);
    },

    onAdd() {
      this.selected = {};
      this.$nextTick(() => (this.isAddProductLink = true));
    },

    onEdit({ item }) {
      this.selected = item;
      this.$nextTick(() => (this.isAddProductLink = true));
    },

    onDocumentKeydown(event) {
      if (event.code == "Tab") {
        event.preventDefault();
        this.onTab();
      }
    },

    onTab() {
      const table1 = this.$refs.table1;
      if (this.checkFocused()) {
        table1.isSavePosition = true;
        table1.setFocusToItem();
      } else {
        const item = this.$firstArrayItem(this.entries);
        if (!item) return;
        this.onSelect({ item });
      }
    },

    async onSelectedDelete({ close, setLoading }) {
      setLoading(true);

      const params = { guidList: this.selectedIds };

      const status = await this.deleteApi(params);

      if (status) {
        this.getEntries({ reset: true });
        this.idsObject = {};
        this.$emit("refresh");
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("success"),
          color: "success",
        });
        close();
      }

      setLoading(false);
    },

    onSetSelected({ item }) {
      const items = { ...this.idsObject };
      if (items[item.guid]) delete items[item.guid];
      else items[item.guid] = true;
      this.idsObject = items;
    },

    onClickWithShift({ item }) {
      if (!this.selectedIds?.length) return;
      const firstId = this.$lastArrayItem(this.selectedIds);
      if (!firstId || firstId == item.guid) return;
      const first = this.entries.find((x) => x.guid == firstId);
      if (!first) return;

      if (first._id > item._id) {
        const idsObject = { ...this.idsObject };
        for (let index = item._id - 1; index < first._id; index++) {
          const li = this.entries[index];
          if (!li) return;
          idsObject[li.guid] = true;
        }
        this.idsObject = idsObject;
      } else {
        const idsObject = { ...this.idsObject };
        for (let index = first._id - 1; index < item._id; index++) {
          const li = this.entries[index];
          if (!li) return;
          idsObject[li.guid] = true;
        }
        this.idsObject = idsObject;
      }
    },

    setFocusToItem() {
      if (this.isSavePosition && this.selected?._id) {
        this.isSavePosition = false;
        const input = this.$setInputFocus(this.selected._id, this.cacheKey);
        if (!input) {
          this.isToPrev = true;
          this.setFocusToItem();
        }
      } else if (this.isToPrev) {
        this.isToPrev = false;
        const last = this.$lastArrayItem(this.entries);
        if (!last) return;
        this.selected = { ...last };
        this.$setInputFocus(this.selected._id, this.cacheKey);
      } else {
        const first = this.$firstArrayItem(this.entries);
        if (!first) return;
        this.selected = { ...first };
        this.$setInputFocus(this.selected._id, this.cacheKey);
      }
    },

    checkFocused() {
      return document.activeElement.className.includes(this.cacheKey);
    },

    onPageUp() {
      if (this.options.page > 1) {
        this.isSavePosition = true;
        this.options.page--;
      }
    },

    onPageDown() {
      if (this.options.page < this.pagesCount) {
        this.isSavePosition = true;
        this.options.page++;
      }
    },

    onUpKey({ item }) {
      if (item._id == 1 && this.options.page > 1) {
        this.isToPrev = true;
        this.options.page--;
      } else {
        this.isToPrev = false;
        if (this.$setInputFocus(item._id - 1, this.cacheKey)) {
          const select = this.entries[item._id - 2];
          if (select) this.selected = { ...select };
        }
      }
    },

    onDownKey({ item }) {
      this.isToPrev = false;
      if (this.entries.length == item._id && this.options.page < this.pagesCount) {
        this.options.page++;
      } else if (this.$setInputFocus(item._id + 1, this.cacheKey)) {
        const select = this.entries[item._id];
        if (select) this.selected = { ...select };
      }
    },

    onKeyupDown() {
      if (this.isLoading) return
      this.setFocusToItem();
    },
  },
};
</script>

<template>
  <div>
    <AddProductLink
      v-if="isAddProductLink"
      :ln="`${ln}.products_list.add`"
      :itemData="itemData"
      :value="selected"
      @close="isAddProductLink = false"
      @saved="getEntries"
    />

    <ConfirmDialog
      :text="tn('products_list.delete_alert')"
      @close="isShowSelectedDelete = false"
      v-if="isShowSelectedDelete"
      @accept="onSelectedDelete"
    />

    <v-container fluid>
      <v-row>
        <v-col
          cols="6"
          xl="6"
          class="pr-1"
        >
          <products-list
            ref="table1"
            :ln="ln + '.products_list'"
            :tableHeight="tableHeight"
            @select="(v) => (itemData = v)"
          />
        </v-col>

        <v-col
          cols="6"
          xl="6"
          class="pl-1"
        >
          <div class="d-flex align-center">
            <h4>{{ tn("products_list.title") }}</h4>
            <v-spacer />

            <v-badge
              v-if="!(itemData.status == 100)"
              :content="selectedIds.length"
              :value="selectedIds.length"
              overlap
              color="error"
            >
              <v-btn
                :disabled="!selectedIds.length"
                color="error"
                height="40"
                min-height="40"
                outlined
                @click="isShowSelectedDelete = true"
                :loading="isLoading"
                :title="tn('products_list.delete_btn')"
              >
                {{ tn("products_list.delete_btn") }}
              </v-btn>
            </v-badge>

            <div class="px-1" />

            <div style="max-width: 300px; width: 100%">
              <SearchSuggestBox
                @keyup-down="onKeyupDown({ enter: true })"
                :ln="ln"
                :searchName="'search_F4'"
                v-model="searchText"
              />
            </div>

            <div class="px-1" />

            <v-btn
              color="primary"
              height="40"
              min-height="40"
              outlined
              @click="onAdd"
              :loading="isLoading"
              :title="tn('products_list.add_btn')"
            >
              {{ tn("products_list.add_btn") }}
            </v-btn>

            <div class="px-1" />

            <v-btn
              color="primary"
              min-width="40"
              width="40"
              height="40"
              min-height="40"
              outlined
              @click="getEntries"
              :loading="isLoading"
              :title="tn('refresh_btn')"
            >
              <v-icon> mdi-sync </v-icon>
            </v-btn>
          </div>

          <div class="mt-1" />

          <v-data-table
            v-if="isShowTable && tableHeaders.length && tableHeight > 0"
            :headers="tableHeaders"
            dense
            :height="tableHeight"
            :mobile-breakpoint="0"
            :loading="isLoading"
            :options.sync="options"
            :items="entries"
            :server-items-length="entriesCount"
            :items-per-page.sync="itemsPerPage"
            fixed-header
            must-sort
            :sort-by="sortBy"
            :footer-props="{
              itemsPerPageOptions: [25, 50],
              showCurrentPage: true,
              showFirstLastPage: true,
              itemsPerPageText: $t('table.per_page_text'),
            }"
            key="guid"
            class="remove-checkbox-margin"
          >
            <template #header._checkbox>
              <div class="d-flex justify-center align-center">
                <v-checkbox
                  color="primary"
                  :indeterminate="!!selectedIds?.length && selectedIds?.length != entries.length"
                  v-model="isSelectAll"
                  hide-details
                  class="mt-0 pt-0"
                />
              </div>
            </template>

            <template v-slot:item="{ item, headers, index }">
              <tr
                class="cursor-pointer"
                @click.stop="onSelect({ item })"
                :class="selected.guid == item.guid && 'tr-active'"
              >
                <template v-for="head in headers">
                  <td
                    v-if="head.value == 'number'"
                    :key="head.value"
                    class="px-0 text-center"
                  >
                    <span
                      class="text-nowrap"
                      v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
                    />
                  </td>

                  <td
                    v-else-if="head.value == 'productName'"
                    :key="head.value + 1"
                  >
                    <table-focus-input
                      :ref="item.id"
                      :tabindex="index + 1"
                      :item="item"
                      :uniKey="cacheKey"
                      @upKey="onUpKey({ item })"
                      @downKey="onDownKey({ item })"
                      @pageUp="onPageUp({ item })"
                      @pageDown="onPageDown({ item })"
                    />
                    <div
                      :style="{ minWidth: head.minWidth }"
                      v-text="item[head.value]"
                    />
                  </td>

                  <td
                    @click.exact="onSetSelected({ item })"
                    @click.shift="onClickWithShift({ item })"
                    v-else-if="head.value == '_checkbox'"
                    :key="head.value + 2"
                    :style="{ width: head.width, maxWidth: head.width }"
                  >
                    <div class="d-flex justify-center align-center">
                      <v-checkbox
                        color="primary"
                        v-model="idsObject[item.guid]"
                        readonly
                        hide-details
                        class="mt-0 pt-0"
                      />
                    </div>
                  </td>

                  <td
                    v-else-if="head._filter"
                    :key="head.value + 3"
                  >
                    <div
                      class="text-nowrap"
                      v-html="head._filter(item[head.value], item)"
                      :style="{
                        textAlign: head._align,
                        minWidth: head.minWidth,
                      }"
                    />
                  </td>

                  <td
                    v-else-if="head.value == 'actions'"
                    :key="head.value + 4"
                    class="px-0"
                  >
                    <div class="d-flex justify-center tb-action-wrap">
                      <v-btn
                        :title="tn('table.actions.edit')"
                        @click="onEdit({ item })"
                        depressed
                        min-width="36"
                        width="36"
                        min-height="36"
                        height="36"
                        color="transparent"
                        :disabled="!item.guid"
                      >
                        <v-icon color="secondary">mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                  </td>

                  <td
                    v-else
                    :key="head.value + 5"
                  >
                    <div v-text="item[head.value]" />
                  </td>
                </template>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
