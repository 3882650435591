import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = 'PriceListViewerBasketSharingContact'

export const state = () => ({
  itemsData: {},
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    };
  },
};

export const actions = {
  async GET_API({ commit }, guid) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();
    commit("SET_ITEMS");

    const res = await vm.$axios.$get(`${url}/${guid}`);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  async ADD_API(ctx, params) {
    const res = await vm.$axios.$post(url, params);
    return res?.succeeded
  },

  async GET_CHECK_API(ctx, payload) {
    const res = await vm.$axios.$get(`${url}/Check?Phone=${payload}`);
    return res?.succeeded
  },

  async SEND_SMS_API(ctx, params) {
    const res = await vm.$axios.$post(`${url}/SendSms`, params);
    return res?.succeeded
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
};
