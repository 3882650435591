import store from "../store";
export default async (to, from, next) => {
  if (to.path == "/") return next(true);
  if (to?.meta?.auth) {
    const isUser = await initUser();
    if (isUser) return next(true);
  }

  sessionStorage.setItem("auth-return-url", to.path)
  next("/?logout=1&");
};

async function initUser() {
  let isUser = !!store.state.auth.user.exist;
  if (isUser) return true;
  store.dispatch("SET_INIT", true);
  await store.dispatch("auth/INIT_USER");
  isUser = !!store.state.auth.user.exist;
  if (isUser) await store.dispatch("INIT_STATES");
  store.dispatch("SET_INIT", false);
  return isUser;
}
