import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = "PriceListViewerOrder";

export const state = () => ({
  itemsData: {},
  orderData: {},
});

export const mutations = {
  SET_ORDER_LIST(state, payload) {
    state.itemsData = {
      items: vm.$array(
        payload?.items.map((x, i) => {
          x._id = i + 1;
          return x;
        })
      ),
      total: vm.$number(payload?.totalCount),
      totals: {
        count: vm.$number(payload?.totalCount),
        totalAmountOrdered: vm.$number(payload?.totalAmountOrdered),
        totalAmountAccepted: vm.$number(payload?.totalAmountAccepted),
      },
    };
  },

  SET_ORDER_BY_GUID(state, payload) {
    state.orderData = payload || {};
  },
};

export const actions = {
  async GET_API({ commit }, { options, filterData = {} }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_ORDER_LIST");

    const params1 = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      sortBy: options.sortBy,
      sortDirection: options.sortDesc,
      search: options.search,
      status: options.status,
      ...filterData,
    };

    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$get(`${url}/List`, params, request);
    if (res?.succeeded) commit("SET_ORDER_LIST", res?.data);
  },

  async CANCEL_API(ctx, payload) {
    const res = await vm.$axios.$post(`${url}/${payload?.guid}/cancel`, payload);
    return res;
  },

  async PRICE_LIST_VIEWER_ORDER_BY_ID({ commit }, { guid }) {
    commit("SET_ORDER_BY_GUID");
    const res = await vm.$axios.$get(`${url}/${guid}`);
    if (res?.succeeded) commit("SET_ORDER_BY_GUID", res?.data);
  },
};

export const getters = {
  GET_BY_GUID: (state) => state.orderData,
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
  GET_TOTALS: (state) => state.itemsData.totals || {},
};
