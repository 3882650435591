import i18n from "@/i18n";

import Vue from "vue";
const vm = Vue.prototype;
const rules = {
  required: (v) => !!v || v == 0 || "",
  array: (v) => !!vm.$array(v).length,
  non_zero: (v) => v > 0 || "",
  phone: (v) => `${v || ""}`.length == 9 || "",
  number4Length: (v) => `${v || ""}`.length == 4 || "",
  minSize: (length) => (v) =>
    `${v || ""}`.length >= length ||
    i18n.t("min_length_input_error", { 0: length }),
};

Vue.prototype.$rules = rules;
