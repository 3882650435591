import { render, staticRenderFns } from "./selection-auto-join.vue?vue&type=template&id=071341f1&scoped=true"
import script from "./selection-auto-join.vue?vue&type=script&lang=js"
export * from "./selection-auto-join.vue?vue&type=script&lang=js"
import style0 from "./selection-auto-join.vue?vue&type=style&index=0&id=071341f1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "071341f1",
  null
  
)

export default component.exports