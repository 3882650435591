<script>
export default {
  props: {
    options: Object,
    lastPage: Number,
    perPageInput: [String, Number],
    pageInput: [String, Number],
    entriesCount: Number,
    onLastPage: Function,
    onChangePerPageInput: Function,
    onChangePageInput: Function,
    loading: Boolean,
  },

  data: () => ({
    //
  }),

  computed: {
    paginationInfo() {
      const page = this.options.page - 1;
      const perPage = this.options.itemsPerPage;
      const txt1 = page * perPage + 1;
      const _txt2 = page * perPage + perPage;
      const txt2 = _txt2 > this.entriesCount ? this.entriesCount : _txt2;

      return `${this.$sum(txt1)} - ${this.$sum(txt2)}`;
    },
  },
};
</script>

<template>
  <div class="d-flex align-center justify-end px-2 table-pager">
    <div
      class="per-page-text"
      v-text="$t('table.per_page_text')"
    />

    <div
      class="pl-1"
      style="max-width: 78px; max-height: 28px"
    >
      <v-select
        :disabled="loading"
        :value="perPageInput"
        @change="onChangePerPageInput"
        class="fs-12"
        :items="[25, 50]"
        flat
        outlined
        hide-details
      />
    </div>

    <div class="fs-12 px-3">
      {{ paginationInfo }}
      из
      {{ entriesCount | sum }}
    </div>

    <v-btn
      :loading="loading"
      :disabled="options.page <= 1"
      @click="onChangePageInput(null, 1)"
      width="24"
      max-width="24"
      height="24"
      max-height="24"
      icon
    >
      <v-icon size="24"> mdi-page-first </v-icon>
    </v-btn>

    <v-btn
      :loading="loading"
      :disabled="options.page <= 1"
      @click="onChangePageInput(null, options.page - 1)"
      width="24"
      max-width="24"
      height="24"
      max-height="24"
      icon
    >
      <v-icon size="24"> mdi-chevron-left </v-icon>
    </v-btn>

    <div>
      <input
        :disabled="loading"
        @focus="$event.target.select()"
        :value="pageInput"
        @blur="onChangePageInput"
        @keyup.enter="onChangePageInput"
        class="fs-12 footer-input"
        v-inputmask="$masks.number_only"
      />
    </div>

    <v-btn
      :loading="loading"
      :disabled="options.page >= lastPage"
      @click="onChangePageInput(null, options.page + 1)"
      width="24"
      max-width="24"
      height="24"
      max-height="24"
      icon
    >
      <v-icon size="24"> mdi-chevron-right </v-icon>
    </v-btn>

    <v-btn
      :loading="loading"
      :disabled="options.page >= lastPage"
      @click="onLastPage"
      width="24"
      max-width="24"
      height="24"
      max-height="24"
      icon
    >
      <v-icon size="24"> mdi-page-last </v-icon>
    </v-btn>
  </div>
</template>
