<script>
import { mapActions, mapGetters } from "vuex";
import tnVue from "@/mixins/tn.vue";
import debounce from "debounce";

import TableCog from "@/components/table-cog.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TablePagination from "@/mixins/table-pagination.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import HistoryOfChange from "@/components/modals/price-list-viewer-order/history-of-change.vue";
import CancelModal from "@/components/modals/price-list-viewer-order/cancel-modal.vue";

import { getCurrentDate } from "@/filters/date";

const currentDate = getCurrentDate();
const datePlus1Month = getCurrentDate(null, 1);
const datePlus3Month = getCurrentDate(null, 3);
const datePlus6Month = getCurrentDate(null, 6);

export default {
  mixins: [tnVue, TableNavFunctionsVue, TablePagination, TableResizeVue],
  components: { TableCog, HistoryOfChange, CancelModal },

  data: (vm) => ({
    ln: "price_list_viewer_order_view",
    uniqKey: "price_list_viewer_order_view",
    isInit: false,
    filterData: vm.$getFilterData() || {},
    options: vm.$getSortLocal({ key: "price-list-viewer-order-view" }),
    isHistoryOfChange: false,
    isCancel: false,
  }),

  async created() {
    this.$store.dispatch("SET_LOADING", true);
    await this.getPriceListViewerOrderId({ guid: this.priceListViewerOrderId });
    this.$store.dispatch("SET_LOADING", false);
    this.isInit = true;
  },

  watch: {
    searchText() {
      this.onSearch();
    },
  },

  computed: {
    ...mapGetters("price-list-viewer-order-view", ["GET", "GET_COUNT", "GET_TOTALS"]),
    ...mapGetters("price-list-viewer-order", ["GET_BY_GUID"]),

    itemsData: (vm) => vm.GET_BY_GUID,

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,
    entriesTotals: (vm) => vm.GET_TOTALS,

    tableHeight: (vm) => vm.size.height - 222,
    priceListViewerOrderId: (vm) => vm.$route.params.priceListViewerOrderId,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "_number",
          sortable: false,
          _required: true,
          _resize: false,
          width: "30px",
        },
        {
          text: vm.tn("table.name"),
          value: "productName",
          width: "650px",
          minWidth: "180px",
          _required: true,
        },
        {
          text: vm.tn("table.manufacturer"),
          value: "manufacturerName",
          width: "300px",
          _required: true,
        },
        {
          text: vm.tn("table.order"),
          value: "quantityOrdered",
          _filter: (v) => vm.$sum(v),
          _align: "right",
          width: "120px",
        },
        {
          text: vm.tn("table.confirmation"),
          value: "quantityAccepted",
          width: "120px",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.quantityOrdered;
            if (v && mxp && v > mxp) return "red";
          },
          _align: "right",
        },
        {
          text: vm.tn("table.shelf_life"),
          value: "shelfLife",
          width: "98px",
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.order_amount"),
          value: "totalAmountOrdered",
          width: "120px",
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.amount_confirmation"),
          value: "totalAmountAccepted",
          width: "160px",
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: "",
          value: "actions",
          width: "100%",
          maxWidth: "450px",
          sortable: false,
          class: "disable-resize",
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),

    onStatusText: (vm) => {
      switch (vm.itemsData.status) {
        case 1:
          return vm.tn("statuses.sent_title");
        case 3:
          return vm.tn("statuses.accepted_title");
        case 200:
          return vm.tn("statuses.cancelled_title");
        case 100:
          return vm.tn("statuses.completed_title");
        default:
          return "";
      }
    },
  },

  methods: {
    ...mapActions("price-list-viewer-order-view", {
      getEntriesApi: "GET_API",
    }),

    ...mapActions("price-list-viewer-order", {
      getPriceListViewerOrderId: "PRICE_LIST_VIEWER_ORDER_BY_ID",
    }),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.$setSortLocal({
        key: "price-list-viewer-order-view",
        options: this.options,
      });

      const params = { options: { ...this.options } };

      params.options.sortBy = this.$getSort(params.options.sortBy);
      params.options.sortDesc = this.$getSort(params.options.sortDesc, true);
      params.options.priceListViewerOrderId = this.priceListViewerOrderId;

      if (!params.options.sortBy) delete params.options.sortDesc;
      if (this.searchText) params.options.search = this.searchText;

      await this.getEntriesApi(params);

      if (document.activeElement?.id != this.ln) {
        this.setFocusToItem();
      }

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.cacheKey);
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onKeyup(e) {
      if (e.keyCode == 113) {
        this.$refs.searchBox?.focus();
        this.$nextTick(() => {
          const input = this.$refs.searchBox?.$el?.querySelector("input");
          input?.select();
        });
      }
    },

    async onExportExcel() {
      this.$store.dispatch("SET_LOADING", true);
      const url = `PriceListViewerOrder/${this.priceListViewerOrderId}/Export`;
      await this.$axios.$postResFile(url);
      this.$store.dispatch("SET_LOADING", false);
    },

    getShelfStyle(item) {
      const style = {};
      if (item.shelfLife == "0001-01-01T00:00:00") return style;
      else if (item.shelfLife <= currentDate) style.backgroundColor = "#ff958f";
      else if (item.shelfLife <= datePlus1Month) style.backgroundColor = "#ffced6";
      else if (item.shelfLife <= datePlus3Month) style.backgroundColor = "#ffbd45";
      else if (item.shelfLife <= datePlus6Month) style.backgroundColor = "yellow";
      return style;
    },

    onExit() {
      if (!this.$router.back()) this.$router.replace("/price-list-viewer-order");
    },

    onRefresh() {
      this.getEntries();
      this.getPriceListViewerOrderId({ guid: this.priceListViewerOrderId });
    },

    onKeyupDown() {
      this.setFocusToItem();
    },
  },
};
</script>

<template>
  <v-container
    v-if="isInit"
    fluid
  >
    <HistoryOfChange
      @close="isHistoryOfChange = false"
      v-if="isHistoryOfChange"
      :guid="$route.params.priceListViewerOrderId"
      :ln="ln"
    />

    <CancelModal
      @close="isCancel = false"
      v-if="isCancel"
      :ln="ln"
      @saved="onRefresh"
    />

    <div class="d-flex align-center pt-2">
      <v-btn
        replace
        @click="onExit"
        fab
        elevation="0"
        small
        class="mr-3"
      >
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>
      <div>
        <h3 v-text="itemsData.providerName" />
        <small>
          <b>{{ tn("title") + "№ " }}</b>
          {{ itemsData.number }}
        </small>
      </div>

      <v-spacer />

      <v-btn
        v-if="itemsData.status == 1"
        color="#DA3C3C"
        @click="isCancel = true"
        dark
        elevation="0"
        outlined
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('cancel_btn')"
      >
        {{ tn("cancel_btn") }}
      </v-btn>
    </div>

    <div class="d-flex align-center pt-2">
      <h3> {{ onStatusText }} </h3>

      <v-spacer />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
        class="mr-2"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <table-cog
        :headersData="tableHeaders"
        @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
        :cacheKey="'price-list-viewer-order-table-cog'"
      />

      <div class="px-1" />

      <v-btn
        class="transform-none"
        height="40"
        min-width="40"
        width="40"
        depressed
        color="primary"
        outlined
        @click="onExportExcel"
        :title="tn('export_excel_btn')"
      >
        <v-icon> mdi-file-excel-outline </v-icon>
      </v-btn>

      <div class="px-1" />

      <v-btn
        class="transform-none"
        height="40"
        min-width="40"
        width="40"
        depressed
        color="primary"
        outlined
        @click="isHistoryOfChange = true"
        :title="tn('history_of_status_changes_btn')"
      >
        <v-icon> mdi-history </v-icon>
      </v-btn>

      <div class="mx-1" />

      <v-text-field
        :id="ln"
        ref="searchBox"
        v-model="searchText"
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        clearable
        style="max-width: 300px; width: 100%"
        :label="tn('search')"
        @click:append="onSearch"
        @keyup.enter="onKeyupDown({ enter: true })"
        @keyup.down="onKeyupDown"
        @keyup.up="onKeyupDown"
      />
    </div>

    <div class="mt-3" />

    <v-data-table
      v-if="filteredHeaders.length && tableHeight > 0"
      :headers="filteredHeaders"
      dense
      fixed-header
      disable-pagination
      hide-default-footer
      class="fixed-right"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="entriesCount"
      :height="tableHeight"
      v-resize-table="{
        tableHeaders: filteredHeaders,
        callback: onUpdateTdSizes,
      }"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr
          class="cursor-pointer"
          @click.stop="onSelect({ item })"
          :class="selected.guid == item.guid && 'tr-active'"
        >
          <template v-for="head in headers">
            <td
              v-if="head.value == '_number'"
              :key="head.value"
              class="px-0 text-center"
            >
              <span
                class="text-nowrap"
                v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
              />
            </td>

            <td
              v-else-if="head.value == 'shelfLife'"
              :key="head.value + 1"
              :style="{
                width: tdSizes[head.value] || head.width,
                maxWidth: tdSizes[head.value] || head.width,
                ...getShelfStyle(item),
              }"
            >
              <div
                class="text-nowrap"
                v-html="head._filter(item[head.value], item)"
                :style="{
                  textAlign: head._align,
                  minWidth: head.minWidth,
                  color: head._getColor?.(item[head.value], item),
                }"
              />
            </td>

            <td
              v-else
              :key="head.value + 3"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div
                :style="{ minWidth: head.minWidth, textAlign: head._align, ...head._getStyle?.(item[head.value], item) }"
                v-html="head._filter ? head._filter(item[head.value], item) : item[head.value]"
                :class="{ 'text-nowrap': head._wrap }"
              />
            </td>
          </template>
        </tr>
      </template>

      <template
        v-if="entriesCount > 1"
        #body.append="bodyProps"
      >
        <tr class="sticky-table-footer">
          <td
            v-for="(header, i) in bodyProps.headers"
            :key="i"
          >
            <div
              v-if="header.value == '_number'"
              class="text-nowrap text-center"
              v-text="$sum(entriesTotals.count)"
            />
            <div
              v-if="header.value"
              class="text-nowrap text-right"
              v-text="$sum(entriesTotals[header.value], true)"
            />
          </td>
        </tr>
      </template>

      <template #footer>
        <TablePaginationButtons
          :loading="isLoading"
          :options="options"
          :lastPage="lastPage"
          :perPageInput="perPageInput"
          :pageInput="pageInput"
          :entriesCount="entriesCount"
          :onLastPage="onLastPage"
          :onChangePerPageInput="onChangePerPageInput"
          :onChangePageInput="onChangePageInput"
        />
      </template>
    </v-data-table>
  </v-container>
</template>
