<script>
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";

import FooterCopyright from "@/components/footer-copyright.vue";
import Card from "./components/card.vue";
import Contacts from "@/views/auth/components/contacts.vue";

export default {
  components: { Contacts, FooterCopyright, Card },

  data: (vm) => ({
    ln: "statistic",
    locale: vm.$i18n.locale,
  }),

  mixins: [tnVue],

  computed: {
    ...mapGetters("statistic", ["GET"]),
    items: (vm) => vm.GET,

    carouselItems: (vm) => [
      {
        img: `advertising-space-${vm.locale}`,
      },
    ],
  },

  async created() {
    this.$store.dispatch("SET_LOADING", true);
    await this.GET_API({ options: { itemsPerPage: 50, page: 1 } });
    this.$store.dispatch("SET_LOADING", false);
  },

  methods: {
    ...mapActions("statistic", ["GET_API"]),
  },
};
</script>

<template>
  <v-container style="max-width: 992px">
    <div
      class="py-4"
      style="margin: 20px;"
    >
      <v-carousel
        hide-delimiters
        show-arrows-on-hover
        :show-arrows="carouselItems.length >= 2"
        :interval="3000"
        :height="270"
      >
        <v-carousel-item
          v-for="(item, i) in carouselItems"
          :key="i"
          :src="require(`@/assets/news/${item.img}.png`)"
        />
      </v-carousel>
    </div>
    <v-row>
      <v-col
        v-for="item in items"
        :key="item.guid"
        cols="12"
        md="6"
      >
        <Card
          :url="item.url"
          :background="item.color"
          :icon="item.icon"
          :count="$sum(item.count)"
          :title="item.name"
        />
      </v-col>
    </v-row>

    <div class="py-10" />

    <Contacts />

    <div class="mt-10" />

    <FooterCopyright class="footer-copyright" />
  </v-container>
</template>

<style lang="scss" scoped>
.footer-copyright {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
