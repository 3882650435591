import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

export const state = () => ({
  priceList: {},
  priceListData: {},
});

export const mutations = {
  SET_PRICE_LIST(state, payload) {
    state.priceListData = {
      items: vm.$array(payload?.items.map((x) => {
        x._discountColor = x.discountPercent || x.discountMinAmountToPurchase || x.discountMinAmountToPurchaseWithDelivery;
        return x;
      })),
      total: vm.$number(payload?.totalCount),
    };
  },

  SET_BY_GUID(state, payload) {
    state.priceList = payload || {};
  },

  REPLACE_PRICE_LIST(state, payload) {
    state.priceListData.items = state.priceListData.items.map((x) =>
      x.providerGuid == payload.providerGuid ? payload : x
    );
  },
};

export const actions = {
  async GET_API({ commit }, { options, filterData = {} }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_PRICE_LIST");
    const params1 = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      sortBy: options.sortBy,
      sortDirection: options.sortDesc,
      search: options.search,
      ...filterData,
    };

    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$post("PriceList/List", params, request);
    if (res?.succeeded) commit("SET_PRICE_LIST", res?.data);
  },

  async GET_BY_GUID_API({ commit }, { guid }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();
    commit("SET_BY_GUID");

    const res = await vm.$axios.$get("PriceList/" + guid);
    if (res?.succeeded) commit("SET_BY_GUID", res?.data);
  },

  async FAVORITE_API({ commit }, payload) {
    let status = false;
    const url = `priceListViewerFavoriteProvider`;
    payload.isFavorite = !payload.isFavorite;
    const params = { priceListProviderGuid: payload?.providerGuid }

    if (payload.isFavorite) status = (await vm.$axios.$post(url, params))?.succeeded;
    else status = (await vm.$axios.$delete(`${url}/${payload?.providerGuid}`))?.succeeded;

    if (status) commit("REPLACE_PRICE_LIST", payload);
  },

  async BLACK_LIST_API({ commit }, payload) {
    let status = false;
    const url = `PriceListViewerBlackListProvider`;
    payload.isBlackList = !payload.isBlackList;

    if (payload.isBlackList) status = (await vm.$axios.$post(`${url}/${payload?.providerGuid}`))?.succeeded;
    else status = (await vm.$axios.$delete(`${url}/${payload?.providerGuid}`))?.succeeded;

    if (status) commit("REPLACE_PRICE_LIST", payload);
  },
};

export const getters = {
  GET_BY_GUID: (state) => state.priceList,
  GET: (state) => state.priceListData.items,
  GET_COUNT: (state) => state.priceListData.total,
};
