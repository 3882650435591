<script>
import ModalsTemplate from "@/components/modals-template.vue";

export default {
  components: { ModalsTemplate },

  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },

    tn: Function,
  },

  data: () => ({
    dialog: true,

    entries: [],
    selectedValues: [],

    toggle_exclusive: 2,

    dragRow: null,
    dragIndex: null,
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  computed: {
    tableHeaders: (vm) => [
      {
        text: "",
        value: "selectBox",
        width: 10,
      },
      {
        text: vm.tn("table.name"),
        value: "text",
      },
      {
        text: "",
        value: "actions",
        width: 30,
        sortable: false,
      },
    ],

    isSelectAll: {
      get: (vm) => vm.entries.length <= vm.selectedValues.length,
      set(v) {
        let values = this.entries;
        if (!v) values = values.filter((x) => x._required);
        this.selectedValues = values.map((x) => x.value);
      },
    },
  },

  created() {
    this.entries = [...this.items];
    this.selectedValues = this.selectedItems.map((x) => x.value);
  },

  methods: {
    onOrder({ status, item, index }) {
      let entries = this.entries.filter((x) => x.value != item.value);
      let toIndex = status == "up" ? index - 1 : index + 1;
      if (toIndex > -1) entries.splice(toIndex, 0, item);
      this.entries = entries.map((x, index) => {
        x._order = index;
        return x;
      });
    },

    onApply() {
      const selected = this.selectedValues;
      const values = this.entries.map((item) => {
        item._hide = !selected.find((value) => value == item.value);
        return item;
      });

      this.$emit("apply", values);
      this.dialog = false;
    },

    onReset() {
      this.$emit("reset");
      this.dialog = false;
    },

    isPrevRequired(index) {
      return this.entries[index - 1]?._required;
    },

    onDragover(e, { index }) {
      e?.preventDefault();
      if (!this.dragRow) return;
      this.dragIndex = index;
    },

    onDragEnd() {
      const item = this.dragRow;
      const index = this.dragIndex || 0;
      let entries = this.entries.filter((x) => x.value != item.value);
      entries.splice(index, 0, item);
      this.dragRow = null;
      this.dragIndex = null;
      this.entries = entries.map((x, i) => {
        x._order = i;
        return x;
      });
    },

    isDraGable(item) {
      return this.dragRow?.value == item.value;
    },
  },
};
</script>

<template>
  <modals-template
    :width="500"
    v-model="dialog"
    :title="tn('title')"
    :persistent="false"
  >
    <v-data-table
      dense
      :headers="tableHeaders"
      :mobile-breakpoint="0"
      :items="entries"
      fixed-header
      disable-pagination
      hide-default-footer
      disable-sort
      key="value"
    >
      <template v-slot:header.selectBox>
        <div class="tb-action-wrap checkbox-wrap">
          <v-checkbox
            hide-details
            class="mt-0"
            v-model="isSelectAll"
            :indeterminate="!!selectedValues.length && !isSelectAll"
          />
        </div>
      </template>
      <template v-slot:item="{ item, headers, index }">
        <tr
          :draggable="!!dragRow"
          @dragover="onDragover($event, { index })"
          :class="{
            'tr-active': $hasProp(dragRow, 'value') == item.value,
            'drag-shadow': dragIndex == index,
          }"
          @dragend="onDragEnd"
        >
          <template v-for="head in headers">
            <td
              v-if="head.value == 'selectBox'"
              :key="head.value"
              class="px-0 text-center"
            >
              <div class="tb-action-wrap checkbox-wrap">
                <v-checkbox
                  v-model="selectedValues"
                  :value="item.value"
                  :key="item.value"
                  hide-details
                  class="mt-0 ml-2"
                  :disabled="item._required"
                />
              </div>
            </td>
            <td
              v-else-if="head.value == 'actions'"
              :key="head.value"
              class="px-0 text-center"
            >
              <div class="d-flex align-center">
                <div class="order-btn-wrap">
                  <button
                    @click="onOrder({ status: 'up', item, index })"
                    :disabled="index == 0"
                    v-ripple
                    class="order-btn"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </button>
                  <button
                    @click="onOrder({ status: 'down', item, index })"
                    :disabled="index == entries.length - 1"
                    v-ripple
                    class="order-btn"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </button>
                </div>
                <div
                  @mousedown="dragRow = item"
                  :key="item.value + 'move'"
                  class="cursor-grab"
                >
                  <v-btn
                    icon
                    class="cursor-grab"
                  >
                    <v-icon> mdi-cursor-move </v-icon>
                  </v-btn>
                </div>
              </div>
            </td>

            <td
              v-else-if="head._filter"
              :key="head.value + 1"
            >
              <div
                class="text-nowrap"
                v-if="head._html"
                v-html="head._filter(item[head.value], item)"
              />
              <div
                v-else
                class="text-nowrap"
                v-text="head._filter(item[head.value], item)"
                :style="{ textAlign: head._align }"
              />
            </td>

            <td
              v-else
              :key="head.value + 4"
            >
              <div
                :style="{ minWidth: head.minWidth }"
                v-text="item[head.value]"
              />
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>

    <div class="d-flex align-center justify-center">
      <v-btn
        height="40"
        color="error"
        @click="onReset"
        elevation="0"
        class="mt-5 mb-1 w-49"
      >
        {{ tn("reset_btn") }}
      </v-btn>
      <div class="px-2" />
      <v-btn
        height="40"
        color="primary"
        @click="onApply"
        elevation="0"
        class="mt-5 mb-1 w-49"
      >
        {{ tn("apply_btn") }}
      </v-btn>
    </div>
  </modals-template>
</template>

<style lang="scss" scoped>
.order-btn-wrap {
  .order-btn {
    width: 30px;
    height: 20px;
    overflow: hidden;
    cursor: pointer;
    border-right: 1px solid rgba(0, 0, 0, 0.12);

    &:first-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }
}

.checkbox-wrap {
  width: 24px !important;
}
</style>
