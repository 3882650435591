import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = "PriceListViewerOrderStatusHistory";

export const state = () => ({
  itemsData: {},
});

export const mutations = {
  SET_STATUS_HISTORY_LIST(state, payload) {
    state.itemsData = {
      items: vm.$array(
        payload?.items.map((x, i) => {
          x._id = i + 1;
          return x;
        })
      ),
      total: vm.$number(payload?.totalCount),
    };
  },
};

export const actions = {
  async GET_API({ commit }, { options, }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_STATUS_HISTORY_LIST");

    const params = { ...options };
    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$get(`${url}/List`, params, request);
    if (res?.succeeded) commit("SET_STATUS_HISTORY_LIST", res?.data);
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
};
