<script>
export default {
  props: {
    background: String,
    icon: String,
    title: String,
    count: [String, Number],
    url: String,
  },

  data: () => ({
    navbar: true,
  }),

  methods: {
    onOpen() {
      if (this.url) window.open(this.url, "_blank");
    },
  },
};
</script>

<template>
  <div
    @click="onOpen"
    :class="url && 'cursor-pointer'"
    class="card-wrap"
  >
    <div
      class="card"
      :style="{ background }"
    >
      <div class="card-content">
        <div>
          <h1>{{ count }}</h1>
          <div class="pt-1" />
          <b> {{ title }} </b>
        </div>
        <div>
          <v-icon
            :size="100"
            dark
          >
            {{ icon }}
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-wrap {
  display: flex;
  justify-content: center;
  color: white;
  padding: 12px 0;

  .card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 430px;
    flex: 1;

    .card-content {
      padding: 20px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
