<script>
import { mapActions } from "vuex";

export default {
  props: { item: Object },

  data: (vm) => ({
    isLoading: false,
    formData: { ...vm.item },
  }),

  methods: {
    ...mapActions("provider-permission", { hideApi: "ADD_API" }),

    async onChange() {
      this.isLoading = true;
      const params = { ...this.formData };
      delete params.createdOn;
      delete params.guid;
      delete params.modifiedOn;

      await this.hideApi(params);
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <v-switch
    :readonly="isLoading"
    hide-details
    dense
    class="ma-0 my-switch"
    :loading="isLoading"
    v-model="formData.isAllowed"
    @change="onChange"
    inset
  />
</template>
