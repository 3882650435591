export default [
  {
    name: "Русский язык",
    value: "ru",
  },
  {
    name: "Ўзбек тили",
    value: "uz",
  },
  {
    name: "O‘zbek tili",
    value: "uz-lat",
  },
];
