<script>
import tnVue from "@/mixins/tn.vue";

export default {
  data: () => ({
    ln: "contacts",
  }),

  mixins: [tnVue],
};
</script>

<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <h1>{{ tn("title") }}</h1>
      <div class="d-flex align-center mt-8">
        <img
          src="@/assets/icons/call.svg"
          alt=""
        />
        <div class="px-3" />
        <a
          class="info-text"
          href="tel:712036766"
        >
          (71) 203
          <span class="primary--text"> OSON </span>
          (6766)
        </a>
      </div>
      <div class="d-flex align-center mt-8">
        <img
          src="@/assets/icons/call.svg"
          alt=""
        />
        <div class="px-3" />
        <a
          class="info-text"
          href="tel:944846766"
        >
          (94) 484 67 66 - Faxriddin (24/7)
        </a>
      </div>
      <div class="d-flex align-center mt-8">
        <img
          src="@/assets/icons/mail.svg"
          alt=""
        />
        <div class="px-3" />
        <a
          class="info-text"
          href="mailto:info@osonapteka.uz"
        >
          info@osonapteka.uz
        </a>
      </div>
      <div class="d-flex align-center mt-8">
        <img
          src="@/assets/icons/location.svg"
          alt=""
        />
        <div class="px-3" />
        <a
          href="https://www.google.com/maps/place/Oson+Apteka/@41.3267192,69.2601417,15z/data=!4m2!3m1!1s0x0:0x5487db9229bf9bdd"
          target="_blank"
          class="info-text"
        >
          {{ tn("address") }}
        </a>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      order-md="first"
    >
      <div class="pt-md-16" />

      <a
        href="https://www.google.com/maps/place/Oson+Apteka/@41.3267192,69.2601417,15z/data=!4m2!3m1!1s0x0:0x5487db9229bf9bdd"
        target="_blank"
      >
        <img
          src="@/assets/img/map.png"
          alt=""
        />
      </a>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.info-text {
  color: inherit;
  font-size: 16px;
  font-weight: 500;
}

a:hover {
  text-decoration: underline;
}
</style>
