<script>
import tnVue from "@/mixins/tn.vue";
import { mapActions, mapGetters } from "vuex";
import ModalsTemplate from "@/components/modals-template.vue";
import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import AddShareContact from "./add-share-contact.vue";
export default {
  mixins: [tnVue],

  components: { ModalsTemplate, ConfirmDialog, AddShareContact },

  props: {
    value: Object,
    ln: String,
  },

  data: () => ({
    dialog: true,
    options: {},

    loaders: {},
    isInit: false,
    isAdd: false,
    isShowDelete: false,
  }),

  created() {
    this.isInit = true;
  },

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },

    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("share-contact", {
      entries: "GET",
      entriesCount: "GET_COUNT",
    }),

    size: (vm) => vm.$store.getters.GET_SIZE,
    tableHeight: (vm) => vm.size.height - 550,

    tableHeaders: (vm) => [
      {
        text: "№",
        value: "number",
        width: 10,
        sortable: false,
        class: "disable-resize",
      },
      {
        text: vm.tn("table.headers.status"),
        value: "status",
        _align: "center",
        sortable: false,
        width: 10,
      },
      {
        text: vm.tn("table.headers.phones"),
        value: "phone",
        sortable: false,
        _filter: (v) => vm.$tel(v),
      },
      {
        text: "",
        value: "_actions",
        sortable: false,
        width: 10,
      },
    ],

    isLoading: (vm) => !!Object.keys(vm.loaders).length,
  },

  methods: {
    ...mapActions("share-contact", {
      getEntriesApi: "GET_API",
      deleteApi: "ADD_API",
      sendSmsApi: "SEND_SMS_API",
      sendWithBotApi: "SEND_WITH_BOT_API",
    }),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      await this.getEntriesApi(this.value?.priceListProviderGuid);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    async onShare() {
      this.$store.dispatch("SET_LOADING", true);

      const url = `Basket/${this.value?.guid}/ShareWithBot`;
      const res = await this.$axios.$post(url);
      if (res?.succeeded) {
        if (res.data == 1) {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.$t("success"),
            color: "success",
          });
          this.dialog = false;
        } else if (res.data == 0) {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.tn("first_link_bot_account_info"),
            color: "info",
          });
          this.$router.push("/telegram-bot-connect");
        } else if (res.data == -1) {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.tn("error_sending_bot_file_info"),
            color: "info",
          });
        }
      }

      this.$store.dispatch("SET_LOADING", false);
    },

    async onSendSms({ item }) {
      this.$store.dispatch("SET_LOADING2", true);
      const params = { phone: item?.phone };
      const status = await this.sendSmsApi(params);
      if (status) {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("message_sent_alert"),
          color: "success",
        });
      }
      this.$store.dispatch("SET_LOADING2", false);
    },

    onAdd() {
      this.selected = {};
      this.$nextTick(() => (this.isAdd = true));
    },

    onEdit({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isAdd = true));
    },

    onShowDelete({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowDelete = true));
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);

      const contactList = this.entries.reduce((acc, entry) => {
        if (entry.phone != this.selected.phone) acc.push(entry.phone);
        return acc;
      }, []);

      const params = {
        providerGuid: this.value?.priceListProviderGuid,
        contactList: contactList,
      };
      await this.deleteApi(params);
      this.getEntries();
      setLoading(false);
      close();
    },
  },
};
</script>

<template>
  <modals-template
    v-if="isInit"
    :width="500"
    :title="tn('title')"
    v-model="dialog"
  >
    <ConfirmDialog
      :text="tn('delete_alert')"
      @close="isShowDelete = false"
      v-if="isShowDelete"
      @accept="onDelete"
    />

    <AddShareContact
      v-if="isAdd"
      :ln="`${ln}.add`"
      :value="selected"
      :contactList="entries"
      :priceListProviderGuid="value?.priceListProviderGuid"
      @close="isAdd = false"
      @saved="getEntries"
    />

    <div class="d-flex pb-3">
      <v-spacer />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="onAdd"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('add_btn')"
        class="mr-2"
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>
    </div>

    <v-data-table
      v-if="tableHeight > 0"
      :headers="tableHeaders"
      hide-default-footer
      dense
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :height="tableHeight"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr class="cursor-pointer">
          <template v-for="head in headers">
            <td
              v-if="head.value == 'number'"
              :key="head.value"
              class="px-0 text-center"
            >
              <span
                class="text-nowrap"
                v-text="index + 1"
              />
            </td>

            <td
              v-else-if="head.value == 'status'"
              :key="head.value + 2"
              class="px-0 text-center"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="item.isOsonPriceBotUser ? 'primary' : 'error'"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.isOsonPriceBotUser ? "mdi-account-multiple-check" : "mdi-account-multiple-remove" }}
                  </v-icon>
                </template>
                <span> {{ item.isOsonPriceBotUser ? tn("registered_from_oson_price_bot") : tn("not_registered_from_oson_price_bot") }}</span>
              </v-tooltip>
            </td>

            <td
              v-else-if="head.value == '_actions'"
              :key="head.value + 6"
              class="pa-0 text-center"
            >
              <div class="d-flex align-center justify-end">
                <v-btn
                  v-if="!item.isOsonPriceBotUser"
                  :title="tn('table.actions.send_sms')"
                  @click="onSendSms({ item })"
                  icon
                  color="primary"
                  :disabled="!item.guid"
                >
                  <v-icon>mdi-send</v-icon>
                </v-btn>

                <v-btn
                  :title="tn('table.actions.edit')"
                  @click="onEdit({ item })"
                  icon
                  color="primary"
                  :disabled="!item.guid"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-btn
                  :title="tn('table.actions.delete')"
                  @click="onShowDelete({ item })"
                  icon
                  color="error"
                  :disabled="!item.guid"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </td>

            <td
              v-else-if="head._filter"
              :key="head.value + 4"
            >
              <div
                class="text-nowrap"
                v-html="head._filter(item[head.value], item)"
                :style="{
                  textAlign: head._align,
                  minWidth: head.minWidth,
                }"
              />
            </td>

            <td
              v-else
              :key="head.value + 7"
            >
              <div v-text="item[head.value]" />
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>

    <div class="d-flex justify-center pt-5">
      <v-btn
        min-width="200px"
        @click="onShare"
        elevation="0"
        color="primary"
      >
        {{ tn("share_btn") }}
      </v-btn>
    </div>
  </modals-template>
</template>
