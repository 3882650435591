<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: String,
  },

  data: () => ({
    isShowLetters: false,
    letters: [],
  }),

  async created() {
    this.$store.dispatch("SET_LOADING", true);
    this.letters = await this.getFilterLetterApi();
    this.$store.dispatch("SET_LOADING", false);
  },

  computed: {
    selectedLetter: {
      get: (vm) => vm.value,
      set(v) {
        this.$emit("input", v);
        this.isShowLetters = false;
      },
    },
  },

  methods: {
    ...mapActions("product", {
      getFilterLetterApi: "GET_FILTER_LETTER_API",
    }),
  },
};
</script>

<template>
  <v-menu
    v-if="letters.length"
    v-model="isShowLetters"
    :close-on-content-click="false"
    max-width="496"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2"
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        v-bind="attrs"
        v-on="on"
      >
        {{ selectedLetter || "A-Я" }}
      </v-btn>
    </template>

    <v-card>
      <div class="pa-2">
        <v-btn
          class="ma-1"
          @click="selectedLetter = null"
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          :outlined="!!selectedLetter"
        >
          А-Я
        </v-btn>
        <v-btn
          class="ma-1"
          v-for="letter in letters"
          :key="letter"
          @click="selectedLetter = letter"
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          :outlined="selectedLetter != letter"
          :title="letter"
        >
          {{ letter }}
        </v-btn>
      </div>
    </v-card>
  </v-menu>
</template>
