import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
import { parseDateAsync } from "@/utils/parse-date-async";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = "PriceListViewerOrganizationStatistic";

export const state = () => ({
  itemsData: {},
  importItems: [],
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),

      totals: {
        count: vm.$number(payload?.totalCount),
        totalInboundLastQuantity: vm.$number(payload?.totalInboundLastQuantity),
        totalQuantityReject: vm.$number(payload?.totalQuantityReject),
        totalQuantityStock: vm.$number(payload?.totalQuantityStock),
        totalQuantityStockNeed: vm.$number(payload?.totalQuantityStockNeed),
        totalSaleQuantityDay30: vm.$number(payload?.totalSaleQuantityDay30),
      },
    };
  },

  SET_IMPORT_ITEMS(state, payload = []) {
    state.importItems = vm.$array(payload);
  },

  REPLACE_ITEM(state, payload) {
    state.itemsData.items = vm.$array(state.itemsData.items).map((x) => (x.guid == payload.guid ? payload : x));
  },
};

export const actions = {
  async GET_API({ commit }, { options }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();
    commit("SET_ITEMS");

    const params1 = { ...options };

    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$post(`${url}/List`, params, request);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  async CHECK_IMPORT() {
    const res = await vm.$axios.$get(`PriceListViewerOrganizationStatisticImport/CheckImport`);
    return res?.succeeded ? res.data : null;
  },

  SET_IMPORT_ITEMS({ commit }, payload) {
    commit("SET_IMPORT_ITEMS", payload);
  },

  async IMPORT_ITEMS_API({ state }, { fields }) {
    const lineItems = await correctItems(state.importItems, fields);
    let status = false;
    let data;

    if (lineItems?.length) {
      const res = await vm.$axios.$post("PriceListViewerOrganizationStatisticImport", {
        lineItems,
      });
      status = res?.succeeded;
      data = res?.data;
    }

    return { status, count: lineItems?.length, data };
  },

  async DELETE_API(ctx, guid) {
    const res = await vm.$axios.$delete(url + "/" + guid);
    return res?.succeeded;
  },

  async DELETE_ALL_API() {
    const res = await vm.$axios.$delete(url + "/DeleteList");
    return res?.succeeded;
  },

  async DELETE_BRANCH_NAME_API(ctx, payload) {
    const res = await vm.$axios.$post(`${url}/Delete/BranchName`, payload);
    return res?.succeeded;
  },

  async MERGE_API(ctx, payload) {
    const res = await vm.$axios.$post(`${url}/Merge`, payload);
    return res?.succeeded;
  },

  async GET_BRANCH_NAME_LIST_API() {
    const res = (await vm.$axios.$get(`${url}/BranchNameList`))?.data;
    return res;
  },

  async GET_PRODUCT_STATISTIC_INFO_API(ctx, payload) {
    const res = await vm.$axios.$post("PriceListViewerProductStatisticInfo", payload);
    return res?.succeeded;
  },

  async ROUND_STOCK_NEED_QTY_API(ctx, payload) {
    const params = {
      lessThanOneQuantity: payload.less1,
      greaterThanOneQuantity: payload.large1,
    };

    const res = await vm.$axios.$post(`${url}/RoundStockNeedQuantity`, params);
    return res?.succeeded;
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
  GET_TOTALS: (state) => state.itemsData.totals || {},
  GET_IMPORT_ITEMS: (state) => state.importItems,
};

async function correctItems(lineItems, fields) {
  const lifs = [];
  for (const key in fields) {
    if (trim(fields[key])) {
      lifs.push({ key, param: fields[key] });
    }
  }

  let newItems = [];
  for (const lineItem of lineItems) {
    const params = {};

    lifs.forEach((li) => (params[li.param] = trim(lineItem[li.key])));

    if (params.nm) {
      const nms = `${params.nm}`.split("\\");
      params.manufacturerName = nms.length > 1 ? nms.pop() : "";
      params.productName = nms.join();
      delete params.nm;
    }

    // fix decimals
    Object.keys(params)
      .filter((_key) => !(_key == "branchName" || _key == "productName" || _key == "manufacturerName" || _key == "inboundSupplierName" || _key == "saleLastDateTime" || _key == "inboundLastDateTime"))
      .forEach((_dkey) => (params[_dkey] = fixDecimal(params[_dkey])));

    params.saleLastDateTime = await parseDateAsync(params.saleLastDateTime);
    if (!params.saleLastDateTime) delete params.saleLastDateTime;

    params.inboundLastDateTime = await parseDateAsync(params.inboundLastDateTime);
    if (!params.inboundLastDateTime) delete params.inboundLastDateTime;

    if (trim(params.productName) && trim(params.manufacturerName)) {
      newItems.push(params);
    }
  }

  return newItems;
}

function fixDecimal(string) {
  let str = `${string || ""}`;

  if (str.includes(".")) {
    // Agar . bo'lsa u holda hamma , olib tashlash kerek
    str = str.replace(/,/g, "");
  } else {
    // Agar nuqta bo'lmasa va bir dona , bo'lsa u holda , -> .
    if ((str.match(/,/g) || []).length == 1) {
      str = str.replace(/,/g, ".");
    }
  }

  str = str.replace(/[^,.0-9]/g, "");

  if (str == "0") return null;
  return vm.$number(str) || null;
}

function trim(str) {
  return `${str || ""}`.trim();
}
