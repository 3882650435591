import { render, staticRenderFns } from "./price-list.vue?vue&type=template&id=548a15d6&scoped=true"
import script from "./price-list.vue?vue&type=script&lang=js"
export * from "./price-list.vue?vue&type=script&lang=js"
import style0 from "./price-list.vue?vue&type=style&index=0&id=548a15d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "548a15d6",
  null
  
)

export default component.exports