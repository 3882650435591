<script>
import { mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";

import ModalsTemplate from "@/components/modals-template.vue";

import { useSelectionTopPeriods } from "@/utils/selection-top-periods";

import TransitionExpand from "@/components/transition-expand.vue";
import TableFilterProviders from "../price-list-compare/table-filter-providers.vue";
import TableFilterRegions from "./table-filter-regions.vue";

export default {
  components: {
    ModalsTemplate,
    TableFilterRegions,
    TableFilterProviders,
    TransitionExpand,
  },

  props: {
    value: Object,
  },

  data: (vm) => ({
    dialog: true,
    isLoading: false,

    ln: "selection_top.product_list.table.filter",

    formData: {},
    isShowProviders: true,
    isShowRegions: false,
    isShowDistrRegions: false,

    tableHeight: 200,
    selectedProvidersCount: 0,
    selectionTopPeriods: useSelectionTopPeriods(vm),
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  computed: {
    ...mapGetters("references", { entries: "GET_REGIONS" }),

    tableHeaders: (vm) => [{ text: vm.tn("table.name"), value: "name", width: "100%" }],

    favoriteOrOthers: (vm) => [
      {
        name: vm.tn("all_providers"),
        value: null,
      },
      {
        name: vm.tn("show_fav_providers"),
        value: true,
      },
      {
        name: vm.tn("other_providers"),
        value: false,
      },
    ],
  },

  created() {
    if (Object.values(this.value).length) {
      this.formData = this.value;

      this.formData.isLinkedOnly = this.value.isLinkedOnly != false;

      if (this.value.regionListTop500?.length) this.isShowRegions = true;
      else if (this.value.regionGuids?.length) this.isShowDistrRegions = true;
    }
  },

  methods: {
    async onApply() {
      const formData = { ...this.formData };

      const regionGuids = this.$array(this.$refs.distrRegions?.selectedRows);
      const providerGuids = this.$array(this.$refs.providers?.selectedRows);
      const regionListTop500 = this.$array(this.$refs.regions?.selectedRows);

      formData.regionGuids = regionGuids.map((x) => x.guid).filter((x) => x);
      if (!formData.regionGuids.length) {
        delete formData.regionGuids;
      }

      formData.providerGuids = providerGuids.map((x) => x.guid).filter((x) => x);
      if (!formData.providerGuids.length) {
        delete formData.providerGuids;
      }

      formData.regionListTop500 = regionListTop500.map((x) => x.guid).filter((x) => x);
      if (!formData.regionListTop500.length) {
        delete formData.regionListTop500;
      }

      this.$emit("apply", formData);
      this.dialog = false;
    },

    onReset() {
      this.$refs.regions.selectedRows = [];
      this.$refs.providers.selectedRows = [];
      this.$refs.distrRegions.selectedRows = [];
      this.formData = { top500Period: 14, isFavoriteProvider: null, isLinkedOnly: true };
      this.$nextTick(() => this.onApply());
    },

    onChangeFavoriteOrOthers() {
      if (this.formData.isFavoriteProvider != false) {
        this.$refs.providers.selectedRows = [];
      }
    },
  },
};
</script>

<template>
  <modals-template
    :width="600"
    v-model="dialog"
    :title="tn('title')"
    :persistent="false"
  >
    <v-checkbox
      v-model="formData.isLinkedOnly"
      :label="tn('linked_only')"
    />

    <h4 v-text="$t('selection_top_periods.title')" />
    <v-select
      :items="selectionTopPeriods"
      item-text="name"
      item-value="value"
      outlined
      v-model="formData.top500Period"
      dense
    />

    <table-filter-regions
      @switchHide="isShowRegions = !isShowRegions"
      :isShow="isShowRegions"
      :value="formData.regionListTop500"
      ref="regions"
      :tableHeight="tableHeight"
      :title="tn('regions')"
    />

    <div class="pt-4" />

    <div class="d-flex align-center">
      <v-btn
        @click="isShowProviders = !isShowProviders"
        color="primary"
        outlined
        x-small
        icon
      >
        <v-icon v-if="isShowProviders"> mdi-minus </v-icon>
        <v-icon v-else> mdi-plus </v-icon>
      </v-btn>

      <div class="d-flex align-items-center">
        <h4
          class="pl-2"
          v-text="tn('providers')"
        />
        <div
          v-if="selectedProvidersCount"
          class="pl-1 error--text"
        >
          ({{ selectedProvidersCount }})
          <span
            @click="$refs.providers.selectedRows = []"
            class="span-link"
          >
            {{ tn("clear_providers") }}
          </span>
        </div>
      </div>
    </div>

    <TransitionExpand>
      <div v-show="isShowProviders">
        <div class="pt-2" />
        <v-select
          dense
          v-model="formData.isFavoriteProvider"
          :items="favoriteOrOthers"
          outlined
          hide-details
          item-text="name"
          item-value="value"
          @change="onChangeFavoriteOrOthers"
        />

        <TransitionExpand>
          <div v-show="formData.isFavoriteProvider == false">
            <div class="pt-2" />
            <table-filter-providers
              @setCount="(v) => (selectedProvidersCount = v)"
              :value="formData.providerGuids"
              ref="providers"
              :tableHeight="tableHeight"
            />
          </div>
        </TransitionExpand>
      </div>
    </TransitionExpand>

    <div class="pt-4" />

    <table-filter-regions
      @switchHide="isShowDistrRegions = !isShowDistrRegions"
      :isShow="isShowDistrRegions"
      :value="formData.regionGuids"
      ref="distrRegions"
      :tableHeight="tableHeight"
      :title="tn('regions_distributor')"
    />

    <div class="d-flex align-center justify-center">
      <v-btn
        height="45"
        :loading="isLoading"
        color="error"
        @click="onReset"
        elevation="0"
        class="mt-5 mb-1 w-49"
      >
        {{ tn("reset_btn") }}
      </v-btn>
      <div class="px-2" />
      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onApply"
        elevation="0"
        class="mt-5 mb-1 w-49"
      >
        {{ tn("apply_btn") }}
      </v-btn>
    </div>
  </modals-template>
</template>
