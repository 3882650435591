import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = 'HomePageTile'

export const state = () => ({
  itemsData: {}
});

export const mutations = {
  SET(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    }
  },
};

export const actions = {
  async GET_API({ commit }, { options, filterData }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();
    commit("SET");

    const params = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      sortBy: options.sortBy,
      sortDirection: options.sortBy ? options.sortDesc : null,
      search: options.search,
      ...filterData
    };

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$get(url, params, request);
    if (res?.succeeded) commit("SET", res?.data);
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
};
