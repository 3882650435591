<script>
import debounce from "debounce";
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";

export default {
  mixins: [tnVue],

  components: {
    ConfirmDialog,
  },

  data: (vm) => ({
    sortBy: null,
    options: {},
    itemsPerPage: 50,
    rowsPerPage: [50, 100],

    selected: {},

    loaders: {},

    ln: "history.basket_price_list_provider_result.join",

    searchText: null,

    isShowDelete: null,
    isShowClose: null,

    basketGuid: vm.$route.params.basketGuid,
    sessionGuid: vm.$route.params.sessionGuid,
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("basket-price-list-provider-result-join", {
      entries: "GET",
      entriesCount: "GET_COUNT",
    }),

    size: (vm) => vm.$store.getters.GET_SIZE,

    tableHeaders: (vm) => [
      {
        text: "№",
        value: "number",
        width: 40,
        align: "center",
        sortable: false,
      },
      {
        text: "",
        value: "status",
        width: 40,
        align: "center",
      },
      {
        text: vm.tn("table.name"),
        value: "linkedProductName",
        width: "50%",
      },
      {
        text: vm.tn("table.name_excel"),
        value: "productName",
        width: "50%",
      },
      {
        text: "",
        value: "actions",
        width: 35,
        sortable: false,
      },
    ],

    tableHeight: (vm) => vm.size.height - 160,

    isLoading: (vm) => !!Object.keys(vm.loaders).length,
  },

  methods: {
    ...mapActions("basket-price-list-provider-result-join", {
      getEntriesApi: "GET_API",
      deleteApi: "DELETE_API",
      deleteSessionApi: "DELETE_SESSION_API",
      applyToList: "APPLY_TO_LIST",
    }),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          sessionGuid: this.sessionGuid,
          search: this.searchText,
        },
      };

      await this.getEntriesApi(params);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onClear() {
      this.searchText = null;
      this.$nextTick(() => this.getEntries());
    },

    onDeleteShow({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowDelete = true));
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);
      await this.deleteApi({
        basketGuid: this.basketGuid,
        guid: this.selected?.guid
      });
      setLoading(false);
      close();
      this.getEntries();
    },

    async onExit({ close, setLoading }) {
      setLoading(true);
      const status = await this.deleteSessionApi(this.sessionGuid);
      setLoading(false);
      if (status) {
        close();
        this.$router.replace(`/history/${this.basketGuid}`);
      }
    },

    async onApply() {
      const status = await this.applyToList({
        sessionGuid: this.sessionGuid,
        basketGuid: this.basketGuid
      });
      if (status) {
        this.$router.replace(`/history/${this.basketGuid}`);
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("saved_success"),
          color: "success",
        });
      }
    },
  },
};
</script>

<template>
  <div class="px-5 pt-5">
    <ConfirmDialog
      :text="tn('delete_alert')"
      @close="isShowDelete = false"
      v-if="isShowDelete"
      @accept="onDelete"
    />

    <ConfirmDialog
      :text="tn('delete_session_alert')"
      @close="isShowClose = false"
      v-if="isShowClose"
      @accept="onExit"
    />

    <div class="d-flex align-center">
      <h4>
        {{ tn("title") }}
      </h4>

      <v-spacer />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <div class="px-1" />

      <v-text-field
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        style="max-width: 300px; width: 100%"
        @click:append="onSearch"
        @keyup.enter="onSearch"
        @click:clear="onClear"
        :label="tn('search')"
        clearable
        v-model="searchText"
        ref="searchBox"
      />

      <div class="px-1" />

      <v-btn
        @click="onApply"
        outlined
        color="primary"
        height="40"
        min-height="40"
        class="transform-none"
      >
        {{ tn("add_btn") }}
      </v-btn>

      <div class="px-1" />

      <v-btn
        @click="isShowClose = true"
        outlined
        color="red"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </div>

    <div class="mt-3" />

    <v-data-table
      :headers="tableHeaders"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="entriesCount"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: $t('table.per_page_text'),
      }"
      :height="tableHeight"
      item-key="guid"
      class="fixed-right"
      fixed-header
    >
      <template #item.number="{ index }">
        <td class="text-center">
          <span
            class="text-nowrap"
            v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
          />
        </td>
      </template>

      <template #item.status="{ item }">
        <v-icon
          color="success"
          v-if="item.status == 1"
        >
          mdi-check
        </v-icon>
        <v-icon
          color="error"
          v-else
        >
          mdi-close
        </v-icon>
      </template>

      <template #item.linkedProductName="{ item }">
        <td class="pa-0">
          <div class="px-2 py-1">
            {{ item.linkedProductName }}
            <template v-if="item.linkedManufacturerName"> ({{ item.linkedManufacturerName }}) </template>
          </div>
        </td>
      </template>

      <template #item.productName="{ item }">
        <td>
          <div>{{ item.productName }} ({{ item.manufacturerName }})</div>
        </td>
      </template>

      <template #item.actions="{ item }">
        <td class="px-0">
          <div class="tb-action-wrap justify-center transform-7">
            <v-btn
              @click="onDeleteShow({ item })"
              icon
              small
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped>
.transform-7 {
  transform: translateY(-7px);
}
</style>
