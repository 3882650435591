<script>
import { mapActions, mapGetters } from "vuex";
import tnVue from "@/mixins/tn.vue";

export default {
  data: () => ({
    ln: "selection_auto.import",

    isLoading: false,
    columns: {},
  }),

  mixins: [tnVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("selection-auto", {
      entries: "GET_IMPORT_ITEMS",
    }),

    tableHeight: (vm) => vm.size.height - 160,
    tableHeaders() {
      const pi = this.entries;
      const entries = pi.length ? Object.keys(pi[0]) : [];
      return entries.map((x) => ({ text: x, value: x }));
    },

    fields: (vm) => [
      {
        name: vm.tn("name"),
        value: "productName",
        variants: ["наим", "наз", "тов", "ном", "name"],
      },
      {
        name: vm.tn("manufacturer"),
        value: "manufacturerName",
        variants: ["произ", "чикар", "чиқар", "manufac"],
      },
      {
        name: vm.tn("name") + "\\" + vm.tn("manufacturer"),
        value: "nm",
        variants: [],
      },
      {
        name: vm.tn("need"),
        value: "stockNeed",
        variants: ["потр"],
      },
      {
        name: vm.tn("price"),
        value: "salePrice",
        variants: ["цен"],
      },
      {
        name: vm.tn("quantity"),
        value: "stockQuantity",
        variants: ["кол", "остат"],
      },
      {
        name: vm.tn("selling"),
        value: "saleQuantityDay30",
        variants: ["продажа"],
      },
    ],

    availableFields() {
      const values = Object.values(this.columns) || [];

      return this.fields
        .map((x) => {
          x.disabled = !!values.some((v) => v == x.value);
          return x;
        })
        .reverse()
        .sort((a) => (a.disabled ? 1 : -1));
    },
  },

  created() {
    if (this.entries?.length) {
      this.checkFields();
    } else {
      this.onExit();
    }
  },

  methods: {
    ...mapActions("selection-auto", {
      importItemsApi: "IMPORT_ITEMS_API",
    }),

    checkFields() {
      this.columns = {};
      const columns = [];
      const values = Object.values(this.entries[0]);
      values.forEach((key, inx) => {
        const name = `${key || ""}`.toLowerCase();

        this.fields.forEach((vi) => {
          for (let index = 0; index < vi.variants.length; index++) {
            if (((vi.code && vi.code == name) || name.includes(vi.variants[index])) && !Object.values(columns).find((c) => c == vi.value)) {
              columns[inx] = vi.value;
              break;
            }
          }
        });
      });

      this.$nextTick(() => (this.columns = columns));
    },

    async onSendExcel() {
      if (this.checkRequiredFields()) {
        this.$store.dispatch("SET_LOADING", true);
        this.isLoading = true;
        const res = await this.importItemsApi({ fields: this.columns });

        if (res.count > 0) {
          if (res.status) this.$router.replace(`/selection/auto/join/${res.data}`);
        } else {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.tn("select_columns_alert"),
            color: "info",
          });
        }

        this.isLoading = false;
        this.$store.dispatch("SET_LOADING", false);
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("select_columns_alert"),
          color: "info",
        });
      }
    },

    checkRequiredFields() {
      const keys = Object.values(this.columns || {});
      if (keys.find((x) => x == "nm")) {
        return true;
      } else {
        const rqs = keys.filter((x) => x == "productName" || x == "manufacturerName");
        return rqs.length == 2;
      }
    },

    onExit() {
      this.$router.replace("/selection/auto");
    },
  },
};
</script>

<template>
  <div class="px-5 pt-5">
    <div class="d-flex align-center">
      <h4>
        {{ tn("title") }}
      </h4>

      <v-spacer />

      <v-btn
        color="primary"
        class="transform-none"
        dark
        @click="onSendExcel"
        elevation="0"
        outlined
        :loading="isLoading"
        min-height="40"
        height="40"
      >
        {{ tn("next_btn") }}
      </v-btn>

      <div class="px-1" />

      <v-btn
        @click="onExit"
        outlined
        color="red"
        width="40"
        min-width="40"
        min-height="40"
        height="40"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </div>

    <div class="mt-3" />

    <v-data-table
      disable-sort
      :headers="tableHeaders"
      hide-default-header
      :items="entries"
      :height="tableHeight"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [30],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      mobile-breakpoint="0"
      fixed-header
      calculate-widths
      dense
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th
              width="25%"
              v-for="(header, index) in headers"
              :key="index"
            >
              <div class="py-2">
                <v-select
                  class="table-column-select"
                  clearable
                  item-text="name"
                  item-value="value"
                  hide-details
                  outlined
                  :items="availableFields"
                  v-model="columns[header.value]"
                  dense
                />
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr
            v-for="(item, key) in items"
            :key="`tr-${key}`"
          >
            <td
              v-for="(header, index) in headers"
              :key="`td-${index}`"
            >
              <div
                style="width: 250px"
                v-text="item[header.value]"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table> </div
></template>

<style lang="scss" scoped>
.dt-no-data:hover,
.dt-no-data {
  opacity: 0.6;
  text-align: center;
  background-color: transparent !important;
}
</style>
