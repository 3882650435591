<script>
export default {
  props: {
    tabindex: Number,
    uniKey: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    //
  }),

  methods: {
    onKeyUp(e) {
      if (e.code == "PageUp") this.$emit("pageUp");
      else if (e.code == "PageDown") this.$emit("pageDown");
    },

    onEnter() {
      this.$nextTick(() => this.$emit("enter"));
    },
  },
};
</script>

<template>
  <div class="tb-focus-input">
    <input
      @keydown.up="$emit('upKey')"
      @keydown.down="$emit('downKey')"
      @keyup.enter="onEnter"
      @keyup="onKeyUp"
      :tabindex="tabindex"
      :class="uniKey"
    />
  </div>
</template>

<style lang="scss" scoped>
.tb-focus-input {
  overflow: hidden;
  width: 0;
  height: 0;
}
</style>
