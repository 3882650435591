import Vue from "vue";
export const sum = value => {
  const number = Number(value);
  if (number) {
    const fixed = +number.toFixed(2);
    return `${fixed || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else return value;
};

Vue.prototype.$sum = sum;

Vue.filter("sum", sum);
