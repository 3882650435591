<script>
import TablePaginationButtons from "@/components/table-pagination-buttons.vue";

export default {
  components: { TablePaginationButtons },

  data: () => ({
    pageInput: 0,
    perPageInput: 50,
  }),

  created() {
    const perPage = this.$number(localStorage.getItem(`options-itemsPerPage-${this.uniqKey}`));
    if (perPage > 0) {
      this.options.itemsPerPage = perPage;
    }
  },

  watch: {
    "options.page"(v) {
      this.pageInput = v;
    },

    "options.itemsPerPage"(v) {
      this.perPageInput = v;
    },
  },

  computed: {
    lastPage: (vm) => Math.ceil(vm.entriesCount / vm.options.itemsPerPage),
  },

  methods: {
    onChangePageInput(e, v) {
      const value = e?.target?.value || v;
      const _page = this.$number(value) || 1;
      const last = this.lastPage;
      if (!this.$number(last)) return;
      const current = _page < last ? _page : last;
      this.pageInput = current;
      this.options.page = current;
    },

    onChangePerPageInput(e) {
      const value = this.$number(e) || this.$number(e?.target?.value) || 50;
      this.pageInput = 1;
      this.options.page = 1;
      this.options.itemsPerPage = value;
      localStorage.setItem(`options-itemsPerPage-${this.uniqKey}`, value);
    },

    onLastPage() {
      this.options.page = this.lastPage;
    },
  },
};
</script>
