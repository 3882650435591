<script>
import debounce from "debounce";
import tnVue from "@/mixins/tn.vue";
import { mapActions, mapGetters } from "vuex";

import TableCog from "@/components/table-cog.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TablePagination from "@/mixins/table-pagination.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import PriceListViewerOrderItems from "./price-list-viewer-order-items";
import TableFilter from "@/components/modals/price-list-viewer-order/table-filter.vue";
import HistoryOfChange from "@/components/modals/price-list-viewer-order/history-of-change.vue";

const detailsStoreKey = "price-list-viewer-order-hide";

export default {
  mixins: [tnVue, TableNavFunctionsVue, TablePagination, TableResizeVue],
  components: { TableCog, PriceListViewerOrderItems, TableFilter, HistoryOfChange },

  data: (vm) => ({
    ln: "price_list_viewer_order",
    uniqKey: "price_list_viewer_order",
    sortBy: null,
    tabs: [
      {
        name: "tabs.all",
        color: "#525252",
      },
      {
        name: "tabs.sent",
        color: "#707070",
      },
      {
        name: "tabs.accepted",
        color: "#5689C5",
      },
      {
        name: "tabs.cancelled",
        color: "#C5A656",
      },
      {
        name: "tabs.completed",
        color: "#56C561",
      },
    ],
    activeTab: 0,

    isShowItems: false,
    isFilter: false,
    isHistoryOfChange: false,
    isDetailsBtn: localStorage.getItem(detailsStoreKey) != "false",
    filterData: vm.$getFilterData() || {},
    options: vm.$getSortLocal({ key: "price-list-viewer-order" }),
    filteredHeaders2: [],
  }),

  watch: {
    activeTab: debounce(function () {
      this.getEntries({ reset: true });
    }, 300),

    selected: debounce(function (v) {
      this.isShowItems = false;
      if (v?.guid) {
        this.isShowItems = true;
        this.$refs.priceListViewerOrderItems?.getEntries({ reset: true });
      }
    }, 300),

    searchText() {
      this.onSearch();
    },
  },

  computed: {
    ...mapGetters("price-list-viewer-order", ["GET", "GET_COUNT", "GET_TOTALS"]),
    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,
    entriesTotals: (vm) => vm.GET_TOTALS,

    tableHeight: (vm) => vm.size.height - 234,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "_number",
          width: 10,
          sortable: false,
          class: "disable-resize",
          _required: true,
          _resize: false,
          align: "center",
        },
        {
          text: vm.tn("table.status"),
          value: "status",
          align: "center",
          width: "120px",
          _required: true,
        },
        {
          text: vm.tn("table.date_and_time_of_formation"),
          value: "timeStarted",
          _filter: (v) => vm.$dateTime(v),
          width: "200px",
          _required: true,
        },
        {
          text: vm.tn("table.price_type"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
        },
        {
          text: vm.tn("table.distributor"),
          value: "priceListProviderName",
          _required: true,
          minWidth: "200px",
        },
        {
          text: vm.tn("table.amount_ordered"),
          value: "totalAmountOrdered",
          _filter: (v) => vm.$sum(v),
          _align: "right",
          minWidth: "120px",
        },
        {
          text: vm.tn("table.amount_accepted"),
          value: "totalAmountAccepted",
          _filter: (v) => vm.$sum(v),
          _align: "right",
          minWidth: "120px",
        },
        {
          text: vm.tn("table.itemsCount"),
          value: "itemsCount",
          _filter: (v) => vm.$sum(v),
          _align: "right",
          minWidth: "120px",
        },
        {
          text: vm.tn("table.note"),
          value: "note",
          width: "200px",
        },
        {
          text: "",
          value: "column",
          sortable: false,
          _resize: false,
          width: "100%",
          maxWidth: "450px",
        },
        {
          text: "",
          value: "actions",
          width: 10,
          sortable: false,
          _resize: false,
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),

    actionsList: (vm) => [
      {
        name: vm.tn("actions.export_to_excel_all_btn"),
        action: (item) => vm.onExportExcel({ item }),
      },
      {
        name: vm.tn("actions.export_to_excel_ended_btn"),
        action: (item) => vm.onExportExcel({ item, showAcceptedOrder: false }),
      },
      {
        name: vm.tn("actions.export_to_excel_confirmed_btn"),
        action: (item) => vm.onExportExcel({ item, showAcceptedOrder: true }),
      },
    ],

    isFilterBadge() {
      const values = Object.values(this.filterData);
      const filters = values.filter((x) => x === 0 || `${x || ""}`.length);
      return filters.length;
    },
  },

  methods: {
    ...mapActions("price-list-viewer-order", {
      getEntriesApi: "GET_API",
    }),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.$setSortLocal({
        key: "price-list-viewer-order",
        options: this.options,
      });

      const params = { options: { ...this.options } };

      params.options.sortBy = this.$getSort(params.options.sortBy);
      params.options.sortDesc = this.$getSort(params.options.sortDesc, true);

      if (this.searchText) params.options.search = this.searchText;
      params.filterData = { ...this.filterData };

      switch (this.activeTab) {
        case 1:
          params.options.status = 1;
          break;
        case 2:
          params.options.status = 3;
          break;
        case 3:
          params.options.status = 200;
          break;
        case 4:
          params.options.status = 100;
          break;
        default:
          params.options.status = null;
      }

      if (!params.options.sortBy) delete params.options.sortDesc;

      await this.getEntriesApi(params);
      if (document.activeElement?.id != this.ln) {
        this.setFocusToItem();
      }

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.uniqKey);
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onHide() {
      this.isDetailsBtn = !this.isDetailsBtn;
      const isHide = this.isDetailsBtn;
      localStorage.setItem(detailsStoreKey, isHide);
    },

    onKeyup(e) {
      if (e.keyCode == 113) {
        this.$refs.searchBox?.focus();
        this.$nextTick(() => {
          const input = this.$refs.searchBox?.$el?.querySelector("input");
          input?.select();
        });
      }
    },

    async onExportExcel({ item, showAcceptedOrder }) {
      this.$store.dispatch("SET_LOADING", true);
      const url = `PriceListViewerOrder/${item.guid}/Export`;
      await this.$axios.$postResFile(url, { showAcceptedOrder: showAcceptedOrder });
      this.$store.dispatch("SET_LOADING", false);
    },

    onFilter(filterData) {
      this.filterData = filterData;
      this.$setFilterData(filterData);
      this.$nextTick(() => this.getEntries());
    },

    onKeyupDown() {
      this.setFocusToItem();
    },

    onHistoryOfChange({ item }) {
      this.selected = {};
      this.$nextTick(() => {
        this.selected = { ...item };
        this.isHistoryOfChange = true;
      });
    },

    onCloseHistory() {
      this.isHistoryOfChange = false;
      this.isSavePosition = false;
      this.$nextTick(() => this.setFocusToTable());
    },
  },
};
</script>

<template>
  <v-container fluid>
    <HistoryOfChange
      @close="onCloseHistory"
      v-if="isHistoryOfChange"
      :guid="selected.guid"
      :ln="'price_list_viewer_order_view'"
    />

    <TableFilter
      v-if="isFilter"
      :value="filterData"
      @close="isFilter = false"
      @apply="onFilter"
    />
    <h3 v-text="tn('title')" />

    <div class="d-flex align-center">
      <div class="d-flex align-center pb-2">
        <div class="d-flex _actions-bar">
          <v-tabs
            active-class="tab-active"
            background-color="transparent"
            class="transform-none my-supplies-tabs"
            v-model="activeTab"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.name"
              :style="{ color: tab.color }"
              class="text-transform-none"
            >
              {{ tn(tab.name) }}
            </v-tab>
          </v-tabs>
        </div>
      </div>

      <v-spacer />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
        class="mr-2"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <table-cog
        :headersData="tableHeaders"
        @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
        :cacheKey="uniqKey"
      />

      <div class="mx-1" />

      <v-badge
        dot
        overlap
        color="error"
        :value="isFilterBadge"
      >
        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          @click="isFilter = true"
          :title="tn('filter_btn')"
        >
          <v-icon> mdi-filter </v-icon>
        </v-btn>
      </v-badge>

      <div class="mx-1" />

      <v-text-field
        :id="ln"
        ref="searchBox"
        v-model="searchText"
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        clearable
        style="max-width: 300px; width: 100%"
        :label="tn('search')"
        @click:append="onSearch"
        @keyup.enter="onKeyupDown({ enter: true })"
        @keyup.down="onKeyupDown"
        @keyup.up="onKeyupDown"
      />
    </div>

    <v-data-table
      v-if="filteredHeaders.length && tableHeight > 0"
      :headers="filteredHeaders"
      dense
      fixed-header
      disable-pagination
      hide-default-footer
      class="fixed-left fixed-right"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="entriesCount"
      :height="isDetailsBtn ? tableHeight / 1.7 : tableHeight"
      v-resize-table="{ tableHeaders: filteredHeaders, callback: onUpdateTdSizes }"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr
          class="cursor-pointer"
          @click.stop="onSelect({ item })"
          :class="selected.guid == item.guid && 'tr-active'"
          @dblclick="$router.push(`/price-list-viewer-order/${item.guid}`)"
          @keyup.enter="$router.push(`/price-list-viewer-order/${item.guid}`)"
          :style="{ backgroundColor: item.status == 200 ? '#c5a65669' : '' }"
        >
          <template v-for="head in headers">
            <td
              v-if="head.value == '_number'"
              :key="head.value"
              class="px-0 text-center"
            >
              <span
                class="text-nowrap"
                v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
              />
              <table-focus-input
                :ref="item.guid"
                :tabindex="item._id"
                :item="item"
                :uniqKey="uniqKey"
                @upKey="onUpKey({ item })"
                @downKey="onDownKey({ item })"
                @pageUp="onPageUp({ item })"
                @pageDown="onPageDown({ item })"
              />
            </td>

            <td
              v-else-if="head.value == 'status'"
              :key="head.value + 1"
              class="text-center px-0"
              :style="{
                width: tdSizes[head.value] || head.width,
                maxWidth: tdSizes[head.value] || head.width,
              }"
            >
              <span>
                {{
                  (() => {
                    switch (item.status) {
                      case 1:
                        return tn("statuses.sent_title");
                      case 3:
                        return tn("statuses.accepted_title");
                      case 200:
                        return tn("statuses.cancelled_title");
                      case 100:
                        return tn("statuses.completed_title");
                      default:
                        return "";
                    }
                  })()
                }}
              </span>
            </td>

            <td
              v-else-if="head.value == 'note'"
              :key="head.value + 2"
              class="pa-0"
              :style="{
                width: tdSizes[head.value] || head.width,
                maxWidth: tdSizes[head.value] || head.width,
              }"
            >
              <div class="d-flex align-center">
                <v-btn
                  min-height="28"
                  height="28"
                  min-width="28"
                  width="28"
                  depressed
                  color="transparent"
                  class="mx-1"
                  @click="onHistoryOfChange({ item })"
                  :title="tn('history_of_status_changes_btn')"
                >
                  <v-icon> mdi-history</v-icon>
                </v-btn>
                <span
                  class="text-nowrap"
                  v-text="item.note"
                />
              </div>
            </td>

            <td
              v-else-if="head.value == 'actions'"
              :key="head.value + 3"
              class="px-0 text-center"
              :style="{ width: head.width }"
            >
              <div class="d-flex align-center justify-center">
                <v-menu
                  offset-y
                  offset-x
                  nudge-left="5"
                  nudge-top="5"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      min-height="28"
                      height="28"
                      min-width="28"
                      width="28"
                      depressed
                      color="transparent"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-menu </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(actionItem, index) in actionsList"
                      :key="index"
                      @click="actionItem.action(item)"
                    >
                      <v-list-item-title :class="actionItem.class">
                        {{ actionItem.name }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider
                      v-if="index < actionsList.length - 1"
                      :key="index + 'i'"
                    />
                  </v-list>
                </v-menu>
              </div>
            </td>

            <td
              v-else
              :key="head.value + 4"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div
                :style="{ minWidth: head.minWidth, textAlign: head._align, ...head._getStyle?.(item[head.value], item) }"
                v-html="head._filter ? head._filter(item[head.value], item) : item[head.value]"
                :class="{ 'text-nowrap': head._wrap }"
              />
            </td>
          </template>
        </tr>
      </template>

      <template
        v-if="entriesCount > 1"
        #body.append="bodyProps"
      >
        <tr class="sticky-table-footer">
          <td
            v-for="(header, i) in bodyProps.headers"
            :key="i"
          >
            <div
              v-if="header.value == '_number'"
              class="text-nowrap text-center"
              v-text="$sum(entriesTotals.count)"
            />
            <div
              v-if="header.value"
              class="text-nowrap text-right"
              v-text="$sum(entriesTotals[header.value], true)"
            />
          </td>
        </tr>
      </template>

      <template #footer>
        <TablePaginationButtons
          :loading="isLoading"
          :options="options"
          :lastPage="lastPage"
          :perPageInput="perPageInput"
          :pageInput="pageInput"
          :entriesCount="entriesCount"
          :onLastPage="onLastPage"
          :onChangePerPageInput="onChangePerPageInput"
          :onChangePageInput="onChangePageInput"
        />
      </template>
    </v-data-table>

    <price-list-viewer-order-items
      v-if="isShowItems"
      :priceListViewerOrderItem="selected"
      :tableHeight="tableHeight / 2.8"
      :filteredHeaders="filteredHeaders2"
      :ln="ln"
      :onHide="onHide"
      :isDetailsBtn="isDetailsBtn"
      ref="priceListViewerOrderItems"
    />
  </v-container>
</template>

<style lang="scss" scoped>
._actions-bar {
  border-bottom: 1px solid #e2e8ee;
  height: 55px;
  align-items: flex-end;
}
</style>
