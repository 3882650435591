import Vue from "vue";
Vue.prototype.$version = process.env.VUE_APP_VERSION;

import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import VueResizeTable from "./plugins/resize-table";
Vue.use(VueResizeTable)

import "./plugins/vue-inputmask.js";
import "./plugins/services.js";

import masks from "./utils/masks.js";
Vue.prototype.$masks = masks;

import { dxColumnSize } from './utils/dx-table-column-width.js'
Vue.prototype.$dxcs = dxColumnSize;

import "./plugins/get-real-time";

import "./utils/rules.js";
import "./utils/browser-store.js";

import "./scss/style.scss";

import "./filters";
import i18n from "./i18n";

Vue.config.productionTip = false;

import languageList from "./utils/language-list.";

const _url = process.env.VUE_APP_OSONKASSA_URL

Vue.prototype.$openCashier = () => {
  const token = Vue.$cookies.get("token");
  const _tenant_oc = sessionStorage.getItem("_tenant_oc");
  if (token) {
    const url = `${_url}${token}`.replace('{tenant}', _tenant_oc);
    sessionStorage.removeItem("_tenant_oc");
    window.location.replace(url);
  }
};

const _tenant = getQueryVar("_tenant_oc");
if (_tenant) sessionStorage.setItem("_tenant_oc", _tenant);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  i18n,

  beforeCreate() {
    const themeDark = localStorage.getItem("theme--dark");
    this.$vuetify.theme.dark = themeDark == "true";

    const locale = localStorage.getItem("language");
    if (locale && languageList.find((x) => x.value == locale)) {
      this.$i18n.locale = locale;
    }
  },
}).$mount("#app");

function getQueryVar(varName) {
  const queryStr = decodeURIComponent(window.location.search) + "&";
  const regex = new RegExp(".*?[&\\?]" + varName + "=(.*?)&.*");
  const val = queryStr.replace(regex, "$1");
  return val == queryStr ? false : val;
}
