<script>
import debounce from "debounce";

export default {
  data: () => ({
    tdSizes: {},
    tdSizes2: {},
  }),

  created() {
    if (this.uniqKey) {
      const tdSizes = this.$browserStore.getLocal(`${this.uniqKey}-tb-sizes`);
      if (Object.keys({ ...tdSizes }).length) {
        this.tdSizes = tdSizes;
      }
    }
    if (this.uniqKey2) {
      const tdSizes2 = this.$browserStore.getLocal(`${this.uniqKey2}-tb-sizes`);
      if (Object.keys({ ...tdSizes2 }).length) {
        this.tdSizes2 = tdSizes2;
      }
    }
  },

  methods: {
    onUpdateTdSizes: debounce(function (key, width) {
      if (this.tdSizes[key] == width) return;
      const tdSizes = { ...this.tdSizes };
      tdSizes[key] = width;
      this.tdSizes = tdSizes;
      if (this.uniqKey) {
        this.$browserStore.setLocal(`${this.uniqKey}-tb-sizes`, this.tdSizes);
      }
    }, 200),

    onUpdateTdSizes2: debounce(function (key, width) {
      if (this.tdSizes2[key] == width) return;
      const tdSizes2 = { ...this.tdSizes2 };
      tdSizes2[key] = width;
      this.tdSizes2 = tdSizes2;
      if (this.uniqKey2) {
        this.$browserStore.setLocal(`${this.uniqKey2}-tb-sizes`, this.tdSizes2);
      }
    }, 200),
  },
};
</script>
