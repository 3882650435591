<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [tnVue],

  components: { ModalsTemplate },

  props: {
    guid: String,
    ln: String,
  },

  data: () => ({
    dialog: true,
    options: {},

    loaders: {},
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },

    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("price-list-viewer-order-status-history", {
      entries: "GET",
      entriesCount: "GET_COUNT",
    }),

    size: (vm) => vm.$store.getters.GET_SIZE,
    tableHeight: (vm) => vm.size.height - 350,

    tableHeaders: (vm) => [
      {
        text: "№",
        value: "number",
        width: 10,
        sortable: false,
        class: "disable-resize",
      },
      {
        text: vm.tn("status_history.table.status"),
        value: "status",
        align: "center",
        width: "120px",
        sortable: false,
      },
      {
        text: vm.tn("status_history.table.note"),
        value: "note",
        sortable: false,
      },
      {
        text: vm.tn("status_history.table.created_on"),
        value: "createdOn",
        width: 10,
        _filter: (v) => vm.$date(v),
        sortable: false,
      },
      {
        text: vm.tn("status_history.table.comment_by"),
        value: "commentBy",
        sortable: false,
      },
    ],

    isLoading: (vm) => !!Object.keys(vm.loaders).length,
  },

  methods: {
    ...mapActions("price-list-viewer-order-status-history", {
      getEntriesApi: "GET_API",
    }),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = { options: {} };
      params.options.orderGuid = this.guid;

      await this.getEntriesApi(params);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },
  },
};
</script>

<template>
  <modals-template
    :width="900"
    :title="tn('status_history.title')"
    v-model="dialog"
  >
    <v-data-table
      v-if="tableHeight > 0"
      :headers="tableHeaders"
      hide-default-footer
      dense
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :height="tableHeight"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr class="cursor-pointer">
          <template v-for="head in headers">
            <td
              v-if="head.value == 'number'"
              :key="head.value"
              class="px-0 text-center"
            >
              <span
                class="text-nowrap"
                v-text="index + 1"
              />
            </td>

            <td
              v-else-if="head.value == 'status'"
              :key="head.value + 1"
              class="text-center px-0"
            >
              <span> {{
                (() => {
                  switch (item.status) {
                    case 1:
                      return tn("statuses.sent_title");
                    case 3:
                      return tn("statuses.accepted_title");
                    case 200:
                      return tn("statuses.cancelled_title");
                    case 100:
                      return tn("statuses.completed_title");
                    default:
                      return "";
                  }
                })()
              }} </span>
            </td>

            <td
              v-else-if="head._filter"
              :key="head.value + 2"
            >
              <div
                class="text-nowrap"
                v-html="head._filter(item[head.value], item)"
                :style="{
                  textAlign: head._align,
                  minWidth: head.minWidth,
                }"
              />
            </td>

            <td
              v-else
              :key="head.value + 3"
            >
              <div v-text="item[head.value]" />
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>

    <div class="d-flex pt-5">
      <v-spacer />

      <v-btn
        min-width="200px"
        @click="dialog = false"
        elevation="0"
        color="primary"
      >
        {{ tn("status_history.close_btn") }}
      </v-btn>
    </div>
  </modals-template>
</template>
