<script>
import tnVue from "@/mixins/tn.vue";

import LanguageList from "@/utils/language-list..js";

import FooterCopyright from "@/components/footer-copyright.vue";
import Contacts from "./contacts.vue";

export default {
  props: {
    maxWidth: {
      type: Number,
      default: () => 992,
    },
  },

  components: {
    FooterCopyright,
    Contacts,
  },

  data: () => ({
    isLoading: false,

    ln: "auth_template",

    languageList: LanguageList,
    selectedLanguage: null,
  }),

  mixins: [tnVue],

  watch: {
    selectedLanguage(v) {
      if (v) this.$i18n.locale = v;
    },
  },

  created() {
    const locale = this.$i18n.locale;
    const selectedLanguage = LanguageList.find((x) => x.value == locale);
    if (selectedLanguage) this.selectedLanguage = selectedLanguage.value;
    else this.selectedLanguage = LanguageList[0];
  },

  methods: {
    onPolicy() {
      window.open("/Публичная оферта - Oson Price (OP2023).pdf", "_blank");
    },
  },
};
</script>

<template>
  <div>
    <div class="auth-template">
      <div class="base-header">
        <v-spacer />
        <img
          width="210"
          src="@/assets/img/logo-inline.svg"
          alt=""
        />
        <v-spacer />
      </div>

      <v-container :style="{ maxWidth: maxWidth + 'px' }">
        <div class="pt-2 pb-2 text-right select-none fs_14">
          <template v-for="(lang, index) in languageList">
            <span
              class="cursor-default"
              :key="lang.value"
              v-if="selectedLanguage == lang.value"
              v-text="lang.name"
            />
            <a
              v-else
              @click="selectedLanguage = lang.value"
              :key="lang.value"
              v-text="lang.name"
            />
            <template v-if="index != languageList.length - 1"> &nbsp;/&nbsp; </template>
          </template>
        </div>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <p>
              {{ tn("distributors_info") }}
            </p>

            <br />

            <p>{{ tn("benefits_of_oson_price") }}</p>
            <ul>
              <li>
                {{ tn("benefits_of_oson_price_text1") }}
              </li>

              <li>
                {{ tn("benefits_of_oson_price_text2") }}
              </li>

              <li>
                {{ tn("benefits_of_oson_price_text3") }}
              </li>

              <li>
                {{ tn("benefits_of_oson_price_text4") }}
              </li>
            </ul>

            <br />

            <a
              class="_privacy_policy"
              @click="onPolicy"
            >
              {{ tn("privacy_policy") }}
            </a>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <slot />
          </v-col>
        </v-row>

        <div class="pt-15"></div>

        <contacts />
      </v-container>
    </div>
    <footer-copyright />
  </div>
</template>

<style lang="scss" scoped>
.auth-template {
  min-height: 97vh;
}

a:hover {
  text-decoration: underline;
}

._privacy_policy {
  font-size: 14px;
}

.fs_14 {
  font-size: 14px;
}
</style>
