<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";
import { mapActions } from "vuex";

export default {
  components: { ModalsTemplate },

  props: {},

  data: () => ({
    dialog: true,
    isLoading: false,

    formData: {},

    ln: "feedback.add",
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    ...mapActions("feedback", {
      addApi: "ADD_API",
    }),

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const formData = { ...this.formData };
        const status = await this.addApi(formData);
        this.isLoading = false;
        if (status) {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.tn("feedback_saved_alert"),
            color: "success",
          });
          this.$emit("saved");
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template
    :width="500"
    :title="tn('title')"
    v-model="dialog"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-textarea
        autofocus
        outlined
        @keyup.enter="onSave"
        dense
        v-model="formData.description"
        required
        :rules="[$rules.required, $rules.minSize(5)]"
      />

      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        elevation="0"
        class="mt-5 mb-1"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
