import Vue from "vue";

export const tel = value => {
  if (value && value.length == 12) {
    value = value.toString();
    const code1 = value.substr(0, 3);
    const code2 = value.substr(3, 2);
    const num1 = value.substr(5, 3);
    const num2 = value.substr(8, 2);
    const num3 = value.substr(10, 2);
    return `+${code1} ${code2}  ${num1} ${num2} ${num3}`;
  } else {
    return value;
  }
};

Vue.prototype.$tel = tel;

Vue.filter("tel", tel);
