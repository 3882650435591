import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = "priceListProvider"

export const state = () => ({
  providersData: {},
  provider: {},
});

export const mutations = {
  SET_PROVIDERS(state, payload) {
    state.providersData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    }
  },

  SET_PROVIDER(state, payload) {
    state.provider = payload || {}
  },
};

export const actions = {
  async GET_API({ commit }, { options, }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_PROVIDERS");
    const params = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      sortBy: options.sortBy,
      sortDirection: options.sortDesc,
      search: options.search,
    };

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$get(url, params, request);
    if (res?.succeeded) commit("SET_PROVIDERS", res?.data);
  },

  async GET_BY_GUID_API({ commit }, { guid }) {
    commit("SET_PROVIDER");
    const res = await vm.$axios.$get(url + '/' + guid);
    if (res?.succeeded) commit("SET_PROVIDER", res?.data);
  },

};

export const getters = {
  GET: (state) => state.providersData.items,
  GET_COUNT: (state) => state.providersData.total,
  GET_BY_GUID: (state) => state.provider,
};
