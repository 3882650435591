export const useSelectionTopPeriods = (vm) => {
  return [
    {
      name: vm.$t("selection_top_periods.day14"),
      value: 14,
    },
    {
      name: vm.$t("selection_top_periods.day31"),
      value: 31,
    },
    {
      name: vm.$t("selection_top_periods.day93"),
      value: 93,
    },
  ];
};
