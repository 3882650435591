<script>
import tnVue from "@/mixins/tn.vue";
import { mapActions } from "vuex";
import ModalsTemplate from "@/components/modals-template.vue";

export default {
  components: { ModalsTemplate },

  props: {
    providerGuid: String,
    value: Object,
  },

  data: () => ({
    ln: "price_list.show.viewing_access_permission.add",
    dialog: true,
    isLoading: false,
    formData: {},
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    ...mapActions("provider-permission", {
      addApi: "ADD_API",
    }),

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        const params = {
          priceListViewerPhone: "998" + this.formData.priceListViewerPhone,
          priceListProviderGuid: this.providerGuid,
        };

        const status = await this.addApi(params);
        this.isLoading = false;
        if (status) {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.$t("success"),
            color: "success",
          });
          this.$emit("saved");
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template
    :width="320"
    :title="tn('title')"
    v-model="dialog"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      {{ tn("form.phone_number") }}
      <v-text-field
        autofocus
        outlined
        dense
        v-model="formData.priceListViewerPhone"
        required
        :rules="[$rules.phone]"
        v-inputmask="$masks.phone"
        @keyup.enter="onSave"
        hide-details
      />

      <v-btn
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        elevation="0"
        class="mb-1 mt-5"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
