import i18n from "@/i18n";
import Vue from "vue";
import Vuex from "vuex";

const vm = Vue.prototype;

Vue.use(Vuex);

export default new Vuex.Store({
  modules: getModules(),

  state: {
    snackbar: {
      text: false,
      color: "success",
      isShow: false,
    },
    snackbar2: {
      text: false,
      isShow: false,
    },
    size: {
      height: 0,
      width: 0,
    },

    loading: false,
    loading2: false,
    init: false,
  },

  mutations: {
    SET_SNACKBAR(state, snackbar) {
      snackbar.isShow = true;
      state.snackbar = snackbar;
    },

    SET_SNACKBAR2(state, snackbar) {
      snackbar.isShow = true;
      state.snackbar2 = snackbar;
    },

    SET_SIZE(state, payload) {
      state.size = payload;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_LOADING2(state, payload) {
      state.loading2 = payload;
    },

    SET_INIT(state, payload) {
      state.init = payload;
    },
  },

  actions: {
    async INIT_STATES({ commit, rootState, dispatch }) {
      commit("SET_INIT", true);

      const isLaod = !`${window.location.search || ""}`.includes("preview=1");

      if (rootState.auth.user.exist && isLaod) {
        if (sessionStorage.getItem("_tenant_oc")) {
          vm.$openCashier();
        } else {
          if (rootState.auth.user.validDateTill != '9999-12-31T00:00:00') {
            const validTill = vm.$date(rootState.auth.user.validDateTill);
            const text = i18n.t("account_expires_alert", { 0: validTill });
            commit("SET_SNACKBAR2", { text });
          }

          await dispatch("references/INIT_REFERENCES");
        }
      }
      commit("SET_INIT", false);
    },

    SET_SNACKBAR({ commit }, payload) {
      commit("SET_SNACKBAR", payload);
    },

    SET_SIZE({ commit }, payload) {
      commit("SET_SIZE", payload);
    },

    SET_LOADING({ commit }, payload) {
      commit("SET_LOADING", payload);
    },

    SET_LOADING2({ commit }, payload) {
      commit("SET_LOADING2", payload);
    },

    SET_INIT({ commit }, payload) {
      commit("SET_INIT", payload);
    },
  },

  getters: {
    GET_SNACKBAR: (state) => state.snackbar,
    GET_SNACKBAR2: (state) => state.snackbar2,
    GET_SIZE: (state) => state.size,
    GET_LOADING: (state) => state.loading,
    GET_LOADING2: (state) => state.loading2,
    GET_INIT: (state) => state.init,
  },
});

function getModules() {
  const modulesCtx = require.context("./", true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const modules = {};
  // const ignores = [];
  // if (ignores.indexOf(key) < 0 && key != "./index.js") {

  modulesCtx.keys().forEach((key) => {
    if (key == "./index.js") return;
    let module = modulesCtx(key);
    module.namespaced = true;
    const name = key.slice(key.lastIndexOf("/") + 1, key.length - 3);
    modules[name] = module;
  });
  return modules;
}
