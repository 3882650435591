<script>
import { mapActions } from "vuex";
import debounce from "debounce";

import tnVue from "@/mixins/tn.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TablePagination from "@/mixins/table-pagination.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import TableCog from "@/components/table-cog.vue";
import TableCartInput from "./table-cart-input.vue";
import { getCurrentDate } from "@/filters/date";
import SearchSuggestBox from "@/components/search-suggest-box.vue";

const currentDate = getCurrentDate();
const datePlus1Month = getCurrentDate(null, 1);
const datePlus3Month = getCurrentDate(null, 3);
const datePlus6Month = getCurrentDate(null, 6);

const storeUniqKey = "my-markup";

function getStoredMarkup() {
  const vl = `${localStorage.getItem(storeUniqKey)}`;
  return vl >= 0 ? vl : 10;
}

export default {
  components: { TableCartInput, ConfirmDialog, TableCog, SearchSuggestBox },

  props: {
    hideBackBtn: Boolean,

    tableHeaders: Array,
    ln: String,
    sortBy: String,
    tableHeight: Number,
    GET_API: Function,
    entries: Array,
    entriesCount: Number,
    uniqKey: {
      type: String,
      required: true,
    },
    url: String,
    disableTab: Boolean,
    disableAutofocus: Boolean,
    activeItem: {
      type: Object,
      default: () => ({}),
    },

    isShowTotals: Boolean,
    hideDistributorInfo: Boolean,
    actionsList: {
      type: Array,
      default: () => [],
    },
    getRowStyle: {
      type: Function,
      default: () => { },
    },
    disableCountBold: Boolean,
    disableCount: Boolean,
    sort: {
      type: Boolean,
      default: () => true,
    },
    searchBoxHotKey: {
      type: [String, Number],
      default: () => 115,
    },

    mustSort: {
      type: Boolean,
      default: () => true,
    },
    multiSort: Boolean,
    isShowMyMarkup: {
      type: Boolean,
      default: () => true,
    },
    vResizeTable: {
      type: Object,
      default: () => null,
    },
  },

  data: () => ({
    filteredHeaders: [],
    isShowDelete: false,
    isShowCog: true,
    isShowMarkupModal: false,
    isMyMarkup: true,
    isShowSuggests: null,
    markupText: getStoredMarkup(),
  }),

  mixins: [tnVue, TableResizeVue, TablePagination, TableNavFunctionsVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,
    isLoading: (vm) => !!Object.keys(vm.loaders).length,
    priceListGuid: (vm) => vm.$route.params.guid,
  },

  watch: {
    tableHeaders() {
      this.isShowCog = false;
      this.$nextTick(() => (this.isShowCog = true));
    },

    selected: debounce(function () {
      const v = this.selected || {};
      this.$emit("select", { ...v });
    }, 200),

    markupText(e) {
      const markup = this.$number(e);
      this.$emit("markup", markup);
      this.$emit("isMyMarkup", markup > 0);
      localStorage.setItem(storeUniqKey, markup);
    },

    searchText() {
      this.onSearch();
    },
  },

  created() {
    this.$emit("markup", this.markupText);
    this.$emit("isMyMarkup", this.markupText > 0);
  },

  mounted() {
    document.addEventListener("keydown", this.onDocumentKeydown);
    document.addEventListener("keyup", this.onDocumentKeyup);
  },

  destroyed() {
    document.removeEventListener("keydown", this.onDocumentKeydown);
    document.removeEventListener("keyup", this.onDocumentKeyup);
  },

  methods: {
    ...mapActions("price-list-items", ["SET_ITEM_QTY"]),

    async getEntries(ee) {
      let isReset = ee?.reset;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const options = { ...this.options };

      const params = {
        options: {
          limit: options.itemsPerPage,
          offset: options.itemsPerPage * (options.page - 1) > 0 ? options.itemsPerPage * (options.page - 1) : 0,
          sortDirection: this.$getSort(options.sortDesc, true),
          sortBy: this.$getSort(options.sortBy),
          sortOrders: this.$getSortOrders(options),
          search: this.searchText,
          url: this.url,
          priceListGuid: this.priceListGuid,
        },
      };

      if (!params.options.sortBy) {
        delete params.options.sortDirection;
      } else if (params.options.sortBy == '"Actions"') {
        params.options.sortBy = '"BasketQuantity"';
      }

      if (params.options.sortOrders.find((x) => x.property == '"Actions"')) {
        params.options.sortOrders = params.options.sortOrders.map((x) => {
          if (x.property == '"Actions"') x.property = '"BasketQuantity"';

          return x;
        });
      }

      if (isReset) {
        this.options.page = 1;
        params.options.offset = 0;
      }

      params.filterData = {};

      await this.GET_API(params);

      if (!this.disableAutofocus || !isReset || document?.activeElement?.tagName != "INPUT" && document.activeElement?.id != this.ln && !this.isShowSuggests) {
        this.setFocusToItem();
      } else {
        this.selected = {};
      }

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    checkFocused() {
      return document.activeElement.className.includes(this.uniqKey);
    },

    onSearch: debounce(function () {
      this.getEntries({ reset: true });
    }, 200),

    onDocumentKeydown(event) {
      if (event.code == "Tab") event.preventDefault();
    },

    onDocumentKeyup(event) {
      if (this.searchBoxHotKey && event.keyCode == this.searchBoxHotKey) {
        this.$refs.searchBox?.focus();
        this.$nextTick(() => {
          const input = this.$refs.searchBox?.$el?.querySelector("input");
          input?.select();
        });
        return;
      }

      if (this.disableTab || event.code != "Tab") return;
      event.preventDefault();
      this.$emit("tab");
    },

    setFocusToTable() {
      this.isSavePosition = true;
      this.$nextTick(() => this.setFocusToItem());
    },

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.uniqKey);
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);

      const params = {
        priceListLineItemGuid: this.selected.priceListLineItemGuid,
        priceListLineItemPriceGuid: this.selected.priceListLineItemPriceGuid,
        id: this.selected.id,
        quantity: 0,
      };

      const item = this.$firstArrayItem(this.$refs[this.selected.id]);
      if (item) item.count = 0;

      this.SET_ITEM_QTY(params);

      setLoading(false);
      close();
    },

    onAdd({ qty }) {
      const params = {
        priceListLineItemGuid: this.selected.priceListLineItemGuid,
        priceListLineItemPriceGuid: this.selected.priceListLineItemPriceGuid,
        id: this.selected.id,
        quantity: qty,
      };

      this.SET_ITEM_QTY(params);
    },

    onShowDelete({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowDelete = true));
    },

    getShelfStyle(item) {
      const style = {};
      if (item.shelfLife == "0001-01-01T00:00:00") return style;
      else if (item.shelfLife <= currentDate) style.backgroundColor = "#ff958f";
      else if (item.shelfLife <= datePlus1Month) style.backgroundColor = "#ffced6";
      else if (item.shelfLife <= datePlus3Month) style.backgroundColor = "#ffbd45";
      else if (item.shelfLife <= datePlus6Month) style.backgroundColor = "#fff380";
      return style;
    },

    onExit() {
      if (!this.$router.back()) this.$router.replace("/price-list");
    },

    onClearMarkup() {
      localStorage.setItem(storeUniqKey, 0);
    },

    onKeyupDown() {
      if (this.isLoading) return
      this.setFocusToItem();
    },
  },
};
</script>

<template>
  <div>
    <ConfirmDialog
      :text="tn('delete_alert')"
      @close="() => {
        isShowDelete = false;
        setFocusToTable();
      }"
      v-if="isShowDelete"
      @accept="onDelete"
      hideInfo
    />

    <div class="d-flex align-center">
      <v-btn
        min-width="40"
        width="40"
        outlined
        color="primary"
        v-if="!hideBackBtn"
        replace
        @click="onExit"
        depressed
        class="mr-3"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <div class="w-100">
        <slot name="title" />
      </div>

      <v-spacer />
      <v-menu
        v-if="isShowMyMarkup"
        :close-on-content-click="false"
        offset-y
        v-model="isShowMarkupModal"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            color="primary"
            min-width="40"
            :width="markupText > 99 ? 50 : 40"
            height="40"
            min-height="40"
            outlined
            v-bind="attrs"
            v-on="on"
            :title="$t('my_markup')"
          >
            {{ $number(markupText) }}%
          </v-btn>
        </template>

        <v-card class="pa-2">
          <small> {{ $t("my_markup") }} (%) </small>
          <v-text-field
            outlined
            hide-details
            dense
            @click:clear="onClearMarkup"
            @keyup.enter="isShowMarkupModal = false"
            style="max-width: 100px; width: 100%"
            clearable
            autofocus
            v-inputmask="$masks.numberMax({ max: 999 })"
            v-model="markupText"
            @focus="$event.target.select()"
          />
        </v-card>
      </v-menu>

      <div class="mx-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
        class="mr-2"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <slot
        name="actions-prepend"
        :loading="isLoading"
      />

      <slot name="actions" />

      <table-cog
        :headersData="tableHeaders"
        v-if="isShowCog"
        @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
        :cacheKey="uniqKey"
      />

      <div class="mx-1" />

      <div style="max-width: 300px; width: 100%; min-width: 200px">
        <SearchSuggestBox
          @keyup-down="onKeyupDown({ enter: true })"
          @show-suggests="(e) => isShowSuggests = e"
          :ln="ln"
          :searchBoxHotKey="searchBoxHotKey"
          v-model="searchText"
        />
      </div>
    </div>

    <slot />

    <div class="mt-1" />

    <v-data-table
      v-if="filteredHeaders.length && tableHeight > 0"
      dense
      key="id"
      fixed-header
      class="fixed-right"
      disable-pagination
      hide-default-footer
      :headers="filteredHeaders"
      :height="tableHeight"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :items="entries"
      :server-items-length="entriesCount"
      :must-sort="mustSort"
      :sort-by="sortBy"
      :multi-sort="multiSort"
      :disable-sort="!sort"
      v-resize-table="vResizeTable"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr
          class="cursor-pointer"
          @click.stop="onSelect({ item })"
          :class="{
            'tr-active': selected.id == item.id,
            'tr-active-v2': activeItem.id == item.id,
            'tr-basket-count-bold': !disableCountBold && !!item.basketQuantity,
          }"
          :style="getRowStyle(item)"
        >
          <template v-for="head in headers">
            <td
              v-if="head.value == 'number'"
              :key="head.value"
              class="px-0 text-center"
            >
              <span
                class="text-nowrap"
                v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
              />
            </td>

            <td
              v-else-if="head._slot"
              :key="head.value + 1"
              class="px-0 text-center"
              :style="{ width: head.width }"
            >
              <slot
                :name="head.value"
                :item="item"
              />
            </td>

            <td
              v-else-if="head.value == 'priceListProviderName'"
              :key="head.value + 2"
              :style="{ width: head.width }"
            >
              <div
                class="d-flex align-center"
                :style="{ minWidth: head.minWidth }"
              >
                <div class="tb-action-wrap-2">
                  <v-menu
                    v-if="actionsList.length"
                    offset-y
                    offset-x
                    nudge-left="5"
                    nudge-top="5"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        min-height="28"
                        height="28"
                        min-width="28"
                        width="28"
                        depressed
                        color="transparent"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-menu </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <template v-for="(actionItem, index) in actionsList">
                        <v-list-item
                          :key="index"
                          @click="actionItem.action(item)"
                        >
                          <v-list-item-title :class="actionItem.class">
                            {{ actionItem.name }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider
                          v-if="index < actionsList.length - 1"
                          :key="index + 'i'"
                        />
                      </template>
                    </v-list>
                  </v-menu>
                </div>
                <div class="ellipsis-2-lines">
                  {{ item.priceListProviderName }}
                </div>
              </div>
            </td>

            <td
              v-else-if="head.value == 'shelfLife'"
              :key="head.value + 3"
              :style="{ ...getShelfStyle(item), width: head.width }"
            >
              <div
                class="text-nowrap"
                v-html="head._filter(item[head.value], item)"
                :style="{
                  textAlign: head._align,
                  minWidth: head.minWidth,
                  color: head._getColor?.(item[head.value], item),
                }"
              />
            </td>

            <td
              v-else-if="head._filter"
              :key="head.value + 4"
              :style="{ ...head._getStyle?.(item[head.value], item), width: head.width }"
            >
              <div
                class="text-nowrap"
                v-html="head._filter(item[head.value], item)"
                :style="{
                  textAlign: head._align,
                  minWidth: head.minWidth,
                  color: head._getColor?.(item[head.value], item),
                }"
              />
            </td>

            <td
              v-else-if="head.value == 'actions'"
              :key="head.value + 5"
              class="px-0"
              :style="{ width: head.width }"
            >
              <!-- <div class="px-2" v-if="disableCount">
                {{ $sum(item.basketQuantity) }}
              </div> -->

              <TableCartInput
                :disabled="disableCount"
                :ref="item.id"
                :itemsLength="entries.length"
                :tabindex="index + 1"
                :item="item"
                :uniKey="uniqKey"
                @upKey="onUpKey({ item })"
                @downKey="onDownKey({ item })"
                @pageUp="onPageUp({ item })"
                @pageDown="onPageDown({ item })"
                @delete="onShowDelete({ item })"
                :tn="tn"
              />
            </td>

            <td
              v-else
              :key="head.value + 6"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div
                :style="{ minWidth: head.minWidth }"
                v-text="item[head.value]"
              />
            </td>
          </template>
        </tr>
      </template>

      <template
        v-if="isShowTotals && entriesCount > 1"
        v-slot:body.append
      >
        <tr class="sticky-table-footer">
          <slot
            name="body-append"
            :headers="filteredHeaders"
          />
        </tr>
      </template>

      <template #footer>
        <TablePaginationButtons
          :loading="isLoading"
          :options="options"
          :lastPage="lastPage"
          :perPageInput="perPageInput"
          :pageInput="pageInput"
          :entriesCount="entriesCount"
          :onLastPage="onLastPage"
          :onChangePerPageInput="onChangePerPageInput"
          :onChangePageInput="onChangePageInput"
        />
      </template>
    </v-data-table>
  </div>
</template>
