<script>
import { mapActions, mapGetters } from "vuex";
import tnVue from "@/mixins/tn.vue";
import PermissionConfirmDialog from "@/components/modals/permission-confirm-dialog.vue";

export default {
  props: {
    item: Object,
    ln: String,
  },

  components: { PermissionConfirmDialog },

  data: () => ({
    isLoading: false,
    isPermissionAlert: false,
    tryCount: null,
  }),

  mixins: [tnVue],

  computed: {
    ...mapGetters("auth", ["GET_USER", "GET_USER_PERMISSIONS"]),

    ...mapGetters("product", {
      entries: "GET",
      entriesCount: "GET_COUNT",
    }),
  },

  methods: {
    ...mapActions("product", { favoriteApi: "FAVORITE_API" }),
    ...mapActions("auth", ["GET_PERMISSIONS_LIST"]),

    async onFavorite() {
      if (!this.GET_USER.isTrial) {
        this.onPermission();
      } else {
        this.$store.dispatch("SET_LOADING", true);
        await this.GET_PERMISSIONS_LIST();
        this.$store.dispatch("SET_LOADING", false);

        this.tryCount = this.$getObjectParam("CanAddFavoriteProduct", this.GET_USER_PERMISSIONS);
        if (this.$number(this.tryCount)) {
          this.isPermissionAlert = true;
        } else {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.$t("permission_confirm_dialog.permission_alert"),
            color: "error",
          });
        }
      }
    },

    async onPermission() {
      this.isPermissionAlert = false;

      this.isLoading = true;
      await this.favoriteApi({ ...this.item });
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div>
    <PermissionConfirmDialog
      :text="$t('permission_confirm_dialog.function_permission_alert')"
      :try-count="tryCount"
      @close="isPermissionAlert = false"
      v-if="isPermissionAlert"
      @accept="onPermission"
    />

    <div
      style="transform: translateY(-4px)"
      class="tb-action-wrap translate-3 justify-center"
    >
      <v-btn
        :color="item.isFavorite ? 'primary' : undefined"
        :title="item.isFavorite ? tn('table.actions.favorite_remove_btn') : tn('table.actions.favorite_btn')"
        @click="onFavorite"
        icon
        x-small
      >
        <v-icon>
          {{ item.isFavorite ? "mdi-star" : "mdi-star-outline" }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
